.campaign {
// General styling


h5 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: $brand-primary;
  margin-bottom: 12px;
}
.accordion_title {
  display: flex!important;
  justify-content:space-between;
  color:#062C2D!important;
  background: rgba($color: #5AC5BC, $alpha: 0.05)!important;
  padding: 16px;
  cursor: pointer;
  border-radius: $border-radius;
  font-style: normal;
  font-weight: 600;
  margin: 24px 0;
  border: 1px solid rgba($color: #5AC5BC, $alpha: 0.38)!important ;
}
h2{
  margin-top: $s-s;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #062C2D;
  margin-bottom: 12px;
}
.grey_heading{
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 32px;

color: #676767;
}
h1 {
  position: absolute;
  bottom: 75px;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #FFFFFF;
  margin-left: 40px;
}
p {
  margin-left: 15px;
  a{
    text-decoration: underline;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #676767;
  }
}
.terms{
  font-weight: 600;
}
.subheader {
  margin-left: 0 ;
}
h4 {
  margin-top: $s-s;
  font-weight: 500;
  margin-left: 15px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: $brand-primary;;
}
hr {
  color:$brand-light;
}
.private{
  @include transparent-button;
  width: unset;
  height: 48px;
}
.public {
  @include orange-button;
  height: 48px;
}
.fas {
  margin-right: $s-t;
  font-size: small;
}

// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
    .banner {
      background: url(/static/dist/img/how-to-banner-mobile.png) center/cover;
      height: 461px;
    }
    .private{
      width: unset;
    }
    .public {
      width: unset;
    }
  }
}