.portal-banner {
    height: 270px;
    background: url('/static/dist/img/landing_page_hero_banner.jpg');
    padding: 0 40px;
    background-size: cover;
    &.playful-feasts-banner {
        background-image: url('/static/dist/img/playful-feasts-banner.jpg') !important;
        @media (max-width: $tablet) {
            background-image: url('/static/dist/img/playful-feasts-banner-mobile.jpg') !important;
        }
    }
    &.mindful-meals-banner {
        background-image: url('/static/dist/img/mindful-meals-banner.jpg') !important;
        @media (max-width: $tablet) {
            background-image: url('/static/dist/img/mindful-meals-banner-mobile.jpg') !important;
        }
    }
    &.olive-tree-banner {
        background-image: url('/static/dist/img/olive-tree_banner.jpg') !important;
        @media (max-width: $tablet) {
            background-image: url('/static/dist/img/olive-tree_banner-mobile.jpg') !important;
        }
    }
    &.every-nation-bryanston-banner {
        background-image: url('/static/dist/img/every-nation-bryanston-banner.jpg') !important;
        @media (max-width: $tablet) {
            background-image: url('/static/dist/img/every-nation-bryanston-banner-mobile.jpg') !important;
        }
    }
    &.sab-foundation-banner {
        background-image: url('/static/dist/img/sab-foundation-banner.jpg') !important;
        background-color: white;
        @media (max-width: $tablet) {
            background-image: url('/static/dist/img/sab-foundation-banner-mobile.jpg') !important;
        }
    }
    &.chs-the-table-banner {
        background-image: url('/static/dist/img/chs-the-table-banner.jpg') !important;
        @media (max-width: $tablet) {
            background-image: url('/static/dist/img/chs-the-table-banner-mobile.jpg') !important;
        }
    }
    &.cooktastic-banner {
        background-image: url('/static/dist/img/cooktastic-banner.jpg') !important;
        @media (max-width: $tablet) {
            background-image: url('/static/dist/img/cooktastic-banner-mobile.jpg') !important;
        }
    }
    &.heartlines-wys-banner {
        background-image: url('/static/dist/img/heartlines-wys-banner.jpg') !important;
        @media (max-width: $tablet) {
            background-image: url('/static/dist/img/heartlines-wys-banner-mobile.jpg') !important;
        }
    }
    &.harbour-city-banner {
        background-image: url('/static/dist/img/harbour-city-banner.jpg') !important;
        @media (max-width: $tablet) {
            background-image: url('/static/dist/img/harbour-city-banner-mobile.jpg') !important;
        }
    }
    &.esquared-banner{
        background-image: url('/static/dist/img/esquared-banner.jpg') !important;
        @media (max-width: $tablet) {
            background-image: url('/static/dist/img/esquared-banner-mobile.jpg') !important;
        }
    }
    &.findamentals-banner {
        background-image: url('/static/dist/img/findamentals-banner.jpg') !important;
        @media (max-width: $tablet) {
            background-image: url('/static/dist/img/findamentals-banner-mobile.jpg') !important;
        }
    }
    &.khanyisa-centre-banner {
        background-image: url('/static/dist/img/khanyisa-centre-banner.jpg') !important;
        @media (max-width: $tablet) {
            background-image: url('/static/dist/img/khanyisa-centre-banner-mobile.jpg') !important;
        }
    }
    &.org-banner {
        @media (max-width: $tablet) {
            height: 407px;
        }
    }
    .inner {
        max-width: 1600px;
        margin: 0 auto;
        display: block;
        width: 100%;
        h1 {
            font-style: normal;
            font-weight: 500;
            font-size: 24px !important;
            line-height: 34px;
            color: #FFFFFF;
            padding-top: 91px;

        }
        .btn {
            margin-top: 22px;
            width: 127px !important;
            @include orange-button;

            &:hover {
                border: 0;
                box-shadow:none
            }
        }
        .portal {
            padding-top: 91px;
            img {
                width: 112px;
                height: 112px;
                border-radius: 50%;
                margin-right: 13px;
                border: 2px solid #FFF;
                object-fit: cover;
                &.sab-foundation-logo,&.the-table-cafe-logo {
                    background-color: white;
                }
            }
            h1 {
                padding: 0 !important;
                color: #FFF;
                font-family: 'Inter';
                font-size: 22px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
            p {
                color: #FFF;
                font-family: 'Inter';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .learn-more {
                border-radius: 5px;
                border: 0.5px solid #FFF;
                padding: 20px;
                width: 469px;
                color: #FFF;
                @media (max-width: $tablet) {
                    width: 100%;
                    margin: 15px 0 48px 0;
                }
                p {
                    color: #FFF;
                    font-family: 'Inter';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
                a {
                    color: #FFF;
                    font-family: 'Inter';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-decoration-line: underline;

                    &::after{
                        content: url('/static/dist/img/greater icon.svg');
                        position: relative;
                        top: 3px;
                        left: 8px;
                    }
                }
            }
        }
    }

      @media (max-width: $tablet) {
        background-image: url('/static/dist/img/landing_page_hero_banner-banner-mobile.jpg');
        padding: 0 25px;
        height: 328px;
        background-size: cover;
        background-position: bottom;
      }

}
