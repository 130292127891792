
  #cookies {
    opacity: 1;
    animation: FadeIn 0.1s ease-in-out;
  }
  #cookies1 {
    opacity: 1;
    animation: FadeIn 0.1s ease-in-out;
    display: none;
  }

.cookie-notes {
    position: fixed;
    width: 100%;
    height: 74px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 13px 26px;
    background: #FFFFFF;
    bottom: 0;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 20px rgba(0, 0, 0, 0.12);
    justify-content: space-between;
    z-index: 10;

    div {
        display: flex;
        align-items: center;
        margin-right: 30px;
        p {
            font-weight: 400;
            font-size: 13px;
            line-height: 22px;
            color: #3F4142;
            a {
                font-weight: 400;
                font-size: 13px;
                text-decoration: underline;
                width: fit-content;
                white-space: nowrap;
                
            }
        }
    }
    button {
        @include transparent-button;
        width: fit-content;
        min-width: 131px;
        text-align: center;
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: #3F4142;
        padding: 0 22px;
    }
    // Mobile specific styling
    @media (max-width: $tablet) {
        height: unset;
        padding: 26px;
        div {
            margin: 0;
            p {
                text-align: center;
                display: block
            };
        }
        button {
        width: fit-content;
        margin: 20px auto 0 auto;;
        }
    }
}
@keyframes FadeIn {
    0% {
      display: none;
      opacity: 0;
    }
    1% {
      display: block;
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }