.events_local {

  h2 {
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .events_local--cards {
    width: 100%;
    margin: unset;
    &:first-child {
      margin-left: unset;
    }
  }

  .event-tag {
    background-color: $teal;
    border-color: $teal !important;
  }

  // Desktop specific styling
  @media (min-width: $tablet) {}

  // Mobile specific styling
  @media (max-width: $tablet) {}
}