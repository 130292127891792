.spinner-wrapper{
    position: fixed;
    background: rgba(255,255,255,0.5);
    z-index: 100;
    height: 100%;
    max-height: 1007px;
    width: calc(100% + 10px);
    margin-left: -20px;
    max-width: 650px;
    // Desktop specific styling
    @media (min-width: $tablet) {
    }

    // Mobile specific styling
    @media (max-width: $tablet) {
    }
}