.landing-page--ctas {
// General styling
background: white;
box-shadow: $box-shadow-reverse;
padding: 0;
height: $footer-cta-height;
z-index: 10;
position: sticky;
bottom: 0;
visibility: hidden;

.host {
  a {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #262626;
    position: relative;
    left: 9px;
  }
  &::before {
    content: url('/static/dist/img/coffee.svg');
    position: absolute;
    top: 4px;

  }
}
.find {
  border-left: 1px solid #EAEAEA;
  a {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #262626;
    position: relative;
    left: 9px;
  }
  &::before {
    content: url('/static/dist/img/utensils.svg');
    position: absolute;
    top: 4px;

  }
}
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
    visibility: visible;
  }
}