// Globale property ** should ideally only be applied in the instance of the dashboard
html {
  height: 100%;
}

.dashboard {
  // General styling
  height: 100%;

  .user-layout {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
  }

  >.content {
    padding-top: $s-m;
    background: white;
  }

  // Desktop specific styling
  @media (min-width: $tablet) {}

  // Mobile specific styling
  @media (max-width: $tablet) {}
}