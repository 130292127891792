.email-host {
  // General styling
  color: #4a4a4a;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #3a3a3a;
    margin-bottom: 20px;
    margin-top: 46px;
  }
  .logo-img {
    display: none !important;
  }

  .back {
    display: flex !important;
    align-items: center;
    font-size: 20px;
    cursor: pointer;

    .fas {
      font-size: 24px;
      margin-right: 10px;
    }
  }
  .top-section {
    margin-left: -30px;
    margin-right: -30px;
    margin-bottom: 0;
    hr {
      margin: 0;
      border-top: 1px solid #e7e7e7;
    }
  }
  .back-btn {
    border-bottom: 1px solid #eeeeee;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $brand-primary;
    min-width: 68px;
    margin-top: 60px;
    &::before {
      content: url("/static/dist/img/back-button.svg");
      position: relative;
      right: 13px;
      top: 3px;
    }
  }
  .mobile-header {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    position: absolute;
    left: 50%;
    transform: translate(-50px, -50px);
    top: 88px;
  }
  &--from {
    border-bottom: 1px solid #cbcbcc;
    padding: 6px 0;
    width: 100%;
  }
  &--to {
    &::before {
      content: url("/static/dist/img/select_arrow.svg");
      position: absolute;
      right: 15px;
      top: 27px;
      bottom: 0;
      margin: auto;
      height: 16px;
      width: 16px;
      padding-right: 2px !important;
      z-index: 100;
      transform: translate(0, -50%);    
    }
}
  // .select2-container--default .select2-selection--multiple .select2-selection__clear{
  //     display: block;
  //     cursor: pointer;
  //     float: right;
  //     font-weight: bold;
  //     padding: 0 5px;
  //     position: absolute;
  //     margin-top: 39px;
  //     margin-right: 12px;
  //     color: #aaa;
  //     font-size: 20px;
  //     line-height: 1;
  //     opacity: 0.5;
  //     &:hover{
  //         opacity: 1;
  //     }

  // }

  &--to-name {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-right: 16px;
  }

  &--subject {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &--message {
    border: 1px solid #dcdfe0 !important;
    border-radius: 7px !important;
    line-height: 24px;
    min-height: 300px;
    background-image: none;
    padding-left: 10px !important;
  }
  &--button-container {
    justify-content: end;
  }

  .send {
    @include orange-button;
  }

  .cancel {
    @include transparent-button;
    height: 48px;
    width: unset;
    margin-right: 10px;
    border: 1px solid #c4c4c4 !important;
  }

  label {
    span {
      display: inline-block;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 200;
      position: absolute;
      right: $s-s;
    }
  }

  // Select2
  .select2-default {
    color: #f00 !important;
    width: 100%;
    padding-right: 10px;
  }

  .select2 {
    &-selection {
      width: 100% !important;
      line-height: 24px !important;
      min-height: 48px !important;
      max-height: 48px !important;
      height: unset !important;
      margin-bottom: 31px;
      // padding: 6px !important;

      &__placeholder {
        color: red !important;
      }
      &__choice {
        font-size: 10px;
        margin-top: 10px !important;
        padding: 0 10px !important;
        padding-left: 21px !important;
        position: relative !important;
        top: 0;

        &__remove {
          font-size: 13px !important;
          height: 100%;
        }
      }
      &--multiple {
        border: 1px solid #dcdfe0 !important;
        border-radius: 7px !important;
        width: 100% !important;
        padding-right: 10px !important;
      }
    }

    &-search {
      &__field {
        top: 0;
        padding-left: 5px !important;
        font-size: 14px !important;
        margin-top: 3px !important;
        height: 42px !important;
        width: 100% !important;
        position: relative;
      }

      &::-webkit-input-placeholder {
        padding-left: 5px;
      }
    }

    &-results {
      &__option {
        font-size: 14px;
        padding: 10px 10px 10px 20px !important;
      }
    }
    // container for select2
    &-container {
      z-index: 1;
      width: 100% !important;
      // padding-right: 10px !important;
    }
  }

  // Desktop specific styling
  @media (min-width: $tablet) {
  }

  // Mobile specific styling
  @media (max-width: $tablet) {
    .top-section {
      margin-bottom: 31px;
    }
    .back-btn {
      border-bottom: none;
      margin-top: 32px;
    }
    &--button-container {
      justify-content: unset;
      display: grid;
      .cancel {
        margin-right: 10px;
        order: 2;
        border: none !important;
        box-shadow: none;
      }
      .send {
        order: 1;
      }
    }
  }
}
