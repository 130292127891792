
  .search-bar {
    // Local variables
    $button-width: 70px;
    $checkbox-width: 50px;
    
    margin-top: 70px;

    /* The switch - the box around the slider */
    .switch {
      position: relative;
      display: inline-block;
      width: 42px;
      height: 28px;

      &.mobile_switch {
        background-color: red;
      }

      &--co-host {}
    }

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 24px;
      width: 24px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      background-repeat: no-repeat;
      background-size: 15px;
      background-position: center;
    }

    input:checked+.slider {
      background-color: #3A3A3A;
      width: 42px !important;
    }

    input:checked+.slider.width-search-round {
      background-color: #3A3A3A;
      width: 42px !important;
    }

    input+.slider.width-search-round {
      background-color: #ccc;
      width: 42px !important;
    }

    input:focus+.slider {
      box-shadow: 0 0 1px #3A3A3A;
    }

    input:checked+.slider:before {
      -webkit-transform: translateX(14px);
      -ms-transform: translateX(14px);
      transform: translateX(14px);
      background-image: url('/static/dist/img/grey-checkmark.svg');
      background-repeat: no-repeat;
      background-size: 15px;
      background-position: center;
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
      width: 42px !important;
    }

    .slider.round:before {
      border-radius: 50%;
    }

    .show-past-event {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #676767;
      margin-left: 6px;
      margin-right: 12px;
    }

    form {
      display: flex;
      justify-content: center;
      .form-group {
        width: unset !important;
      }
    }

    input.form-control {
      @include search-input;
      border-right: 0 !important;
      border-top: 1px solid #CBCBCC !important;
      border-bottom: 1px solid #CBCBCC !important;
      border-left: 1px solid #CBCBCC !important;
      padding-left: 15px !important;

      &::placeholder {
        color: white;
      }

      &[type='text'] {
        color: $font-general;
        margin-right: 10px;
        padding-left: 40px !important;

      }

    }

    &--input {
      &::after {
        content: url('/static/dist/img/dark-search.svg');
        position: absolute;
        top: 15px;
        left: 10px;
      }

      label {
        color: white;
      }

            input.search-field {
              @include search-input;
              border: 1px solid #CBCBCC !important;
      
              &::placeholder {
                color: $font-general;
              }
            }
      
            button {
              height: 35px;
              width: $button-width;
              float: right;
              margin-top: 5px;
              border: 0;
              border-radius: 0 17.5px 17.5px 0;
              background: #AAD081;
              color: white;
            }

    }

    &--date {
      position: relative;

      input[type="date"] {
        width: 211px;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        -webkit-appearance: none !important;

        &:hover {
          cursor: pointer;
        }

        &::after {
          content: url('/static/dist/img/calender.svg');
          position: absolute;
          top: 15px;
          right: 15px;
        }

        // padding-right: 8px;
        margin-top: 0;
      }
    }

    &--area {
      position: relative;

      // margin-bottom: 0;
      .select2 {
        &-container {
          // margin-left: 50px;
          width: 211px !important;
          @include search-input;
          border: 1px solid #CBCBCC !important;
          height: 50px !important;
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          // padding: 0 !important;
          display: flex;
          vertical-align: ce;
          align-items: center;

          &::after {
            content: url('/static/dist/img/map-marker.svg');
            position: absolute;
            top: 13px;
            right: 15px;
            pointer-events: none;
          }
        }

        &-selection {
          background-color: transparent;
          border-top: 0;
          border-left: 0;
          border-right: 0;
          border-radius: 0 !important;
          border-color: #ffffff94;
          line-height: unset;

          &__rendered {
            color: $brand-primary;
            padding-left: 0;
            padding-right: 0;

            .select2-selection__placerholder {
              background: red;
              height: 48px;
              padding-top: 11px;
              padding-left: 15px;
            }
          }

          &__arrow {
            margin-bottom: 5px;
            top: -5px;
          }

        }

      }
    }

    .form-buttons {
      // margin-top: $s-m;
      margin: 0;
      margin-left: 10px;

      .btn_clear {
        min-width: 55px !important;
        border-radius: $border-radius;
        border: 1px solid #CBCBCC;

        &::after {
          border-color: white;
          width: 50px;
        }

        &:hover {
          outline: none !important;
          box-shadow: none !important;
        }
      }

      .btn_search {
        width: 84px !important;
        min-width: 84px !important;
        border-radius: $border-radius;
        background-color: $brand-primary;
        margin-left: 10px;

        &:hover {
          outline: none !important;
          box-shadow: none !important;
        }
      }
    }
  

  // Desktop specific styling
  @media (min-width: $tablet) {
    input.form-control {
      &[type='text'] {
        width: 348px;
      }
    }

  }

  //Tablet specific styling
  @media (max-width:$tablet) {
  
      margin-top: 70px;

      form {
        width: 91vw !important;
      }
    
  }

  // Mobile specific styling
  @media (max-width: $tablet) {

      margin-top: 70px;

      form {
        display: block;
        width: 100%;
      }

      input {

        &[type='text'] {
          margin-right: 0;
          width: 100%;

        }
      }

      &--date {
        margin-bottom: 0 !important;

        input[type="date"] {
          width: 100%;
          border: 1px solid #CBCBCC !important;
          border-radius: $border-radius !important;
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }
      }

      .form-buttons {
        margin-left: 0 !important;
        margin-bottom: 24px;
        justify-content: space-between;

        .clear-container {
          justify-content: start !important;

          .btn_clear {
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-radius: 0;
          }
        }

        .search-container {
          justify-content: end !important;

          .btn_text {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #FFFFFF;
          }
        }
      }

      .select2 {
        &-container {
          // margin-left: 50px;
          width: 100% !important;
          border-radius: $border-radius !important;
          border-top: none !important;
          border-top-left-radius: 0 !important;
          border-top-right-radius: 0 !important;
        }

      }
    
  }
}

