.alert{
    background-color: #FFFFFF;
    color: #3F4142;
    position: fixed;
    right: 15px;
    width: 90%;
    z-index: 101;
    top: 89px;
    margin: auto;
    margin-bottom: $s-s;
    max-width: 348px;
    padding: 20px 24px;
    /* Grey / Hover Dark rr*/
    border: 1px solid #F1F3F4;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 7px;
    &--message {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #3F4142;
      margin-bottom: 11px;
      margin-left: 5px;
      &-type {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #3F4142;
      }
    }
    img {
      position: relative;
      bottom: 5px;
    }
    .col-sm-8{
      width: 67%;
    }
    .col-sm-4{
      width: 33%;
    }

    .close{
      padding: 0px $s-s !important;
      z-index: 1;
      opacity: 1;
      span{
        font-size: 45px !important;
      }
      &:hover,&:focus,&:active {
        color: #CBCBCC;
        box-shadow: none !important;
        outline: none !important;
      }
    }
    .successful{
      margin-top: $s-s;
      .fas{
        color: #B8E986;
        font-size: 60px;
      }
    }
    .unsuccessful{
      margin-top: $s-s;
      .fas{
        color: #F73E55;
        font-size: 60px;
      }
    }
    .warning{
      margin-top: $s-s;
      .fas{
        color: #F2C56D;
        font-size: 60px;
      }
    }
    .declined{
      color:#EF3E55;
    }
    .accepted{
      color: #B3D194;
    }
    &-invited{
      color: #F2C56D;
    }
    // Desktop specific styling
    @media (min-width: $tablet) {
    
    }
        
    // Mobile specific styling
    @media (max-width: $tablet) {
      top: 85px;
      right: 0;
      left: 0;
    }
  }