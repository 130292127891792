.event {

  //Local variables and mixins
  @mixin active($color, $background) {
    border: 1px solid $background;
    background: $background;
    color: $color;

    &::after {
      color: $font-general;
    }
  }

  .organisation-badge{
    width: 49%;
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    padding: 16px;
    .labeling{
      border-top: 1px solid #EBEBEB;
      margin: 10px 0;
      padding-top: 13px;
    }
    img{
      width: 34px;
      height: 34px;
      margin-right: 5px;
    }
    span{
      color: #3A3A3A;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.072px;
    }
    .badge{
      position: relative;
      span{
        display: block;
        float: left;
        margin-left: 18px;
      }
      &::after{
        content: url("/static/dist/img/angle-right.svg");
        right: 12px;
        top: 10px;
        position: absolute;
      }
    }
    .money{
      &-wrapper{
        background: #F7F7F7;
        border-radius: 4px;
        padding: 5px;
        margin: 5px;
        .hosting-text{
          border-right: 1px solid #5E5E5E;
          span{
            font-family: 'Inter';
            font-size: 11px;
          }
        }
      }
      .amount{
        display: inline-block;
        float: right;
        position: relative;
        right: 10px;
        color:#3A3A3A;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
      }
      img{
        width: 24px;
        height: 24px;
      }
    }
  }
  #meal_type {
    width: 100%;
    border-radius: 7px;
    border: 2px solid #CBCBCC;
    padding: 13px 8px;
  }

  .pricing_type {
    .select2{
      border-radius: 7px;
      border: 1px solid #888B8E;
      color: #3A3A3A;
      font-size: 14px;
      font-weight: 400;
      .selection .select2-selection{
        background: #F7F7F7;
      }
    }
    
  }
  .meal_type, .pricing_type {
    .select2 {
      position: relative;
      &::after{
        content: url('/static/dist/img/selector chevron down.svg');
        position: absolute;
        right: 12px;
        top: 6px;
      }
    }
    .select2-selection{
      padding: 20px 10px;
      .select2-selection__rendered{
        position: relative;
        top: -11px;


      }
    }
    select {
      -moz-appearance: none;
      -webkit-appearance: none;
    }

    option {
      padding: 10px 0px 12px 0px;
      font-family: 'Inter';
      font-size: 16px;
      font-weight: 400;
    }

    select::-ms-expand {
      display: none;
    }
  }

  #venue-list-cards-empty,
  #caterer-list-cards-empty {
    @media (max-width: $tablet) {
      padding: 30px;
    }

  }

  .vendor-create-btn {
    height: 48px;
    color: white;
    background-color: #F69C3A;
    border: 0;
    width: 327px;
  }

  .event-create-wrapper {
    @media (min-width: $tablet) {
      padding: 0;

      #venue-tab,
      #caterer-tab {
        .event-listing {
          .search-block {
            .form-group {
              .search-field {
                width: 215px;
              }
            }
          }
        }
      }

      .event-listing--container {
        margin-left: 12px;
      }
    }
  }

  .select-co-host {
    p {
      margin-top: 12px;
    }
  }

  // General styling
  position: relative;

  .preview-venue {
    border-radius: 8px;
    border: 1px dashed #C2C2C2;
    background: #F7F7F7;
    position: relative;
    margin: 20px 0;

    .cancel-btn {
      position: absolute;
      width: 24px;
      height: 24px;
      right: -78px;
      top: -12px;
      cursor: pointer;
    }

    .venue-image {
      width: 50px;
      height: 50px;
      background-position: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 4px;
      border: 1px solid #EBEBEB;
    }

    .venue-details {
      position: relative;
      left: -35px;

      h3 {
        color: #717171;
        font-family: 'Inter';
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.077px;
        text-transform: uppercase;
      }

      p {
        color: #717171;
        font-family: 'Inter';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.077px;
      }
    }

    .venue-status {
      float: right;

      span {
        color: #717171;
        text-align: right;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0.052px;
        position: relative;
        padding-right: 27px;
      }

      .venue-request-status-pending {
        &::after {
          content: url('/static/dist/img/pending.svg');
          top: 0;
          position: absolute;
          right: 0;
        }
      }

      .venue-request-status-approved {
        &::after {
          content: url('/static/dist/img/approved.svg');
          top: 0;
          position: absolute;
          right: 0;
        }
      }
    }

    @media (max-width: $tablet) {
      .venue-details {
        left: 13px;
      }
    }
  }

  .contactModal {
    .modal-dialog {
      border-radius: 8px;
      background: #FFF;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10), 0px 0px 4px 0px rgba(0, 0, 0, 0.06);
      max-width: 355px;
      padding: 30px;
      padding-top: 0;

      .modal-header {
        position: relative;

        .close {
          position: absolute;
          right: -11px;
          top: 21px;
          z-index: 1;
        }
      }

      .container {
        padding: 0 !important;
        border: none;
        box-shadow: none;
      }

      h3 {
        color: #3A3A3A;
        font-family: 'Inter';
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 38px;
        /* 135.714% */
      }

      a.via {
        padding: 8px 12px 8px 20px;
        border-radius: 7px;
        border: 1px solid #3A3A3A;
        width: 100%;
        display: block;
        margin: 20px 0;

        &::after {
          width: 20px;
          height: 20px;
          position: absolute;
          right: 26px;
        }

        &.whatsapp {
          &.reschedulebtn {
            text-decoration: none;
            text-align: center;
            border-color: #F69C3A;

            &::after {
              content: '';
            }
          }

          &::after {
            content: url('/static/dist/img/whatsapp.svg');
          }
        }

        &.mobile {
          &.remove-icon {
            &::after {
              content: '';
            }
          }

          &::after {
            content: url('/static/dist/img/phone.svg');
          }
        }

        &.email {
          &::after {
            content: url('/static/dist/img/envelope-alt.svg');
          }
        }
      }
      @media (max-width: $tablet) {
        &.custom-center{
        position: absolute;
        left: 48%;
        top: 41%;
        transform: translate(-50%, -50%);
        width: 100%;
        }

      }
    }
  }

  .host-event-container {
    max-width: 620px;
    margin-bottom: 0;
  }

  &_view {
    padding-top: calc(#{$s-l} + #{$s-s});
    margin-top: 40px;
    margin-bottom: 90px;
    padding-left: 15px !important;
    padding-right: 15px !important;

    .cancel-footer {
      background-color: unset !important;
      box-shadow: unset !important;
    }

    .email-the-host-btn {
      @include transparent-button;
      max-width: 100%;
      width: unset !important;
      min-width: fit-content;

      @media (max-width: $tablet) {
        width: 100% !important;
        min-width: fit-content !important;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 10px;
      }
    }

    .add-extra-seats-btn {
      @include orange-button;
      color: white !important;
      border: none !important;
      box-shadow: none !important;
      margin-top: 21px;
      margin-bottom: 20px;

      @media (min-width: $tablet) {
        margin-top: 21px;
        margin-bottom: 20px;
      }
    }

    .instructions-and-events {
      width: max-content;
      background: white;
      display: block;
      margin: auto;
    }

    &--title {
      hr {
        margin-top: $s-t !important;
      }
    }

    .card {
      // background: rgba(255,255,255,0.9);
      background: transparent;

      &-header {
        background: transparent;
        border-bottom: 0;

        .meal-type {
          position: relative;

          i {
            @include circle-frame(32px, 1px, $font-general);
            background: white;
            opacity: 0.5;

            &::before {
              font-size: 14px;
            }

            &::after {
              position: relative;
              bottom: 0;
              display: inline-block;
              font-size: 10px;
              text-transform: uppercase;
              width: 300%;
              left: -100%;
              text-align: center;
            }

            &.meat {
              &::after {
                content: "Contains Meat";
              }
            }

            &.vegan {
              &::after {
                content: "Vegan";
              }
            }

            &.vegetarian {
              &::after {
                content: "Vegetarian";
              }
            }

            &.active {
              opacity: 1;
            }
          }
        }
      }

      &-covid-guidlines {
        border-top: 1px solid #ebe9e9;
        padding: 0 15px;
      }

      &-body {
        padding-bottom: 0;

        #description_readmore {
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          text-decoration-line: underline;
          color: #4a4a4a;
          position: relative;
          left: calc((100% - 90px) / 2);
        }

        .event {

          &--description,
          &--ingredients,
          &--instructions {
            margin-bottom: $s-s;
            transition: all ease 1.1s;
            overflow: hidden;

            &.description-showless {
              display: -webkit-box;
              -webkit-line-clamp: 6;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }

          &--description {
            p {
              margin-bottom: 1rem;
            }
          }

          &--edited-card-container {
            .edited-cards {
              background: #fdfdfd;
              border: 1px solid #f1f1f1;
              border-radius: 7px;
              padding: 14px 24px;
              margin-bottom: 20px;
              overflow: hidden;

              .date {
                font-size: 14px;
                line-height: 24px;
                color: #676767;
              }

              p {
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                color: #3a3a3a;
              }
            }
          }

          &--details {
            >.row {
              padding: 10px 0;
            }

            .event-details-label {
              font-size: 16px;
              font-weight: 500;
            }

            .food-allergies {
              color: #3A3A3A;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              /* 150% */
              letter-spacing: 0.048px;
            }

            margin-top: 28px;
            position: relative;

            &--label {
              position: absolute;
              top: 0;
              color: $font-general;
              font-weight: 400;
            }

            &--info {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              color: $brand-primary;
              border: $border;
              border-radius: $border-radius;
              padding: 12px;
              margin-top: 20px;

              .popover-pill {
                cursor: pointer;
                padding: 0;
                right: 30px;
                top: 0;
                outline: none;

                i {
                  margin: 0;
                }
              }

              .line {
                background: #EBEBEB;
                height: 1px;
                width: 93%;
              }

              .venue-image {
                border-radius: 4px;
                border: 1px solid #EBEBEB;
                width: 50px;
                height: 50px;
                background-position: 100%;
                background-size: cover;
                background-repeat: no-repeat;
              }

              .venue-title {
                color: #717171;
                font-family: 'Inter';
                font-size: 11px;
                font-weight: 600;
                text-transform: uppercase;
              }

              .venue-name {
                color: #717171;
                font-family: 'Inter';
                font-size: 11px;
                font-weight: 400;
              }

              &.allergies {
                .tag {
                  border-radius: 4px;
                  border: 1px solid #EBEBEB;
                  background: #F7F7F7;
                  padding: 8px;
                  color: #3A3A3A;
                  font-family: 'Inter';
                  font-size: 11px;
                  font-weight: 400;
                  line-height: 14px;
                  /* 127.273% */
                  letter-spacing: 0.077px;
                  margin-right: 5px;
                  width: fit-content;
                  display: inline-block;
                }
              }

              &.cost {
                font-size: 30px;
              }

              &.guest-no {
                display: flex;

                &::before {
                  content: url("/static/dist/img/users-alt.svg");
                  margin-right: 10px;
                  position: relative;
                  top: 2px;
                }
              }

              &.time {
                display: flex;

                &::before {
                  content: url("/static/dist/img/clock.svg");
                  margin-right: 10px;
                  position: relative;
                  top: 2px;
                }
              }

              &.area {
                //display: flex;
                flex-direction: row;

                .area-text {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 18px;
                  letter-spacing: 0.056px;
                  position: relative;
                  margin-left: 24px;
                  top: -23px;
                }

                &::before {
                  content: url("/static/dist/img/location.svg");
                  margin-right: 10px;
                  position: relative;
                  top: 2px;
                }
              }
            }

            &--price {
              p {
                font-weight: 600;
                font-size: 26px;
                line-height: 26px;
                color: #3a3a3a;
                margin-top: 6px;

                span {
                  color: #676767;
                }
              }
            }

            &--type {
              p {
                font-weight: 600;
                font-size: 13px;
                line-height: 20px;
                color: #3a3a3a;
                padding: 5px 10px;
                border: $border;
                border-radius: $border-radius;
                margin: 3px 0;
                margin-right: 2px;
                text-transform: capitalize;
                display: inline-block;
              }
            }

            &--title {
              h1 {
                font-weight: 600;
                font-size: 28px;
                line-height: 36px;
                color: $brand-primary;
                margin-bottom: 15px;

                /* These are technically the same, but use both */
                overflow-wrap: break-word;
                word-wrap: break-word;

                -ms-word-break: break-all;
                /* This is the dangerous one in WebKit, as it breaks things wherever */
                word-break: break-all;
                /* Instead use this non-standard one: */
                word-break: break-word;

                /* Adds a hyphen where the word breaks, if supported (No Blink) */
                -ms-hyphens: auto;
                -moz-hyphens: auto;
                -webkit-hyphens: auto;
                hyphens: auto;
              }
            }

            &--profile {
              a {
                position: relative;
                left: 10px;

                h4 {
                  font-size: 14px;
                  font-weight: 600;
                }
              }
            }

            &--guests {
              border-top: 1px solid #ebe9e9;

              h4 {
                font-weight: 600;
                font-size: 20px;
                line-height: 26px;
                color: $brand-primary;
                display: flex;
                align-items: center;
              }

              p {
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                color: $brand-primary;
              }
            }
          }

          &--attending {
            @include guest-list;
            margin: $s-s 0;

            ul {
              padding-left: 0;
            }

            .guest_profile {
              @include guests-profile;
            }
          }
        }
      }

      &-footer {
        background-color: white !important;
        padding-top: 15px;
        position: relative;
        min-height: 82px;
        .event {
          &--cta {
            padding-bottom: 0;

            justify-content: space-between;

            .approved-request.btn,
            .reschedule-btn.btn,
            .decline-request.btn {
              box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.11), 1px 1px 6px 0px rgba(0, 0, 0, 0.02);
              padding: 15px 0px;
              border-radius: 5px;
              display: block;
              margin: auto;
              width: 247px;
              height: 47px;
              margin-bottom: 16px;
            }

            .decline-request.btn {
              color: #D93025;
              font-weight: 500;
              border: none;
              box-shadow: none;
              position: absolute;
              text-align: center;
              left: 182px;
              top: 157px;
            }

            .reschedule-btn.btn {
              border: 1px solid #3A3A3A;
              font-weight: 500;
              color: #3A3A3A;
            }

            .approved-request.btn {
              background: #F69C3A;
              color: #FFF;
            }

            @media (max-width: $tablet) {
              text-align: center;

              .decline-request.btn {
                left: unset;
              }
            }

            .btn {
              &_primary {}

              // margin-top: $s-s;
              &.email {
                width: calc(100% - #{$s-m});
                margin-left: $s-s;
                background: white;
              }

              &.request-invite {
                @include orange-button;
                width: 299px;
                border-radius: $border-radius !important;
                margin: auto;

                &--center {
                  position: absolute;
                  left: 0;
                  right: 0;
                  top: 0;
                  bottom: 0;

                  @media (max-width: $tablet) {
                    position: relative;
                  }
                }
              }

              &.directions {
                @include dark-button;
                margin-left: 15px;
                width: 100px !important;
                margin-top: 0;

                @media (max-width: $tablet) {
                  margin-left: 0;
                  margin-top: 0;
                }
              }

              &.edit_event {
                @include dark-button;
                margin-left: 15px;
                width: 100px !important;

                @media (max-width: $tablet) {
                  width: 100% !important;
                  min-width: fit-content;
                  margin-left: 0;
                }
              }

              &.pending {
                background-color: $yellow;
                border-color: $yellow;
              }

              &.accept {
                height: 40px;
                border-radius: 0 0 0 5px;
              }

              &.paynow {
                @include dark-button;
                min-width: 155px;
                width: unset;
              }

              &.cancelinvite {
                @include transparent-button;
                border-color: #dcdfe0;
                border-radius: 7px !important;
                height: 48px !important;
                min-width: 155px;
                width: unset;

                @media (max-width: $tablet) {
                  width: 100%;
                  border: none;
                }
              }

              &.decline {
                height: 40px;
                border-radius: 0 0 5px 0;
                float: right;
              }

              &.cancel-seat-btn {
                margin-bottom: 0 !important;
                padding-top: 26px;
              }
            }

            .you-have-cancelled-your-seat {
              padding-top: 13px;

              &--btn {
                margin: unset !important;
              }

              @media (max-width: $tablet) {
                order: 2;

                &--btn {
                  order: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  &_edit {
    &--container {
      padding: 24px !important;

      form {
        .select-co-host {
          label {
            margin-bottom: 5px;
          }

          .select2-container {
            .select2-dropdown {
              .select2-search {
                &__field {
                  border: none !important;
                }
              }
            }

            .selection {
              .select2-selection {
                border-top: none !important;
                border-left: none !important;
                border-right: none !important;
                border-color: #cbcbcc !important;

                &__rendered {}
              }
            }
          }
        }
      }
    }

    &--hero {
      min-height: 376px;
      background: url(/static/dist/img/host-event-background.jpg) center/cover;
      width: 100%;
      margin-left: -$s-m;
      margin-top: -$s-m;
      margin-bottom: $s-l !important;

      h1 {
        color: white;
        text-shadow: $text-shadow;
        text-align: center;
        position: relative;
        top: 23px;
      }
    }

    &--form {
      .event-type {
        margin-bottom: $s-m;

        label {
          margin-right: 0;

          &.public {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            margin-right: -4.5px;

            &:hover {
              @include active(white, #7db345);
              outline: none !important;
              box-shadow: none !important;
            }
          }

          &.private {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin-left: -5.5px;

            &:hover {
              @include active(white, #e65062);
              outline: none !important;
              box-shadow: none !important;
            }
          }
          &.organisation-btn {
            border-radius: 0;
            &:hover {
              @include active(white, #e65062);
              outline: none !important;
              box-shadow: none !important;
            }
          }
        }

        input {
          &[type="radio"] {
            @include dissapear();
          }
        }

        #private:checked~label.private {
          @include active(white, #e65062);
        }
        #organisation:checked~label.organisation-btn {
          @include active(white, #e65062);
        }
        #public:checked~label.public {
          @include active(white, #7db345);
        }
      }

      .meal-type {
        position: relative;

        label {
          display: inline-block;
        }

        .label {
          display: block;
        }

        .error:first-of-type {
          position: absolute;
          left: 70px;
          top: 0px;
        }

        i {
          @include circle-frame(40px, 1px, $font-general);
          transition: 0.1s background-color ease-in-out;
          margin-right: 72px;
          padding: 5px;
          cursor: pointer;

          &::before {
            font-size: 14px;
          }

          &::after {
            position: relative;
            top: 10px;
            display: inline-block;
            font-size: 10px;
            text-transform: uppercase;
            width: 300%;
            left: -100%;
            text-align: center;
          }

          &.meat {
            &:hover {
              @include active(white, #e65062);
              border-radius: calc(40px / 2) !important;
              border: 3px solid #ededed !important;
            }

            &::after {
              content: "Contains Meat";
            }
          }

          &.vegetarian {
            &:hover {
              @include active(white, #7db345);
              border-radius: calc(40px / 2) !important;
              border: 3px solid #ededed !important;
            }

            &::after {
              content: "Vegetarian";
            }
          }

          &.vegan {
            &:hover {
              @include active(white, #3196be);
              border-radius: calc(40px / 2) !important;
              border: 3px solid #ededed !important;
            }

            &::after {
              content: "Vegan";
            }
          }

          &.active {
            opacity: 1;
          }
        }

        .vegan {
          margin-right: 0 !important;
        }

        input {
          &[type="radio"] {
            @include dissapear();
          }
        }

        #meat:checked~label.meat i {
          @include active(white, #e65062);
        }

        #vegetarian:checked~label.vegetarian i {
          @include active(white, #7db345);
        }

        #vegan:checked~label.vegan i {
          @include active(white, #3196be);
        }
      }

      .event-description {
        margin-top: 30px;
      }

      .event-guest-number {
        .no-guests {
          margin-top: 20px;
        }
      }

      .event-location {
        .address {
          color: #3F4142;
          font-family: 'Inter';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          /* 133.333% */
          letter-spacing: 0.072px;
        }

        .address-search {
          border-top: 0 !important;
          border-left: 0 !important;
          border-right: 0 !important;
          border-radius: 0 !important;
          padding-left: 0;

          &--label {
            margin-top: 20px;
          }
        }
      }

      .choose-a-number {
        .event-time {
          margin-top: 20px;
        }
      }

      .cooking-class {
        margin-bottom: $s-m;

        input[type="checkbox"] {
          font-size: 14px;
          width: 20px;
          display: inline-block;
        }

        label {
          font-size: 14px;
          display: inline-flex;
          margin-left: 10px;
          align-self: center;
        }

        .online-event {
          margin-bottom: $s-s;
        }
      }

      .ingredients {
        display: none;
      }

      .instructions {
        display: none;
      }

      // Simple MDE
      .CodeMirror,
      .CodeMirror-scroll {
        min-height: 150px;
      }

      .zoom-notice {
        display: none;
        margin-bottom: $s-m;
      }

      .event-invites {
        display: none;
        clear: both;

        .add-guest {
          margin-bottom: 40px;
          border-radius: $border-radius;
          box-shadow: none;

          i {
            margin-right: 10px;
          }
        }

        .guest-wrapper {
          display: none;

          label {
            margin-top: 20px;
          }
        }

        .notice {
          margin-top: $s-s;
        }

        .row {
          position: relative;
        }

        .delete {
          font-size: 20px;
          top: 33px;
          right: $s-s;
          position: absolute;
          width: 30px;
          transition: 0.2s color ease-in-out;

          &:hover {
            color: crimson;
          }

          &:active {
            color: darkred;
          }
        }
      }

      .event-location {
        .wrapper {
          position: relative;
          clear: both;
        }

        label.radio {
          width: 80%;
          float: right;
          font-size: 16px;
          text-transform: capitalize;
          font-weight: 400;

          span {
            font-size: 12px;
            font-weight: 300;
            font-style: oblique;
          }

          &.other {
            margin-bottom: $s-m;
          }
        }

        input.radio {
          width: 10%;
          float: left;
          font-size: 16px;
          margin-top: $s-t;
        }
      }

      .event-guest-number {
        .update {
          right: $s-s;
          position: absolute;
          top: 21px;
          border-radius: 0;
          min-width: unset;
          box-shadow: none;
          height: 30px;
          display: none; // for switch

          &:hover {
            box-shadow: inherit;
          }
        }

        input.guests {
          padding-left: 5px;
          border-radius: 3px !important;
        }
      }

      .create-event {
        @include orange-button;
        width: 327px;
      }
    }

    &.private {
      .btn.update {
        display: block;
        width: unset;
      }
    }
  }

  &-informaton_text {
    margin-top: 13px;
  }

  // Custom slider
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    &--co-host {
      margin-top: 0;
    }
  }

  .orange-block {
    margin-left: 19px;
    border-radius: 4px;
    border: 1px solid var(--brand-primary, #F69C3A);
    background: rgba(246, 156, 58, 0.10);
    padding: 7px;
    align-items: center;
    height: 34px;

    h4 {
      color: #F69C3A;
      font-family: unset;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;

      /* 153.846% */
      &.guest-fee {
        color: #3A3A3A;
      }
    }
  }

  .select-sponsored-event {
    // max-width: 300px;

    @media (max-width: $tablet) {
      max-width: 100%;
    }

    .select-component {
      position: relative;

      &::after {
        content: url('/static/dist/img/selector chevron down.svg');
        position: absolute;
        right: 12px;
        top: 12px;
      }

      select {
        height: 48px;
        border-radius: 7px;
        border: 1px solid #DCDFE0;
        background: #FFF;
        padding: 12px 29px 12px 18px;
        color: var(--grey-black-primary, #3F4142);
        font-family: unset;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
        /* for Firefox */
        -moz-appearance: none;
        /* for Chrome */
        -webkit-appearance: none;

        &::-ms-expand {
          display: none;
        }
      }
    }

    p {
      color: #888B8E;
      font-family: unset;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 142.857% */
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .radio-registered {
      list-style-type: none;
      padding-left: 0;
      margin-top: 8px;
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;

      input {
        width: 20px;

      }

      input {
        opacity: 0;
        position: absolute;
      }

      input label {
        display: inline-block;
        vertical-align: middle;
        margin: 5px;
        cursor: pointer;
      }

      label {
        position: relative;
      }

      input+label:before {
        content: '';
        background: #fff;
        border: 1px solid #DCDFE0;
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        padding: 2px;
        margin-right: 10px;
        text-align: center;
      }

      input+label:before {
        border-radius: 50%;
      }

      input:checked+label:before {
        background-image: url("/static/dist/img/checkmark.svg");
        font-family: 'FontAwesome';
        color: #FFFFFF;
        background-color: #3A3A3A;
        background-repeat: no-repeat;
        background-position-x: 3px;
        background-position-y: 5px;
        background-size: 11px;
      }

      label {
          width: 124px;
          display: contents;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #3A3A3A;
      }
    }

    .reg_btn {
      border-radius: 7px;
      border: 1px solid #C3C3C3;
      height: 34px;
      width: 139px;
      color: #3F4142;
      font-family: unset;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 150% */
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        text-decoration: none;
      }
    }
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked+.slider {
    background-color: $brand-primary;
  }

  input:focus+.slider {
    box-shadow: 0 0 1px $brand-primary;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .stack4,
  .stack8 {
    display: flex !important;
  }

  .stack7 {
    justify-content: space-between !important;
  }

  #cancelSeat-container {
    .pending {
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      padding-left: 0;
      padding-right: 0;

      @media (max-width: $tablet) {
        width: 100% !important;
      }
    }
  }

  .email-guest-of-this-event {
    @include dark-button;
    width: 148px !important;
    margin-left: 15px;

    @media (max-width: $tablet) {
      width: 100% !important;
      margin-left: 0;
      order: 1;
    }
  }

  .event-cancel {
    height: 48px;

    @media (max-width: $tablet) {
      width: 100%;
      order: 2;
      text-align: center;
      justify-content: center;
      border-radius: 0;
      margin-bottom: -13px;

      //check all event card mobile states
    }
  }

  .cancel-event-to-this-event {
    height: 48px;
    width: 148px !important;
    padding-left: 0;
    text-align: left;
    justify-content: left;

    @media (max-width: $tablet) {
      /* width: 100% !important; */
      order: 2;
      text-align: center;
      justify-content: center;
      border-top: 1px solid #ebe9e9;
      margin-top: 19px;
      border-radius: 0;
      margin-left: -19px;
      margin-bottom: -13px;
      padding: 29px 0;
      /* margin-right: -20px; */
      width: 92vw !important;
    }
  }

  .cancel-line {
    margin: 0 !important;
    border-top: 3px solid red !important;
    margin-top: 5px;
    display: inline-block;
    width: 100%;
  }

  // .create-gathering-progress-bar{
  //   .col.bar{
  //     width: calc(50%/4);
  //   }
  // }
  .title{
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 15px;
  }

  .form-group {
    .btn.disabled {
      padding: 23px 0;
      cursor: not-allowed;
      border-radius: 5px;
      background: #D3D3D3;
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.11), 1px 1px 6px 0px rgba(0, 0, 0, 0.02);
    }
    label{
      font-family: 'Inter';
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.004em;
    }
    input, textarea{
      font-family: 'Inter';
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.003em;
    }

    .btn-cancel {
      border-radius: 5px;
      border: 1px solid #3A3A3A;
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.11), 1px 1px 6px 0px rgba(0, 0, 0, 0.02);
      color: #3A3A3A;
      text-align: center;
      font-family: 'Inter';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.048px;
      padding: 23px 0;
    }

    .btn-next {
      max-width: 100px;
    }

    #meal_options {
      list-style: none;
      padding-left: 0;

      li {
        display: inline-block;
        margin: 6px 12px 6px 0
      }

      input {
        display: none;
      }

      label {
        border-radius: 8px;
        border: 1px solid #3A3A3A;
        padding: 11px 22px;
        width: fit-content;
        cursor: pointer;
        margin-bottom: 0;
      }

      input:checked~label {
        border: 1px solid #34A853;
        background: #EDF6EE;
        padding-left: 15px;
        padding-right: 30px;
        position: relative;

        &::after {
          content: url('/static/dist/img/meal-type.png');
          position: absolute;
          top: 14px;
          right: 10px;
        }
      }
    }
  }

  .cancelation-policy {
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    background: #FEFAEC;
    padding: $s-t + 3px;
    margin-bottom: 15px;
    .cancelation-policy-heading{
      font-family: 'Inter';
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.006em;
      display: flex;
      position: relative;

      .warning-icon {
        background-image: url('/static/dist/img/warning-icon.png');
        margin-right: $s-t;
        width: 13px;
        height: 13px;
      }

      .view {
        position: absolute;
        right: -1px;
        top: -2px;
        font-family: 'Inter';
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
        letter-spacing: 0.006em;
        text-align: right;
        text-decoration: underline;

      }
    }

    ul {
      margin: 0;
      li{
        font-family: 'Inter';
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.006em;
      }
    }
  }

  .info-notice {
    border-radius: 5px;
    border: 1px solid #EBEBEB;
    background: #E9F0FA;
    max-width: 329px;
    padding: 0 16px;
    margin-bottom: 16px;

    .info-icon {
      background-image: url('/static/dist/img/feedback.png');
      margin-right: 10px;
      width: 13px;
      height: 13px;
      display: inline-block;
      background-repeat: no-repeat;
      position: relative;
      top: 16px;
    }

    .text {
      color: #3A3A3A;
      font-family: 'Inter';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.072px;
      display: flex;
      flex-direction: column;
      position: relative;
      top: -7px;
      left: 6px;
    }

    .cancel {
      color: #3A3A3A;
      font-family: 'Inter';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.072px;
      text-decoration-line: underline;
      padding-left: 0;
      position: relative;
      left: -10px;
    }
  }

  .own-venue-label {
    color: #3A3A3A;
    font-size: 12px !important;
    font-style: italic;
    font-weight: 400 !important;
    position: relative;
    padding-left: 33px;
    padding-top: 4px;

    .checkmark {
      background-color: #FFFFFF;
      border: 1px solid #DCDFE0;
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      border-radius: 3px;
    }

    input:checked~.checkmark {
      background-color: #3A3A3A;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    input:checked~.checkmark:after {
      display: block;
    }

    .checkmark:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 30px;
      width: 28px !important;
      z-index: 1;
    }
  }

  #food_allergies,
  #preferences,
  #caterer_preferences {
    padding: 0;

    li {
      list-style: none;
      position: relative;
      margin-bottom: 16px;

      label {
        color: #222;
        font-family: 'Inter';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
        letter-spacing: 0.048px;
        position: relative;
        top: 4px;
        left: 33px;
      }

      .checkmark {
        background-color: #FFFFFF;
        border: 1px solid #DCDFE0;
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        border-radius: 3px;
      }

      input:checked~.checkmark {
        background-color: #3A3A3A;
      }

      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      input:checked~.checkmark:after {
        display: block;
      }

      .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 30px;
        width: 28px !important;
        z-index: 1;
      }
    }
  }

  #venue-tab,
  #caterer-tab {
    .filter {
      top: -22px;
      position: relative;
      right: 24px;
      cursor: pointer;

      &::after {
        content: url('/static/dist/img/sort-filter.png');
        position: absolute;
      }
    }

    .event-listing {
      width: 103%;
      box-shadow: none;
      border: none;

      .search-block {
        margin-top: -5px;

        // .form-buttons{
        //   .btn_search{
        //     width: 50px !important;
        //     min-width: 51px !important;
        //   }
        // }
        .form-group {
          width: unset;

          .search-field {
            width: 182px;
          }

          #caterer_date,
          #venue_date {
            @media (min-width: $tablet) {
              width: 172px;
            }
          }

          .select2-container {
            width: 156px !important;
          }
        }
      }
    }
  }

  .menu-offering {
    h5 {
      color: #1967D2;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      /* 155.556% */
      margin-bottom: 10px;
    }

    .form-group {
      label {
        font-weight: 600 !important;
        font-size: 14px !important;
        color: #3A3A3A !important;
        text-transform: none !important;
      }

      input {
        border-radius: 7px;
        border: 1px solid #CBCBCC;
        padding: 8px;
        color: #3A3A3A !important;
      }

      textarea {
        border: 1px solid #CBCBCC;
        border-radius: 7px;
        color: #3A3A3A !important;
        height: 168px;
        width: 100%;
      }
    }

    .radio {
      color: #3A3A3A !important;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      display: inline-block;
      width: 100%;
      position: relative;
      padding-left: 35px;

      .info {
        padding: 8px 33px;
        padding-top: 16px;
        margin-top: 6px;
        margin-bottom: -8px;

        p {
          font-weight: 600 !important;
          font-size: 12px !important;
          margin-top: -4px !important;
        }
      }

      .menu-price {
        position: absolute;
        right: 0;
        top: -8px;
        font-weight: 600;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked~.checkmark {
          background-color: #3A3A3A;

          &:after {
            display: block;
          }
        }
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
        border-radius: 50%;

        &:after {
          content: "";
          position: absolute;
          display: none;
          top: 9px;
          left: 9px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: white;
        }
      }

      // &:hover input ~ .checkmark {
      //     background-color: #ccc;
      // }
    }
  }

  #moreFiltercaterer,
  #moreFilter {
    border-radius: 8px;
    color: #3A3A3A;
    font-family: 'Inter';
    #caterer_location{
      width: 50% !important;
    }

    .modal-dialog {
      #venue_location {
        max-width: 550px;
      }

      .line {
        background: #EBEBEB;
        height: 1px;
        margin: 50px 0;
      }
    }

    .modal-header {
      border-bottom: 1px solid#EBEBEB !important;
      margin-bottom: 22px;

      .modal-title {
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        width: 100%;
      }
    }

    .btn-next {
      border-radius: 5px;
      background: #F69C3A;
      color: #FFF;
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.11), 1px 1px 6px 0px rgba(0, 0, 0, 0.02);
    }
  }

  .venue-card {
    //display: flex;
    // max-width: 360px;
    // min-width: 360px;
    padding: 16px;
    //align-items: center;
    border-radius: 5px;
    border: 1px solid #EBEBEB;
    background: #FFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    margin: 10px;
    max-width: 30%;

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    .venue-image {
      max-width: 84px;
      height: 84px;
      border-radius: 4px;
      border: 1px solid #EBEBEB;
      margin-left: 13px;
      background-position: 100%;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .venue-text {
      position: relative;
      width: calc(100% - 84px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .dollar {
        position: absolute;
        right: 16px;
        top: 0;
        padding: 3px;
        color: #FFF;
        font-family: 'Inter';
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
        /* 130.769% */
        letter-spacing: 1.95px;
        border-radius: 4px;
        background: #8DD728;
      }

      .rating-stars {
        margin-bottom: 15px;
      }

      .venue-name {
        color: #3A3A3A;
        font-family: 'Inter';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.056px;
      }

      .venue-address {
        position: relative;
        color: #A1A1A1;
        font-family: 'Inter';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 128.571% */
        letter-spacing: 0.056px;
        padding-left: 25px;

        &::before {
          content: url('/static/dist/img/map-marker.svg');
          position: absolute;
          left: -5px;
          pointer-events: none;
        }
      }
    }
  }

  // Desktop specific styling
  @media (min-width: $tablet) {

    .pricing_type {
      .select2{
        width: 50% !important;
      }
    }
    #moreFiltercaterer,
    #moreFilter {
      .modal-dialog {
        max-width: calc(100vw - 60px);
      }
    }

    #food_allergies,
    #preferences,
    #caterer_preferences {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    .event {
      &-type {
        &.public {
          &:hover {
            @include active(white, #7db345);
          }
        }

        &.private {
          &:hover {
            @include active(white, #e65062);
          }
        }
      }
    }
  }

  // Mobile specific styling
  @media (max-width: $tablet) {
    .organisation-badge{
      width: 100%;
    }
    .event {
      &-type {
        .btn {
          min-width: 33.1%;
        }
      }
    }
    .create-gathering-progress-bar {
      .col.bar {
        width: calc(25%/4);
      }
    }

    #moreFilter,
    #moreFiltercaterer {
      .modal-dialog {
        margin-top: 80px;
      }
    }

    .venue-card {
      max-width: 94%;
    }

    #venue-tab,
    #caterer-tab {
      .event-listing {
        width: 100%;

        .search-block {
          margin: 0;

          .form-group {
            width: 100%;

            .search-field,
            #venue_date,
            .select2-container {
              width: 100% !important;
            }
          }
        }
      }

      .box-line {
        background: #FFF;
        box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.12);
        height: 7px;
        width: 100vw;
        margin: 0;
        position: absolute;
        left: 0;
      }
    }

    &_view {
      .cancel-seat-btn {
        padding-top: 0 !important;
        position: relative;
      }

      .card-footer {
        border-top:0;
      }

      #footer-container {
        // display: grid;
        justify-content: center !important;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        /* optional */
        -webkit-box-align: start;
        -moz-box-align: start;
        -ms-flex-align: start;
        -webkit-align-items: flex-start;
        align-items: flex-start;

        hr {
          margin-left: -50px;
          margin-right: -50px;
          border-top: 1px solid #EBE9E9;
          opacity: 1;
          width: -webkit-fill-available;
        }
      }

      #footer-container>#cancelSeat {
        order: 2;
        padding-top: 0;
        width: 100% !important;
      }

      #footer-container>#cancelSeat-container {
        order: 1;
        width: 100%;
      }
    }

    &_edit {
      &--hero {
        min-height: 376px;
        background: url(/static/dist/img/host-event-background-mobile.png) center/cover;
        width: 100%;
        margin-left: -$s-m;
        margin-top: -$s-m;
        margin-bottom: $s-s !important;
      }
    }
  }
}

.mobile-directions {
  @include dark-button;
  height: 48px;
  width: 100px;
  margin-left: 15px;

  @media (max-width: $tablet) {
    width: 100%;
    min-width: fit-content;
    margin-left: 0;
  }
}