.login-sign-up {
  // General styling
  background: url('/static/dist/img/Sign In _ Desktop.jpg') center/cover;
  padding-left: 20px;
  padding-right: 20px;
  // min-height: calc(100vh - (#{$footer-cta-height} + #{$navigation-height-desktop}));
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    padding: 44px 44px 74px 44px;
    background: white;

    .forgot-password {
      @include paragraph-size;

      &-spacer {
        height: 10px;
        width: 100%;
      }
    }

    p {
      margin-left: 0;
    }

    .italic-text {
      font-style: italic;
    }

    .logo-img {
      &--container {
        text-align: left;

        .top-bar {
          border-bottom: 1px solid #ECECEC;
          padding: 0 44px;
        }
        img {
          height: 27px;
          margin-bottom: 23px;
        }

        h1 {
          font-size: 32px;
          line-height: 36px;
          margin-bottom: 8px;

          .line-br {
            display: none
          }
        }

        p {
          @include paragraph-size;
          margin-left: 0;
        }

        a {
          color: #3571DE;
          font-weight: 500;
          @include paragraph-size;
        }
      }
    }

    .btn {
      margin: auto;
      display: block;
      margin-top: $s-m +2px;
      @include dark-button;
    }

    .login {
      padding-left: 0;
      padding-right: 0;
    }

    form {
      background: white;

      input {
        margin-top: 5px !important;
        font-size: 16px;
        color: $brand-primary;

        &.checkbox {
          width: unset;
          position: relative;
          top: 1.9px;
          height: unset;
          margin-right: 4px;
        }
      }

      p {
        font-size: 12px;
      }

      label {
        margin-top: $s-s + 5px;

        &.checkbox {
          font-size: 14px;
          font-weight: 400;
          text-transform: none;
          line-height: 28px;
        }
      }

      .checkbox {
        display: inline-block;
        width: initial;
      }
    }
  }

  .nav-tabs {
    width: 230px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $s-m;

    li a {
      width: 115px;
      text-align: center;
      padding: 5px $s-s;
      display: block;
      border: $border;
      transition: .2s all ease-in-out;
      text-decoration: none;
      font-weight: 500;
      text-transform: capitalize;
      background: white;

      &.active {
        background: $font-general;
        color: white;
        text-decoration: underline;
      }

      &.login {
        border-radius: 5px 0 0 5px;
      }

      &.sign-up {
        border-radius: 0 5px 5px 0;
      }
    }
  }

  // Desktop specific styling
  @media (min-width: $tablet) {
    //  min-height: calc(100vh - (#{$footer-cta-height} + #{$navigation-height-desktop} + 8px))
    min-height: 100vh;
  }

  //Mobile specific styling
  @media (max-width: $tablet) {
    background: url('/static/dist/img/Sign In _ Desktop--mobile.jpg') center/cover;

    .card {
      padding: 44px 22px 74px 22px;

      .logo-img {
        &--container {
          .top-bar {
            border-bottom: 1px solid #ECECEC;
            padding: 0 44px;
          }
          h1 {
            .line-br {
              display: none
            }
          }
        }
      }
    }
  }
}

.log-in-modal,.sign-up-in-modal {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);

  .card {
    padding: 44px 44px 74px 44px;
    background: white;
    margin-top: 150px;

    .forgot-password {
      @include paragraph-size;

      &-spacer {
        height: 10px;
        width: 100%;
      }
    }

    p {
      margin-left: 0;
    }


    .italic-text {
      font-style: italic;
    }

    .button_as_link {
      color: #3571DE;
      border: none;
      background-color: transparent;

      &:hover {
        text-decoration: underline;
        border: none;
        background-color: transparent;
      }
      &:focus {
        text-decoration: underline;
        border: none;
        outline: none;
        background-color: transparent;
        color: purple;
      }
    }

    .logo-img {
      &--container {
        text-align: left;
        .top-bar {
          border-bottom: 1px solid #ECECEC;
          padding: 0 44px;
        }
        img {
          height: 27px;
          margin-bottom: 23px;
        }

        h1 {
          font-size: 32px;
          line-height: 36px;
          margin-bottom: 8px;
          margin-top: 22px;

          .line-br {
            display: none
          }
        }

        p {
          @include paragraph-size;

          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          color: #7C7B7B;
          font-family: 'Inter';
        }

        a {
          color: #3571DE;
          font-weight: 500;
          @include paragraph-size;
        }
      }
    }

    .btn {
      margin: auto;
      display: block;
      margin-top: $s-m +2px;
      @include dark-button;
    }

    .or {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 10px;
    }

    .btn_light {
      margin: auto;
      display: block;
      margin-top: 10px;
      color: #3A3A3A;
      border-radius: 7px !important;
      background-color: white;
      @include transparent-button;

      span {
        margin: auto;
      }
    }

    .login {
      padding-left: 0;
      padding-right: 0;
    }

    form {
      background: white;

      input {
        margin-top: 5px !important;
        font-size: 16px;
        color: $brand-primary;

        &.checkbox {
          width: unset;
          position: relative;
          top: 1.9px;
          height: unset;
          margin-right: 4px;
        }
      }

      p {
        font-size: 12px;
        margin-top: 8px;
      }

      label {
        margin-top: 36px;

        &.checkbox {
          font-size: 14px;
          font-weight: 400;
          text-transform: none;
          line-height: 28px;
        }
      }

      .checkbox {
        display: inline-block;
        width: initial;
      }
    }
  }

  .nav-tabs {
    width: 230px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $s-m;

    li a {
      width: 115px;
      text-align: center;
      padding: 5px $s-s;
      display: block;
      border: $border;
      transition: .2s all ease-in-out;
      text-decoration: none;
      font-weight: 500;
      text-transform: capitalize;
      background: white;

      &.active {
        background: $font-general;
        color: white;
        text-decoration: underline;
      }

      &.login {
        border-radius: 5px 0 0 5px;
      }

      &.sign-up {
        border-radius: 0 5px 5px 0;
      }
    }
  }

  // Desktop specific styling
  @media (min-width: $tablet) {
    //  min-height: calc(100vh - (#{$footer-cta-height} + #{$navigation-height-desktop} + 8px))
    min-height: 100vh;
  }

  //Mobile specific styling
  @media (max-width: $tablet) {
    display: block;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);

    .card {
      padding: 44px 22px 74px 22px;

      .logo-img {
        &--container {
          .top-bar {
            border-bottom: 1px solid #ECECEC;
            padding: 0 44px;
          }
          h1 {
            .line-br {
              display: none
            }
          }
        }
      }
    }
  }
  .otp-container {
    .otp-code,.otp-code1 {
      input {
        height: 64px;
        border-radius: 6px;
        background: #ECECEC;
        color: var(--Black, #3A3A3A);
        font-family: 'Inter';
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 100% */
      }
    }
  }
}

.sign-up-in-modal {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 0;

  .container {
    margin: 120px auto auto auto;
  }

  .card {
    padding: 30px 44px 40px 44px;
    background: white;
    margin: 75px auto auto auto;

    .forgot-password {
      @include paragraph-size;

      &-spacer {
        height: 10px;
        width: 100%;
      }
    }

    p {
      margin-left: 0;
    }

    .italic-text {
      font-style: italic;
    }

    .button_as_link {
      color: #3571DE;
      border: none;
      background-color: transparent;

      &:hover {
        text-decoration: underline;
        border: none;
        background-color: transparent;
      }
      &:focus {
        text-decoration: underline;
        border: none;
        outline: none;
        background-color: transparent;
        color: purple;
      }
    }

    .logo-img {
      &--container {
        text-align: left;
        .top-bar {
          border-bottom: 1px solid #ECECEC;
          padding: 0 44px;
        }
        img {
          height: 27px;
          margin-bottom: 23px;
        }

        h1 {
          font-size: 32px;
          line-height: 36px;
          margin-bottom: 8px;

          .line-br {
            display: none
          }
        }

        p {
          @include paragraph-size;
          margin-left: 0;
        }

        a {
          color: #3571DE;
          font-weight: 500;
          @include paragraph-size;
        }
      }
    }

    .btn {
      margin: auto;
      display: block;
      margin-top: $s-m +2px;
      @include dark-button;
    }

    .or {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 10px;
    }

    .btn_light {
      margin: auto;
      display: block;
      margin-top: 10px;
      color: #3A3A3A;
      border-radius: 7px !important;
      background-color: white;
      @include transparent-button;

      span {
        margin: auto;
      }
    }

    .login {
      padding-left: 0;
      padding-right: 0;
    }

    form {
      background: white;

      input {
        margin-top: 5px !important;
        font-size: 16px;
        color: $brand-primary;

        &.checkbox {
          width: unset;
          position: relative;
          top: 1.9px;
          height: unset;
          margin-right: 4px;
        }
      }

      p {
        font-size: 12px;
      }

      label {
        margin-top: $s-s + 5px;

        &.checkbox {
          font-size: 14px;
          font-weight: 400;
          text-transform: none;
          line-height: 28px;
        }
      }

      .checkbox {
        display: inline-block;
        width: initial;
      }
    }
  }

  .form-split {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5px;

    .iti {
      width: 100%;
    }
  }

  .nav-tabs {
    width: 230px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $s-m;

    li a {
      width: 115px;
      text-align: center;
      padding: 5px $s-s;
      display: block;
      border: $border;
      transition: .2s all ease-in-out;
      text-decoration: none;
      font-weight: 500;
      text-transform: capitalize;
      background: white;

      &.active {
        background: $font-general;
        color: white;
        text-decoration: underline;
      }

      &.login {
        border-radius: 5px 0 0 5px;
      }

      &.sign-up {
        border-radius: 0 5px 5px 0;
      }
    }
  }

  // Desktop specific styling
  @media (min-width: $tablet) {
    //  min-height: calc(100vh - (#{$footer-cta-height} + #{$navigation-height-desktop} + 8px))
    min-height: 100vh;
  }

  //Mobile specific styling
  @media (max-width: $tablet) {
    display: block;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);

    .container {
      margin: 70px auto auto auto;
    }

    .form-split {
      display: block;
    }

    .card {
      padding: 44px 22px 74px 22px;

      .logo-img {
        &--container {
          .top-bar {
            border-bottom: 1px solid #ECECEC;
            padding: 0 44px;
          }
          h1 {
            .line-br {
              display: none
            }
          }
        }
      }
    }
  }
}

.register-modal {
  form {
    .card {
      .logo-img--container{
        .form-split {
          .form-split {
            .input-divider {
              label {
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
  }
}