// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
 /* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #CBCBCC; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: $brand-primary; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $brand-primary; 
}
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  position: relative;
  min-height: 100%;
}

h1,h2,h3,h4,h5,h6, p, span, label, input {
  @include no-margin-padding;
}
// Force the mobile max-width
// .container {
//   max-width: $content-grid;
// }

body {
  padding-bottom: 0 !important; // Overrides sharethis inline styles
  > .content {
    padding-top: $navigation-height-desktop;
    background: white !important;

    //check Bank Account Details edit page mobile before padding edit
    padding: 0;
  }
}

/**
* Make all elements from the DOM inherit from the parent box-sizing
* Since `*` has a specificity of 0, it does not override the `html` value
* making all elements inheriting from the root box-sizing value
* See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
*/
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background: $background-colour !important;
  // background: #2d3036;
//   height: 100vh;
}

input::-webkit-calendar-picker-indicator { display: none }
/**
 * Basic styles for links
 */
a {
  color: $brand-primary;
  text-decoration: none;

  @include on-event {
    color: $font-general;
    text-decoration: underline;
  }
}
.host-event-container {
  max-width: 710px !important;
  margin-bottom: 54px !important;
}
.form-control:disabled {
  background-color: white;
  opacity: 0.5 !important;
}
.disabled {
  opacity: 0.5 !important;
}
input {
  width: 100%;
  outline: none;
  cursor: pointer;
  // Hide the default date picker arrow
  &::-webkit-calendar-picker-indicator, 
  &::-webkit-inner-spin-button,
  &::-webkit-clear-button {
    display: none;
  }
  // Hide the default date picker prompt
  &[type="date"]::-webkit-input-placeholder{ 
    visibility: hidden !important;
  }
}
input[type=checkbox]{
  width: auto !important;
  flex: 0 0 16px !important;
}
select {
  background: url('/static/dist/img/select_arrow.svg') no-repeat right ;
  background-position-x: 681px;
  &::-ms-expand {
    display: none;
  }

}
.centered-text {
  position: absolute;
  left: 0;;
  right: 0;
}
hr {
  border-top: 2px solid;
  opacity: .5;
  &.dashed {
    background-image: linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 11px 2px;
    background-repeat: repeat-x;
    border-top: unset !important;
    border: unset;
    height: 2px;
    opacity: .3 !important;
    margin-bottom: 10px;
  }
  &.light {
    border-color: #CBCBCC !important;
  }
  &.requests {
    margin-bottom: 20px;
    margin-top: 20px !important;
  }
}

a {
  cursor: pointer;
}
.date-cant-be-changed {
  font-weight: 400;
  font-size: 14px;
  color: #ADADAD;
  margin-right: 7px;
  position: relative;
  top: 3px;
}
@media all and (display-mode: standalone) {

#progress {
	position: fixed;
	z-index: 100000000000;
	top: 0;
	left: -6px;
	width: 1%;
	height: 3px;
  background-color: #F69C3A;
  
  -moz-border-radius: 1px;
	-webkit-border-radius: 1px;
	border-radius: 1px;

  -moz-transition: width 600ms ease-out,opacity 500ms linear;
	-ms-transition: width 600ms ease-out,opacity 500ms linear;
	-o-transition: width 600ms ease-out,opacity 500ms linear;
	-webkit-transition: width 600ms ease-out,opacity 500ms linear;
	transition: width 1000ms ease-out,opacity 500ms linear;
  
}
}
.module {
  @include module;
  > .container {
    padding: 0;
  }
}

.component {
  margin: $s-s 0;
}
.login {
  .alert {
    top: 15px !important;
  }
}
label {
//   font-size: 11px;
//    text-transform: uppercase;
//   font-weight: 300;
//   margin-bottom: 0;
//   margin-right: 5px;
//   color: #636E72;
}

.show {
  display: block !important;
}

.highlight {
  color: $brand-primary !important;
  font-weight: 500 !important;
}

.anchor {
  top: -$navigation-height-desktop - $s-t;
  position: relative;
  visibility: hidden;
  display: block;
}
.line-no-margin {
  margin-bottom: 0 !important;
}
.fa-chevron-down {
  color: #3F4142 !important;
  margin-top: 5px;
}
.custom-disabled {
  pointer-events: none !important;
  text-decoration: none !important;
  cursor: text !important;
  opacity: .6;
}
.cancel-event-container .container {
  padding: 0 !important;
  box-shadow: none !important;
}
  // Desktop specific styling
  @media (min-width: $tablet) {
  }
  
  // Mobile specific styling
  @media (max-width: $tablet) {
    .daterangepicker {
      display: none !important;
    }
    .order {
      display: grid !important;
    }
  }
  