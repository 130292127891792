// All buttons
.btn {
    transition: .2s all ease-in-out;
    min-width: 155px;
    font-size: 16px;
    border: 1px solid transparent;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding: 13px 20px;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    cursor: pointer;
    position: relative;
    &:hover,&:active,&:focus,&:focus-visible {
        outline: none !important;
        box-shadow: none !important;
        text-decoration: none !important;
        background: rgba(63, 65, 66, 0.05);
      }
    &:disabled {
        cursor: not-allowed !important;
        opacity: 0.5 !important;
    }
    // General styling
    &_primary {
        background-color: $brand-primary;
        box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.02), 0px 2px 12px rgba(0, 0, 0, 0.11);
        @include border (1px, $brand-primary, 5px);
    }

    &_secondary {
        border: 1px solid $font-general;
    }

    &_light {
        background: transparent;
        box-shadow: $box-shadow;
        @include border (1px, $font-general, 15px);
    }

    &_large {
        height: 50px;
    }

    &_clear {
        background: transparent;
        position: relative;

    }

    &_dark {
        background-color: $brand-tertiary;
        color: white;
        @include border (1px, $font-general, 15px);
    }

    &_success {
      @include orange-button;
      color: white !important;
    }

    &_failure {
        color: $brand-primary !important;
        background: white;
        border-color:#DCDFE0;
    }

    &_back {
        margin-left: 104px;
        margin-bottom: 13px;
        display: block;
    }

    &_inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;

        +label {
            font-size: 1.25em;
            font-weight: 700;
            color: white;
            background-color: black;
            display: inline-block;
            cursor: pointer;
            /* "hand" cursor */
        }

        &:focus+label,
        +label:hover {
            background-color: red;
        }
    }

    &_spinner {
        display: flex !important;
        justify-content: center;
    }

    &:hover,
    &:focus {
        box-shadow: 0 0 0 0.2rem $brand-primary;
    }

    .spinner-border {
        display: none !important;
    }
    .flashing-loader {
        display: none !important;
    }

    .btn_text {
        line-height: unset;
    }

    &.show {
        .spinner-border {
            display: inline-block !important;
            margin-bottom: 2px;
            margin-right: 5px !important;
        }
        .flashing-loader {
            display: inline-block !important;

        }

        .btn_text {
            display: none;
        }

        &.request-invite {
            .spinner-border {

            }
            .flashing-loader {

            }
        }
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.6;
        box-shadow: none;
        transition: .2s all ease-in-out;
    }

    span {
        font: unset;
    }

    // Desktop specific styling
    @media (min-width: $tablet) {}

    // Mobile specific styling
    @media (max-width: $tablet) {
        &_clear {
            background: transparent;
            position: relative;
    
            &::after {
                content: "";
                display: block;
                border-bottom: 1px solid $font-general;
                width: calc(100% - #{$s-s});
                margin: auto;
                position: absolute;
                bottom: 0;
            }
        }
    }
}