// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// Colour branding
$brand-primary: #3A3A3A;
$brand-secondary: #FCC389;
$brand-tertiary: #2d3036;
$brand-charcoal: hsla(195, 7%, 22%, 0.98);
$brand-light: #ECECEC;
$background-colour: white;
$font-general: #4A4A4A;
$font-icons: #4A4A4A;
$button_primary: $brand-primary;
$button_secondary: $brand-secondary;
$success: #B9E08F;
$failure: #FF8795;
$blue: #0984E3;
$mattsblue: #023A65;
$yellow: #F5A623;
$grey: #E7E7E7;
$light-grey:#FBFBFB;
$teal:#208C77;

// Typography
$font-family-primary: 'Inter';
$font-family-secondary: 'Inter';
$h1: normal 600 20px/1.5 $font-family-secondary;
$h2: normal 500 18px/1.5 $font-family-secondary;
$h3: normal 600 16px/1.5 $font-family-secondary;
$h4: normal 500 16px/1.5 $font-family-secondary;
$h5: normal 400 10px/1.5 $font-family-secondary;
$quote1: italic 500 20px/1.5 $font-family-primary;
$quote-p: italic 500 20px/1.5 $font-family-primary;
$p: normal 400 16px/1.5 $font-family-primary;
$p-bold: normal 700 16px/1.5 $font-family-primary;
$span: normal 400 16px/1.5 $font-family-primary;
$detail1: normal 400 14px/1.5 $font-family-primary;
$detail2: normal 400 14px/1.5 $font-family-primary;
$button-select: normal 400 16px/1.5 $font-family-primary;


// Device sizes
$mobile: 360px;
$desktop: 1366px;
$tablet: 768px;

// Grid Sizes
$content-grid: 640px;

// Navigation Sizes
$navigation-height-desktop: 70px;
$navigation-height-mobile: $navigation-height-desktop;
$footer-height: 60px;
$footer-cta-height: 50px;

// Global Elements
$box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
$box-shadow-reverse: 0 -2px 4px 0 rgba(0, 0, 0, 0.2);
$text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
$border-radius: 7px;


// Module Spacing
$s-t: 5px;
$s-s: $s-t * 3;
$s-m: $s-s * 2;
$s-l: $s-m * 2;

$border: 1px solid $brand-light;