.review-form {
    .btn_large {
        border-bottom: 1px solid #EEEEEE;
        min-width: 68px;
        margin-top: 32px;
        margin-bottom: 32px;

        &::before {
            content: url('/static/dist/img/back-button.svg');
            position: relative;
            right: 13px;
            top: 3px;
        }
    }

    .go-back-dashboard {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #3F4142;
    }

    .tab {
        display: none;
        
        .title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 28px;
            color: #3A3A3A;
        }

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 23px;
            color: #3A3A3A;
        }
    }

    .radio-review {
        list-style-type: none;
        padding-left: 0;
        margin-top: 24px;
        margin-bottom: 24px;

        input {
            width: 20px;

        }

        input {
            opacity: 0;
            position: absolute;
        }

        input label {
            display: inline-block;
            vertical-align: middle;
            margin: 5px;
            cursor: pointer;
        }

        label {
            position: relative;
        }

        input+label:before {
            content: '';
            background: #fff;
            border: 1px solid #DCDFE0;
            display: inline-block;
            vertical-align: middle;
            width: 20px;
            height: 20px;
            padding: 2px;
            margin-right: 10px;
            text-align: center;
        }

        input+label:before {
            border-radius: 50%;
        }

        input:checked+label:before {
            background-image: url("/static/dist/img/checkmark.svg");
            font-family: 'FontAwesome';
            color: #FFFFFF;
            background-color: #3A3A3A;
            background-repeat: no-repeat;
            background-position-x: 3px;
            background-position-y: 5px;
            background-size: 11px;
        }

        label {
            width: 124px;
            display: contents;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: #3A3A3A;
        }
    }

    .buttons {
        display: flex;
        justify-content: end;
        margin-top: 44px;
        .btn {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            padding: 12px 14.5px;
            height: unset;
            width: unset;
        }

        .btn-review-cancel {
            border: 1px solid #C4C4C4;
            color: #676767;
            // filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.02)) drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.11));
            border-radius: 5px;
            margin-right: 16px;
        }

        .btn-review-next {
            color: #FFFFFF;
            font-weight: 600;
            background: #F69C3A;
            opacity: 1;
            cursor: pointer;

            &.disable {
                opacity: 0.4;
                cursor: not-allowed;
            }
        }

    }
}

.ratings-wrapper {
    position: absolute;
    z-index: 3;
    top: 82px;
    right: 91px;

    @media (max-width: $tablet) {
        right: 0;
    }
}

.modal-review-rating {
    width: 368px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08), 0px 0px 10px rgba(0, 0, 0, 0.08);
    border-radius: 7px;
    padding: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    position: relative;

    .close-modal {
        position: absolute;
        right: 16px;
        font-weight: 400;
        font-size: 19px;
    }

    .modal-title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #3A3A3A;
        text-align: left;
        margin-bottom: 11px;
    }

    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #3F4142;
        text-align: left;
        margin-bottom: 11px;
    }

    .buttons {
        display: flex;
        justify-content: space-between;
    }

    .btn {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
        min-width: 130px;
        height: unset;
        width: unset;
    }

    .btn-close-modal {
        border: 1px solid #C4C4C4;
        color: #676767;
        // filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.02)) drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.11));
        border-radius: 5px;
    }

    .btn-go-to-review {
        color: #FFFFFF;
        background: #F69C3A;
        opacity: 1;
        cursor: pointer;

        &.disable {
            opacity: 0.4;
            cursor: not-allowed;
        }
    }

    @media (max-width: $tablet) {
        width: 90%;
        margin: 14px;
    }
}