.nav-fade {
// General styling
.header--navigation {
  background: transparent;
  box-shadow: none;
  &.fill {
      background: #fff;
      box-shadow: $box-shadow;

      .navbar {
        height: $navigation-height-desktop;
        padding: $s-t;
        &-buttons {
            .nav-item {
              .sign_up {
                background: #3F4142;
                border-radius: 40px;
                padding: 5px 17px;
                color: white !important;
              }
              .login {
                color: #3F4142 !important;
                border-color : #3F4142 !important;
              }
              .host-a-meal  {
                min-width: 155px !important;
                align-content: center;
              }
            }
          
        }
          &-collapse {

              .sign_in-container {
              }
              .nav-link {
                color: $brand-primary;
              }
          }
          &-brand {
            padding: 0;
            position: relative;
            z-index: 2;
            content: url('/static/dist/img/logo_grey.svg');
            img {
              width: 100px;
            }
          }
        &-toggler {
          position: absolute;
          right: 0;
          z-index: 5;
          position: relative;
          width: 20px;
          padding: 0;
          outline: none;
          border: 0;
          /* color: $brand-primary; */
          top: 0;
          &-line {
            width: 100%;
            height: 4px;
            background: $brand-primary;
            display: block;
            margin: 4px auto;
            transition: .2s all ease-in-out;
            border-radius: 2px;
          }

        }
        &-nav {
          margin-top: 0;
          &--login-btn {
            top: 18px;
            border: 1px solid $brand-primary;
            box-sizing: border-box;
            border-radius: 40px;
            color: $brand-primary;
            padding: 2px 16px;
          }
        }
      } 
      
    }
}
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}
.nav-white {
  .header--navigation {
  border-bottom: 1px solid #E7E7E7;
  }
  .header--navigation,.search {
  background-color: white;
  .navbar {
    height: $navigation-height-desktop;
    padding: $s-t;
    &-buttons {
        .nav-item {
          .sign_up {
            background: #3F4142;
            border-radius: 40px;
            padding: 5px 17px;
            color: white !important;
          }
          .login {
            @include transparent-button;
            border-radius: 40px !important;
            padding: 5px 17px;
            margin-right: 15px;
            min-width: 155px;
            position: relative;
            top: 1px;
            color: #3F4142 !important;
            border-color : #3F4142 !important;
            min-width: fit-content;
            text-align: center;

          }
          .host-a-meal  {
            min-width: 155px !important;
            align-content: center;
          }
        }
      
    }
      &-collapse {
        // text-align: center;
        // background: $brand-charcoal;
        // z-index: 1;
        // padding: 0 20px;
        // margin: 0 -20px;
        // top: -$navigation-height-desktop;
        // position: relative;
        // box-shadow: $box-shadow;
          .sign_in-container {
          }
          .nav-link {
          }
      }
      &-toggler {
        position: absolute;
        right: 0;
        z-index: 5;
        position: relative;
        width: 20px;
        padding: 0;
        outline: none;
        border: 0;
        /* color: $brand-primary; */
        top: 0;
        &-line {
          width: 100%;
          height: 4px;
          color: $brand-primary;
          background: $brand-primary;
          display: block;
          margin: 4px auto;
          transition: .2s all ease-in-out;
          border-radius: 2px;
        }

      }
      &-nav {
        &--login-btn {
          top: 18px;
          border: 1px solid $brand-primary;
          box-sizing: border-box;
          border-radius: 40px;
          color: $brand-primary;
          padding: 2px 16px;
        }
      }
    } 
    }
}
