.sign-up-banner {
    width: 683px;
    margin: auto;
    margin-bottom: 92px;

    &--background-image {
        background-image: url('/static/dist/img/sign-up-banner-img.png');
        min-height: 262px;
        zoom: 50%;
        background-repeat-x: no-repeat;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        &.signed {
            background-image: url('/static/dist/img/signed-in-banner.png');
            min-height: 667px;
        }
        
    }
    &--content {
        text-align: center;
        background-color: #333333;
        padding: 32px;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        &.signed {
            background-color: #3D281E;
        }
    }
    &--button {
        .btn {
            @include orange-button;
            width: 99px;
            margin-top: 24px;
        }
        &.signed {
            .btn {
                width: 327px;
            } 
        }
    }
    h1 {
        color: white;
    }
    p {
        color: white;
    }
      // Mobile specific styling
  @media (max-width: $tablet) {
    width: 100%;
    padding: 0 24px;
    margin-bottom: 62px;
    &.signed {
        padding: 0;
    }
    &--background-image {
        &.signed {
            border-radius: 0;
            background-image: url('/static/dist/img/signed-in-banner-mobile.png');
            background-size: cover;
        }
        
    }
    &--content {
        &.signed {
            border-radius: 0;
            text-align: left;
        }
    }
    &--button {
        .btn {
            @include orange-button;
            width: 100% !important;
            margin-top: 24px;
        }
    }
}
}