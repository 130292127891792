.terms-and-conditions {
// General styling
.btn_wrapper {
  position: sticky;
  bottom: 0;
  z-index: 6;
  display: inline-block;
  height: 0;
  .btn_light {
    bottom: 50px;
    background: white;
    position: relative;
    left: 30px;
  }
}
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}