.security-notice {
// General styling
background: #FAFAFA;
color: white;
width: 100%;
left: 0;
top: 3.3vh;
padding: 24px;
position: relative;
margin-top: 44px;


p {
  color: #919191;
} 
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}