
  .value-proposition {

    border-bottom: 2px solid $grey;
   
    .container {
      .card {
        border-radius: 15px;
        overflow: hidden;
        box-shadow: none;
    
        .card-img-top {
          height: 100%;
          object-fit: cover;
        }
    
        .card-img-overlay {
          background: rgba(0, 0, 0, 0.1);
          padding: 2rem;
          // text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8);
        }
    
        h5 {
          /* Title/32px | 42px */
          font-family: Inter;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 42px; /* 131.25% */
          text-align: left;
        }

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.6); // Dark semi-transparent overlay
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0; // Initially hidden
          transition: opacity 0.3s ease-in-out;
        }

        .overlay-text {
          text-align: center;
          color: white;
          padding: 20px;
    
          p {
            font-size: 1rem;
          }
        }
    
        &:hover {
          h5 {
            opacity: .5;
            transition: ease-in-out 0.3s;
          }
          .overlay,
          &.active .overlay { 
            opacity: 1; // Show overlay on hover
            cursor: pointer;
          }
        }
      }
    }
    

  // Desktop specific styling
  @media (min-width: $tablet) {

  }

  // Mobile specific styling
  @media (max-width: $tablet) {
    .container {
      .card {
        h5 {
          font-size: 30px;
        }
        .card-img-top {
          height:200px;
          object-position: 0 -265px;
          filter: brightness(0.9);
        }
        &:hover {
          h5 {
            opacity: 0;
          }
        }
      }
    }
    
  }
}

