.venue {
    border-radius: 8px;
    color: #3A3A3A;
    font-family: 'Inter';

    &.manage-connection{
        .modal-body{
            .search-box{
                background:  #FBFBFB;
                padding-top: 30px;
                padding-bottom: 30px;
                label{
                    color:  #3A3A3A;
                    font-size: 14px;
                    font-weight: 600;
                }
                #connected-search{
                    border-radius: 7px;
                    border: 2px solid #CBCBCC;
                    background:  #FFF;
                    padding: 7px 14px;
                }
            }
        }
    }

    .user-dashboard .accordion .event-card .user-invite-hub{
        .card-header{
            border-radius: 8px !important;
            border: 1px solid #EBEBEB;
            box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
            padding: 6px 18px;
            margin-bottom: 16px;
            a{
                font-size: 14px;
                font-weight: 500;
            }
        }
    }
    .add-venue input:checked+.slider {
        background-color: #1967D2;
    }
    .add-venue input:checked+.slider:before{
        background-image: url('/static/dist/img/checkmark-blue.svg');
    }
    .invite-btn{
        border-radius: 8px;
        border: 1px solid #1967D2;
        background: #E9F0FA;
        padding: 12px;
        height: 50px;
        margin-top: 15px;
        p{
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.056px;
            color: #1967D2;
        }
    }
    .wallet{
        border-radius: 8px;
        border: 1px solid #EBEBEB;
        background:  #FFF;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
        padding: 16px;
        max-width: 250px;
        margin-top: 60px;
        .name{
            font-family: 'Inter';
            font-size: 11px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.077px;
            text-transform: uppercase;
            padding-right: 12px;
            border-right: 1px solid #EBEBEB;
            width: 60%;
        }
        .add-funds{
            width: 40%;
            span{
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 18px; /* 128.571% */
                letter-spacing: 0.056px;
            }
            .add-fund-btn{
                border-radius: 4px;
                background: #F69C3A;
                font-size: 12px;
                font-weight: 600;
                color: #FFF;
                padding: 5px 11px;
            }
        }
    }
    .vendor-title{
        font-weight: 500;
        font-size: 18px;
    }
    .vendor-card{
        border-radius: 8px;
        border: 1px solid  #EBEBEB;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
        padding: 16px;
        img{
            width: 42px;
            height: 38px;
        }
        .heading, .vendor-name{
            color:  #717171;
            letter-spacing: 0.077px;
            font-family: 'Inter';
            font-size: 11px;
        }
        .heading{
            font-weight: 600;
            line-height: 16px;
            text-transform: uppercase;
        }
        .vendor-name{
            font-weight: 400;
            line-height: 14px;         
        }
        .affiliated{
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            padding-right: 8px;
            float: right;
            position: relative;
            top: -5px;
            &::after{
            content: url('/static/dist/img/award-alt.svg');
            position: relative;
            top: 5px;
            }
        }
    }
    .btn-view-hub{
        border-radius: 5px;
        background: #F69C3A;
        box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.02), 0px 2px 12px 0px rgba(0, 0, 0, 0.11);
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        padding: 20px 0;
        color: #FFF;
        float: right;
    }
    #quick-access{
        width: 100%;
        padding: 8px 0px 8px 8px;
        background-color: #E9F0FA;
        border-radius: 32px;
        font-size: 14px;
        font-weight: 600;
        color: #3A3A3A;
        cursor: pointer;
        .plus-sign{
            background-image: url("/static/dist/img/plus-sign.svg");
            width: 30px;
            height: 30px;
            display: inline-block;
            background-position: center;
            background-size: cover;
            margin-right: 20px;
        }
    }
    .caterer-modal {
        .modal-body {
            padding: 30px;

            .event-title {
                font-size: 18px;
                font-weight: 400;
                margin-bottom: 15px;
            }

            .caterer-payout{
                margin-top: 30px;
                label{
                    font-family: 'Inter';
                    font-size: 11px;
                    font-weight: 600;
 
                }
                .line{
                    background: rgba(203, 203, 204, 1);
                    height: 1px; 
                    margin: 3px 0;
                }
                p{
                    margin-left: 0;
                }
                p,i,span{
                    font-size: 14px;
                    font-weight: 600;

                }
                i{
                    font-weight: 400;
                }
                .amount{
                    font-weight: 400;
                    float: right;
                }
                input{
                    width: 34px;
                    border: 1px solid #d3d3d3;
                    padding: 4px 8px 4px 4px;
                    max-height: 23px;
                    margin-left: 8px;
                    border-radius: 4px;
                }
                .editable{
                    font-family: 'Inter';
                    font-size: 11px;
                    font-weight: 400;
                }
            }

            .event--details--info {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #3A3A3A;
                border: 1px solid #ECECEC;
                border-radius: 7px;
                padding: 12px;
                margin-top: 20px;

                &.time {
                    display: flex;

                    &::before {
                        content: url('/static/dist/img/clock.svg');
                        margin-right: 10px;
                        position: relative;
                        top: 2px;
                    }
                }
                &.custom-request{
                    border: 1px solid #EBEBEB;
                    background: #E9F0FA;
                    margin-top: 0;
                    p{
                        font-size: 14px;
                        font-weight: 400;
                        }
                }
                &.allergies {
                    .tag{
                        border-radius: 4px;
                        border: 1px solid #EBEBEB;
                        background: #F7F7F7;
                        padding: 8px;
                        color: #1967D2;
                        font-family: 'Inter';
                        font-size: 11px;
                        font-weight: 400;
                        line-height: 14px;
                        letter-spacing: 0.077px;
                        margin-right: 5px;
                        width: fit-content;
                        display: inline-block;  
                    }
                }
                &.guest-no{
                    display: flex;
                    &::before{
                        content: url('/static/dist/img/users-alt.svg');
                        margin-right: 10px;
                        position: relative;
                        top: 2px;
                    }
                }
            }
            .event--cta{
                position: relative;
                padding: 40px 0;
                .whatsapp{
                    background: rgba(96, 214, 106, 0.1);
                    width: 300px;
                    margin: auto;
                    &::before{
                        content: url('/static/dist/img/whatsappg.svg');
                    }
                }
                .approved-request.btn {
                    background: #F69C3A;
                    color: #FFF;
                }
                .reschedule-btn.btn {
                    border: 1px solid #3A3A3A;
                    font-weight: 500;
                    color: #3A3A3A;
                }
                .decline-request.btn {
                    color: #D93025;
                    font-weight: 500;
                    border: none;
                    box-shadow: none;
                    //position: absolute;
                    text-align: center;
                    // left: calc((100% - 200px) / 2);
                    // top: 157px;
                }
                .btn{
                    box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.11), 1px 1px 6px 0px rgba(0,0,0,0.02);
                    padding: 15px 0px;
                    border-radius: 5px;
                    display: block;
                    margin: auto;
                    max-width: 300px;
                    height: 47px;
                    margin-bottom: 16px;
                }
            }
        }
    }

    .choose-pay {
        .heading {
            border-radius: 4px;
            background: #E9F0FA;
            margin: -12px;
            padding: 10px;
            margin-bottom: 10px;

            .title {
                color: #3A3A3A;
                font-family: 'Inter';
                font-size: 11px;
                font-weight: 600;
                text-transform: uppercase;
            }

            .move-right {
                color: #1967D2;
                font-family: 'Inter';
                font-size: 11px;
                font-weight: 400;
                float: right;
            }
        }

        .discription-text,
        .discription-value {
            color: #3A3A3A;
            font-size: 14px;
            font-weight: 400;
        }

        .discription-value {
            float: right;
        }

        p {
            color: #A1A1A1;
            font-size: 12px;
            font-weight: 400;
        }

        label {
            color: #3A3A3A;
            font-size: 14px;
            font-weight: 600;
            width: 100%;
            position: relative;

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &:checked~.checkmark {
                    background-color: #3A3A3A;

                    &:after {
                        display: block;
                    }
                }
            }

            .checkmark {
                position: absolute;
                top: 0;
                right: 0;
                height: 25px;
                width: 25px;
                background-color: #eee;
                border-radius: 50%;

                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                    top: 9px;
                    left: 9px;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: white;
                }
            }

            &:hover input~.checkmark {
                background-color: #ccc;
            }
        }
    }

    .user-dashboard {
        .accordion {
            .card-body {
                .cancel-venue-btn {
                    color: #D93025;
                    padding: 10px 7px;
                    font-size: 14px;
                    font-weight: 400;
                    width: 100% !important;
                }

                .btn-orange {
                    background: #F69C3A !important;
                    height: unset !important;
                    padding: 15px 10px;
                    width: 100% !important;
                }

                .btn.arrow-right {
                    position: absolute;
                    right: 0;
                    top: -7px;
                }
            }

            .card--pending-invite {
                width: 50%;
            }
        }
    }

    .modal-header {
        border-bottom: 1px solid#EBEBEB !important;

        // &::before {
        //     content: url('/static/dist/img/back-image.svg');
        //     position: relative;
        //     top: 7px;
        // }

        .modal-title {
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            width: 100%;

            &.venue-booking-title {
                font-size: 14px;
                font-weight: 600;
            }
        }
    }

    &-card-empty {
        border-radius: 8px;
        border: 1px solid #EBEBEB;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
        padding: 15px;
        max-width: 140px;
        height: 200px;
        img {
            margin-bottom: 5px;
            width: 112px;
            height: 112px;
            // border-radius: 8px;
            border: 1px;
            background-position: 100% !important;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            max-height: unset !important;
        }

        p {
            font-size: 12px;
            font-weight: 400;
            color: #3A3A3A;
            margin-bottom: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .warning,
    .info {
        padding: 16px;
        padding-left: 30px;
        border-radius: 5px;
        border: 1px solid #EBEBEB;
        background: #FEFAEC;
        position: relative;

        p {
            color: #3A3A3A;
            font-family: 'Inter';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            /* 133.333% */
            letter-spacing: 0.072px;
        }

        &::before {
            content: url('/static/dist/img/warning.svg');
            position: absolute;
            top: 19px;
            left: 15px;
        }
    }

    .info {
        border-radius: 5px;
        border: 1px solid#1967D2;
        background: #E9F0FA;

        &::before {
            content: url('/static/dist/img/feedback.svg');
            top: 14px;
        }
    }

    .modal-hero {
        width: 100%;
        height: 205px;
        background-position: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 40px;

        .profile-image {
            width: 112px;
            height: 112px;
            border-radius: 8px;
            border: 1px;
            background-position: 100% !important;
            background-repeat: no-repeat !important;
            background-size: cover !important;
        }

        .user-details {
            position: relative;
            text-align: end;

            .dollar {
                padding: 3px;
                color: #FFF;
                font-family: 'Inter';
                font-size: 13px;
                font-style: normal;
                font-weight: 600;
                line-height: 17px;
                /* 130.769% */
                letter-spacing: 1.95px;
                border-radius: 4px;
                background: #8DD728;
                width: fit-content;
                position: relative;
                left: 76px;
            }

            .verified-text {
                font-family: 'Inter';
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.006em;
                text-align: right;
                color: #FFF;
            }
        }
    }

    .venue-title {
        font-family: 'Inter';
        font-size: 22px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0em;

        .venue-tag {
            border-radius: 13px;
            border: 1px solid #EBEBEB;
            background: #E9F0FA;
            color: #1967D2;
            padding: 3px 13px;
            position: relative;
            top: -6px;
            margin-left: 9px;
            font-family: 'Inter';
            font-size: 11px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            /* 145.455% */
            letter-spacing: 0.077px;
            text-transform: uppercase;

        }
    }

    .venue-address {
        color: #A1A1A1;
        font-family: 'Inter';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 128.571% */
        letter-spacing: 0.056px;
        margin: 10px 0;
    }

    .rating-stars {
        margin-bottom: 15px;
    }

    .venue-website {
        color: #1967D2;
        font-family: 'Inter';
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.072px;
        padding-top: 30px;
        position: relative;
        top: -5px;

        &::before {
            content: url('/static/dist/img/external-link-alt.svg');
            position: relative;
            top: 7px;
        }
    }

    .social-media-icons {
        margin: 20px 0;

        a {
            width: 40px;
            height: 40px;
            background-color: #EAECEC;
            border-radius: 50%;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .fa {
                font-size: 17px;
                position: relative;
                left: -3px;
            }
        }
    }

    .venue-prefarence-tags {
        margin: 30px 0;

        .tag {
            padding: 8px;
            border-radius: 4px;
            border: 1px solid #EBEBEB;
            background: #F7F7F7;
            color: #3A3A3A;
            font-family: 'Inter';
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            /* 127.273% */
            letter-spacing: 0.077px;
            margin-right: 3px;
        }
    }

    .venue-description {
        margin: 20px 0;

        p {
            color: #3A3A3A;
            font-family: 'Inter';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 150% */
            letter-spacing: 0.048px;
        }
    }

    .venue-cost-details {
        border-radius: 5px;
        border: 1px solid #EBEBEB;
        background: #FFF;
        box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.12);
        margin-bottom: 60px;
        padding: 20px;
        padding-left: 40px;
        position: relative;

        &::before {
            content: url('/static/dist/img/feedback.svg');
            position: absolute;
            left: 23px;
            top: 19px;
        }

        p {
            color: #3A3A3A;
            font-family: 'Inter';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            /* 128.571% */
            letter-spacing: 0.056px;
        }

        .venue-hire-total {
            border-bottom: 1px solid #3A3A3A;
            padding-bottom: 6px;

            span {
                font-weight: 600;
                float: right;
            }
        }

        .venue-hire-deposit {
            padding-top: 6px;

            span {
                font-weight: 600;
                float: right;
            }

        }

        .notice {
            border-radius: 5px;
            border: 1px solid #EBEBEB;
            background: #E9F0FA;
            padding: 16px;
            margin-top: 20px;
            margin-left: -18px;
            p {
                color: #3A3A3A;
                font-family: 'Inter';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.072px;
            }
        }
    }

    .line {
        background: #ECECEC;
        height: 1px;
        margin: 20px 0;
    }

    .warning,
    .info {
        padding: 16px;
        padding-left: 30px;
        border-radius: 5px;
        border: 1px solid #EBEBEB;
        background: #FEFAEC;
        position: relative;

        p {
            color: #3A3A3A;
            font-family: 'Inter';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            /* 133.333% */
            letter-spacing: 0.072px;
        }

        &::before {
            content: url('/static/dist/img/warning.svg');
            position: absolute;
            top: 19px;
            left: 15px;
        }
    }

    .info {
        border-radius: 5px;
        border: 1px solid#1967D2;
        background: #E9F0FA;

        &::before {
            content: url('/static/dist/img/feedback.svg');
            top: 14px;
        }
    }

    .venue-invoice-snippet{
        .info-green{
            background:  #F5FBEB;
            border-color: #EBEBEB;
            &::before {
                content: url('/static/dist/img/alert-success.svg');
                left: 9px;
            }
        }
    }

    .venue-photo-gallery {
        margin: 20px 0;

        label {
            color: #3A3A3A;
            font-family: 'Inter';
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            /* 155.556% */
            margin-bottom: 5px;
        }

        .image {
            width: 100px;
            height: 100px;
            background-position: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            margin: 10px;
            border-radius: var(--spacing-1, 4px);
            border: 1px solid #EBEBEB;
            display: inline-block;
        }

        .btn-gallery {
            width: 100%;
            max-width: 100%;
            border-radius: 5px;
            border: 1px solid #3A3A3A;
            box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.11), 1px 1px 6px 0px rgba(0, 0, 0, 0.02);
        }
    }

    .venue-accordion {
        padding: 0 !important;
        margin-bottom: 10px;

        label {
            color: #A1A1A1;
            font-family: 'Inter';
            font-size: 11px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            /* 145.455% */
            letter-spacing: 0.077px;
            text-transform: uppercase;
        }

        .accordion {
            width: 100%;

            .accrodion-custom-card {
                .card-header {
                    border-radius: 8px;
                    border: 1px solid #ECECEC;
                    margin-bottom: unset;

                    a {
                        color: #3A3A3A;
                        font-family: 'Inter';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 18px;
                        /* 128.571% */
                        letter-spacing: 0.056px;
                    }
                }

                .content {
                    .card-body {
                        border-radius: 0px 0px 8px 8px;
                        border-right: 1px solid #EBEBEB;
                        border-bottom: 1px solid #EBEBEB;
                        border-left: 1px solid #EBEBEB;

                        .event-card {
                            .card {
                                text-align: left;

                                &.caterer-list {
                                    p {
                                        font-size: 13px;
                                        color: #717171;
                                        font-weight: 400;
                                        margin-left: 5px;
                                    }

                                    ul {
                                        list-style: none;

                                        li {
                                            margin-left: 35px;
                                            color: #222;
                                            font-size: 14px;
                                            font-weight: 400;
                                        }
                                    }
                                }

                                label {
                                    color: #717171;
                                    font-family: 'Inter';
                                    font-size: 13px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 17px;
                                    text-transform: capitalize;
                                    letter-spacing: 0.052px;
                                }

                                .line {
                                    height: 1px;
                                    background-color: #EBEBEB;
                                    margin: 20px 0;
                                }

                                p {
                                    color: #3A3A3A;
                                    font-family: 'Inter';
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 18px;
                                    /* 128.571% */
                                    letter-spacing: 0.056px;
                                    margin: 6px 0;

                                    .icon {
                                        margin-right: 5px;
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
    }

    .btn-request {
        color: #FFF;
        font-family: 'Inter';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 150% */
        letter-spacing: 0.048px;
        margin: 60px 0;
        border-radius: 5px;
        background: #F69C3A;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.11), 1px 1px 6px 0px rgba(0, 0, 0, 0.02);
    }

    .seat-number {
        margin-bottom: 10px;

        p {
            color: #3A3A3A;
            font-family: 'Inter';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 150% */
            letter-spacing: 0.048px;

            .edit {
                font-family: 'Inter';
                font-size: 11px;
                font-weight: 600;
                line-height: 16px;
                /* 145.455% */
                letter-spacing: 0.077px;
                text-decoration-line: underline;
                text-transform: uppercase;
                float: right;
            }

            &.number {
                color: #A1A1A1;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                /* 128.571% */
                letter-spacing: 0.056px;
                border-bottom: 1px solid #CBCBCC;
            }
        }
    }

    .calculator {
        border-radius: 8px;
        border: 1px solid #EBEBEB;
        background: #FFF !important;
        margin: 60px 0;

        .calculator-header {
            border-radius: 8px 8px 0 0px;
            padding: 12px;
            background: #1967D2;
            position: relative;
            padding-left: 18px;

            &::before {
                content: url('/static/dist/img/calculator.svg');
                position: absolute;
                left: 13px;
                top: 12px;
            }

            span {
                color: #E9F0FA;
                font-family: 'Inter';
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                /* 150% */
                letter-spacing: 0.048px;
                margin-left: 23px;
            }
        }

        .calculator-body {
            padding: 8px;

            .guest-section {
                border-radius: 8px;
                background: rgba(25, 103, 210, 0.10);
                padding: 15px 10px;
                margin: 5px;

                span {
                    font-size: 14px !important;
                    font-weight: 400;
                }

                .counting {
                    float: right;
                    position: relative;
                    top: 7px;

                    .guest_number {
                        margin: 0 5px;
                        position: relative;
                        bottom: 6px;
                    }

                    .minus,
                    .plus {
                        border-radius: 4px;
                        border: 1px solid #1967D2;
                        background-color: #FFF;
                        padding: 0 10px;
                        color: #3A3A3A;
                        text-align: center;
                        font-size: 20px;
                        font-weight: 400;
                        position: relative;
                        bottom: 5px;
                        cursor: pointer;
                    }
                }
            }

            .net-income {
                border-radius: 4px;
                background: #1967D2;
                padding: 8px;
                color: #FFF !important;
                font-weight: 600;
                font-size: 14px;
                margin: 5px 0;
            }

            .seat-income {
                color: #3A3A3A;
                font-size: 14px;
                font-weight: 400;

                i {
                    font-size: 12px;
                    font-style: italic;
                    font-weight: 400;
                }

                #breakdown-cost {
                    font-size: 12px;
                    font-style: italic;
                    font-weight: 400;
                }
            }

            .seat-editing {
                display: flex;

                label {
                    color: #A1A1A1;
                    font-family: 'Inter';
                    font-size: 11px;
                    font-weight: 400;
                }

                #seat-income-input {
                    border-radius: 4px !important;
                    border: none !important;
                    background: #FFF;
                    font-weight: 600;
                    position: relative;
                    text-align: right;
                    &::before{
                        content: "R"
                    }
                }
            }

            .disclamier {
                color: #A1A1A1 !important;
                font-family: 'Inter' !important;
                font-size: 11px !important;
                font-weight: 400;
                padding: 0 15px;
            }

            .heading {
                color: #1967D2;
                font-family: 'Inter';
                font-size: 11px;
                font-weight: 600;
                text-transform: uppercase;
            }

            p {
                color: #3A3A3A;
                font-family: 'Inter';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                /* 128.571% */
                letter-spacing: 0.056px;
                display: flow-root;

                span {
                    float: right;
                    font-weight: 600;
                }

                &.net-income {
                    font-weight: 600;
                    margin-bottom: 20px;

                    &.red {
                        color: red !important;
                    }
                }
            }

            .line {
                height: 1px;
                background: #EBEBEB !important;
                margin: 10px 0;
            }

        }

        .line {
            height: 1px;
            background: #CBCBCC;
            margin: 40px 0;

        }
    }

    .payout {
        margin: 30px 0;

        .payout-heading {
            color: #3A3A3A;
            font-family: 'Inter';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 150% */
            letter-spacing: 0.048px;
            margin-bottom: 20px;
        }

        .radio {
            color: #3A3A3A;
            font-family: 'Inter';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            /* 128.571% */
            letter-spacing: 0.056px;
            margin-bottom: 5px;
            display: inline-block;
            width: 100%;
            position: relative;
            padding-left: 35px;

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &:checked~.checkmark {
                    background-color: #3A3A3A;

                    &:after {
                        display: block;
                    }
                }
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 25px;
                width: 25px;
                background-color: #eee;
                border-radius: 50%;

                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                    top: 9px;
                    left: 9px;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: white;
                }
            }

            // &:hover input~.checkmark {
            //     background-color: #ccc;
            // }
        }

        .warning {
            &::before {
                top: 13px;
                left: 14px;
            }
        }
    }

    .btn-next {
        border-radius: 7px;
        background: #F69C3A;
        color: #FFF;
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 2px 5px 0px rgba(60, 66, 87, 0.08);
    }

    .modal-body {
        padding: 0;
        color: #3A3A3A;

        .modal-hero {
            width: 100%;
            height: 205px;
            background-position: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            margin-bottom: 40px;

            .profile-image {
                width: 112px;
                height: 112px;
                border-radius: 8px;
                border: 1px;
                background-position: 100%;
                background-repeat: no-repeat;
                background-size: cover;
            }

            .user-details {
                position: relative;
                text-align: end;

                .dollar {
                    padding: 3px;
                    color: #FFF;
                    font-family: 'Inter';
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 17px;
                    /* 130.769% */
                    letter-spacing: 1.95px;
                    border-radius: 4px;
                    background: #8DD728;
                    width: fit-content;
                    position: relative;
                    left: 76px;
                }

                .verified-text {
                    font-family: 'Inter';
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0.006em;
                    text-align: right;
                    color: #FFF;
                }
            }
        }

        .venue-title {
            font-family: 'Inter';
            font-size: 22px;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: 0em;

            .venue-tag {
                border-radius: 13px;
                border: 1px solid #EBEBEB;
                background: #E9F0FA;
                padding: 3px 13px;
                position: relative;
                top: -6px;
                margin-left: 9px;
                font-family: 'Inter';
                font-size: 11px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                /* 145.455% */
                letter-spacing: 0.077px;
                text-transform: uppercase;

            }
        }

        .venue-address {
            color: #A1A1A1;
            font-family: 'Inter';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            /* 128.571% */
            letter-spacing: 0.056px;
            margin: 10px 0;
        }

        .rating-stars {
            margin-bottom: 15px;
        }

        .line {
            background: #ECECEC;
            height: 1px;
            margin: 20px 0;
        }

        .venue-website {
            color: #1967D2;
            font-family: 'Inter';
            font-size: 12px;
            font-style: italic;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.072px;
            padding-top: 30px;
            position: relative;
            top: -5px;

            &::before {
                content: url('/static/dist/img/external-link-alt.svg');
                position: relative;
                top: 7px;
            }
        }

        .social-media-icons {
            margin: 20px 0;

            a {
                width: 40px;
                height: 40px;
                background-color: #EAECEC;
                border-radius: 50%;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                position: relative;

                .fa {
                    font-size: 17px;
                    position: relative;
                    left: -3px;
                }
            }
        }

        .venue-prefarence-tags {
            margin: 30px 0;

            .tag {
                padding: 8px;
                border-radius: 4px;
                border: 1px solid #EBEBEB;
                background: #F7F7F7;
                color: #3A3A3A;
                font-family: 'Inter';
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
                /* 127.273% */
                letter-spacing: 0.077px;
                margin-right: 3px;
            }
        }

        .venue-description {
            margin: 20px 0;

            p {
                color: #3A3A3A;
                font-family: 'Inter';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                /* 150% */
                letter-spacing: 0.048px;
            }
        }

        .venue-cost-details {
            border-radius: 5px;
            border: 1px solid #EBEBEB;
            background: #FFF;
            box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.12);
            margin-bottom: 60px;
            padding: 20px;
            padding-left: 40px;
            position: relative;

            &::before {
                content: url('/static/dist/img/feedback.svg');
                position: absolute;
                left: 23px; 
                top: 19px;
            }

            p {
                color: #3A3A3A;
                font-family: 'Inter';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                /* 128.571% */
                letter-spacing: 0.056px;
            }

            .venue-hire-total {
                border-bottom: 1px solid #3A3A3A;
                padding-bottom: 6px;

                span {
                    font-weight: 600;
                    float: right;
                }
            }

            .venue-hire-deposit {
                padding-top: 6px;

                span {
                    font-weight: 600;
                    float: right;
                }

            }

            .notice {
                border-radius: 5px;
                border: 1px solid #EBEBEB;
                background: #E9F0FA;
                padding: 16px;
                margin-top: 20px;

                p {
                    color: #3A3A3A;
                    font-family: 'Inter';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0.072px;
                }
            }
        }

        .warning,
        .info {
            padding: 16px;
            padding-left: 30px;
            border-radius: 5px;
            border: 1px solid #EBEBEB;
            background: #FEFAEC;
            position: relative;

            p {
                color: #3A3A3A;
                font-family: 'Inter';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                /* 133.333% */
                letter-spacing: 0.072px;
            }

            &::before {
                content: url('/static/dist/img/warning.svg');
                position: absolute;
                top: 19px;
                left: 15px;
            }
        }

        .info {
            border-radius: 5px;
            border: 1px solid#1967D2;
            background: #E9F0FA;

            &::before {
                content: url('/static/dist/img/feedback.svg');
                top: 14px;
            }
        }

        .venue-photo-gallery {
            margin: 20px 0;

            label {
                color: #3A3A3A;
                font-family: 'Inter';
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
                /* 155.556% */
                margin-bottom: 5px;
            }

            .image {
                width: 100px;
                height: 100px;
                background-position: 100%;
                background-repeat: no-repeat;
                background-size: cover;
                margin: 10px;
                border-radius: var(--spacing-1, 4px);
                border: 1px solid #EBEBEB;
                display: inline-block;
            }

            .btn-gallery {
                width: 100%;
                max-width: 100%;
                border-radius: 5px;
                border: 1px solid #3A3A3A;
                box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.11), 1px 1px 6px 0px rgba(0, 0, 0, 0.02);
            }
        }

        .venue-accordion {
            padding: 0 !important;
            margin-bottom: 10px;

            label {
                color: #A1A1A1;
                font-family: 'Inter';
                font-size: 11px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                /* 145.455% */
                letter-spacing: 0.077px;
                text-transform: uppercase;
            }

            .accordion {
                width: 100%;

                .accrodion-custom-card {
                    .card-header {
                        border-radius: 8px;
                        border: 1px solid #ECECEC;
                        margin-bottom: unset;

                        a {
                            color: #3A3A3A;
                            font-family: 'Inter';
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 18px;
                            /* 128.571% */
                            letter-spacing: 0.056px;
                        }
                    }

                    .content {
                        .card-body {
                            border-radius: 0px 0px 8px 8px;
                            border-right: 1px solid #EBEBEB;
                            border-bottom: 1px solid #EBEBEB;
                            border-left: 1px solid #EBEBEB;

                            .event-card {
                                .card {
                                    text-align: left;

                                    label {
                                        color: #717171;
                                        font-family: 'Inter';
                                        font-size: 13px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 17px;
                                        /* 130.769% */
                                        letter-spacing: 0.052px;
                                    }

                                    .line {
                                        height: 1px;
                                        background-color: #EBEBEB;
                                        margin: 20px 0;
                                    }

                                    p {
                                        color: #3A3A3A;
                                        font-family: 'Inter';
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 18px;
                                        /* 128.571% */
                                        letter-spacing: 0.056px;
                                        margin: 6px 0;

                                        .icon {
                                            margin-right: 5px;
                                        }
                                    }

                                }
                            }
                        }
                    }
                }
            }
        }

        .btn-request {
            color: #FFF;
            font-family: 'Inter';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 150% */
            letter-spacing: 0.048px;
            margin: 60px 0;
            border-radius: 5px;
            background: #F69C3A;
            box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.11), 1px 1px 6px 0px rgba(0, 0, 0, 0.02);
        }

        .seat-number {
            margin-bottom: 10px;

            p {
                color: #3A3A3A;
                font-family: 'Inter';
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                /* 150% */
                letter-spacing: 0.048px;

                .edit {
                    font-family: 'Inter';
                    font-size: 11px;
                    font-weight: 600;
                    line-height: 16px;
                    /* 145.455% */
                    letter-spacing: 0.077px;
                    text-decoration-line: underline;
                    text-transform: uppercase;
                    float: right;
                }

                &.number {
                    color: #A1A1A1;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    /* 128.571% */
                    letter-spacing: 0.056px;
                    border-bottom: 1px solid #CBCBCC;
                }
            }
        }

        .calculator {
            border-radius: 8px;
            border: 1px solid #EBEBEB;
            background: #E9F0FA;
            margin: 15px 0;

            .calculator-header {
                border-radius: 8px 8px 0 0px;
                padding: 12px;
                background: #1967D2;
                position: relative;
                padding-left: 18px;

                &::before {
                    content: url('/static/dist/img/calculator.svg');
                    position: absolute;
                    left: 13px;
                    top: 12px;
                }

                span {
                    color: #E9F0FA;
                    font-family: 'Inter';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    /* 150% */
                    letter-spacing: 0.048px;
                    margin-left: 23px;
                }
            }

            .calculator-body {
                padding: 19px;

                p {
                    color: #3A3A3A;
                    font-family: 'Inter';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    /* 128.571% */
                    letter-spacing: 0.056px;
                    display: flow-root;

                    span {
                        float: right;
                        font-weight: 600;
                    }

                    &.net-income {
                        font-weight: 600;
                        margin-bottom: 20px;

                        &.red {
                            color: red !important;
                        }
                    }
                }

                .line {
                    height: 1px;
                    background: #1967D2;
                    margin: 10px 0;
                }

            }

            .line {
                height: 1px;
                background: #CBCBCC;
                margin: 40px 0;

            }
        }

        .payout {
            margin: 30px 0;

            .payout-heading {
                color: #3A3A3A;
                font-family: 'Inter';
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                /* 150% */
                letter-spacing: 0.048px;
                margin-bottom: 20px;
            }

            .radio {
                color: #3A3A3A;
                font-family: 'Inter';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                /* 128.571% */
                letter-spacing: 0.056px;
                margin-bottom: 5px;
                display: inline-block;
                width: 100%;
                position: relative;
                padding-left: 35px;

                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;

                    &:checked~.checkmark {
                        background-color: #3A3A3A;

                        &:after {
                            display: block;
                        }
                    }
                }

                .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 25px;
                    width: 25px;
                    background-color: #eee;
                    border-radius: 50%;

                    &:after {
                        content: "";
                        position: absolute;
                        display: none;
                        top: 9px;
                        left: 9px;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: white;
                    }
                }

                &:hover input~.checkmark {
                    background-color: #ccc;
                }
            }

            .warning {
                &::before {
                    top: 13px;
                    left: 14px;
                }
            }
        }

        .btn-next {
            border-radius: 7px;
            background: #F69C3A;
            color: #FFF;
            box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 2px 5px 0px rgba(60, 66, 87, 0.08);
        }
    }

    .venue-invoice-snippet {
        &.font-inter{
            
            h3, span, p{
                font-family: "Inter" !important;
            }
        }
        .event-display {
            border-radius: 8px;
            border-top: 1px solid #EBEBEB;
        }

        .disclaimer {
            color: #A1A1A1;
            font-size: 12px;
            font-style: italic;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.072px;
            margin: 30px 0;

            strong {
                font-style: normal;
                text-decoration: underline;
            }
        }

        .btn-orange {
            border-radius: 5px;
            background: #F69C3A;
            box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.11), 1px 1px 6px 0px rgba(0, 0, 0, 0.02);
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 150% */
            letter-spacing: 0.048px;
            padding: 23px 43px;
            margin: 10px 0;
        }

        .cancel-btn {
            margin: 20px 0;
            color: #D93025;
        }

        .add-replace-card {
            border-radius: 7px;
            border: 1px solid #C3C3C3;
            padding: 5px 10px;
            min-width: unset;
        }

        .price--totalCost {
            color: #3A3A3A;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 150% */
            letter-spacing: 0.048px;
        }

        .price.price--total {
            font-weight: 500;
            font-size: 16px;
        }

        .host-payment {
            color: #3A3A3A;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            /* 128.571% */
            letter-spacing: 0.056px;

            &.paylater-label {
                color: #A1A1A1;
            }

            &.price {
                font-weight: 600;
            }
        }

        .host-name {
            a {
                color: #3A3A3A;
                font-size: 14px;
                font-weight: 600;
                line-height: 18px;
                /* 128.571% */
                letter-spacing: 0.056px;
            }
        }

        .venue-profile-image {
            border-radius: 4px;
            width: 50px;
            height: 50px;
            border: 1px solid #EBEBEB;
        }

        .business-name {

            h3,
            p {
                font-family: 'Inter';
                font-size: 11px;
                font-style: normal;
                letter-spacing: 0.077px;

            }

            h3 {
                line-height: 16px;
                /* 145.455% */
                font-weight: 600;
                text-transform: uppercase;
            }

            p {
                font-weight: 400;
                line-height: 14px;
            }
        }

        .event-name {
            color: #3A3A3A;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            /* 127.273% */
        }

        .free-text {
            color: #3A3A3A;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            /* 128.571% */
            letter-spacing: 0.056px;
        }

        .date,
        .address {
            color: #717171;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            /* 128.571% */
            letter-spacing: 0.056px;
        }
    }

    &-dashboard {
        .user-dashboard-venue{
            padding: 0 !important;
                .accordion{
                    width:100%
                }
        }
        .information-label.information-label-title {
            border-bottom: 1px solid #ECECEC;
            margin-bottom: 40px;
        }

        .description {
            margin-bottom: 10px;
        }

        .user-profile.mb-0 {
            margin-bottom: 0 !important;
        }

        .verified-text {
            display: block;
            width: fit-content;
            float: right;
            margin-top: -74px;

            p {
                color: #676767;
                text-align: right;
                font-family: 'Inter';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                /* 150% */
            }
        }

        .line {
            background: #EBEBEB;
            height: 1px;
        }

        .nav-tabs {
            border: none;
            position: relative;
            .nav-item {
                .nav-link {
                    color: #3A3A3A;
                    font-family: 'Inter';
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    /* 145.455% */
                    letter-spacing: 0.077px;
                    text-transform: uppercase;

                    &.active {
                        border: none;
                        border-bottom: 3px solid #8DD728;
                        // padding: 0.5rem 3px;
                        // padding-left: 0;
                        
                        font-weight: 600;
                    }
                }
            }
            .add-vendors{
                width: 260px;
                label{
                    width: 100%;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 600;
                    color: #3A3A3A;
                    border-radius: 7px;
                    border: 1px solid #5E5E5E;
                    padding: 8px;
                    cursor: pointer;
                    position: relative;
                    &:after{
                        content:url("/static/dist/img/selector chevron down.svg");
                        position: absolute;
                        right: 10px;
                    }
                }
                .add-vendors-box{
                    padding: 20px;
                    border-radius: 7px;
                    border: 1px solid #F1F3F4;
                    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
                    z-index: 1;
                    position: relative;
                    background: #FFF;
                    display:none;
                    .vendor-items{
                        margin: 15px 0;
                        a{
                            color:#3A3A3A;
                            font-size: 13px;
                            font-weight: 400;
                        }
                        span{
                            color: #1967D2;
                            font-family: 'Inter';
                            font-size: 11px;
                            font-weight: 600;
                            text-transform: uppercase;
                            padding: 8px 16px;
                            border-radius: 4px;
                            background:  #E9F0FA;
                            text-align: center;
                            float: right;
                            width: 121px;
                        }
                    }
                }
                &.desktop{
                    position: absolute;
                    right: 0;
                    top: -8px;
                }
            }
        }

        .user-dashboard_logged-in {
            padding: 0 !important;

            .user-dashboard {
                padding: 0 !important;

                .accordion {
                    margin-left: 0;
                    margin-right: 0;
                    width: 100%;
                }
            }
        }
    }

    @media (min-width: $tablet) {
        &-dashboard{
            .add-vendors{
                &.mobile{
                    display:none;
                }
            }

        }
        .user-invite-hub{
            .card-header {
                width: 50%;
            }
        }
        .wallet {
            float: right;
            max-width: 300px;
        }
        .add-venue{
            display: inline-flex;
            justify-content: end;
        }
        &-dashboard {
            &.host-event-container.update-venue-dashboard {
                max-width: 1140px !important;
            }
        }
        .modal-dialog {
            max-width: 800px;
        }
    }

    @media (max-width: $tablet) {
        &-dashboard{
            .add-vendors{
                &.desktop{
                    display: none;
                }
            }
        }
        .btn-view-hub{
            float: left;
        }
        .wallet {
            width: 250px;
            position: relative;
            left: -76px;
            top: 73px;
        }
        .caterer-modal {
            .event--cta{
              .whatsapp,.btn{
                width: 100%;
              }  
            }
        }


        #venue-list-cards-empty {
            padding: 20px;
        }

        .user-dashboard {
            .accordion {
                .card--pending-invite {
                    width: 100%;
                }
                .card-body{
                    width: 100% !important;
                }
            }
        }

        .modal-hero {
            margin-bottom: 60px;

            .col-image {
                position: relative;
                top: 81px;
            }

            .col-details {
                position: relative;
                top: 57px;
            }
        }

        .btn-request {
            width: 100%;
            max-width: 100%;
        }

        .venue-photo-gallery {
            .btn-gallery {
                margin-top: 15px;
                margin-bottom: 61px;
            }
        }

        .modal-body {
            .btn-request {
                width: 100%;
                max-width: 100%;
            }

            .venue-photo-gallery {
                .btn-gallery {
                    margin-top: 15px;
                    margin-bottom: 61px;
                }
            }

            .modal-hero {
                margin-bottom: 60px;

                .col-image {
                    position: relative;
                    top: 81px;
                }

                .col-details {
                    position: relative;
                    top: 57px;
                }
            }
        }
    }
}