.terms-and-conditions--content {
// General styling
li {
  a {
    text-transform: capitalize;
    font-weight: 500;
  }
}
h2, p {
  margin-bottom: $s-s;
}

// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}