form, .form_view {
  width: 100%;

  // General styling 
  * {
    &:focus {
      box-shadow: none !important;
    }
  }

  .form-group {
    width: 100%;
    margin-bottom: $s-m - 10px;
    position: relative;
    span {
      font-size: 16px;
      line-height: 2;
      display: block;
    }
    .form-control {
      &::placeholder {
        color: $brand-primary;
        opacity: .5;
      }
    }
  }
  .form-group.last {
    margin-bottom: $s-m - 32px;
  }
  h3 {
    font-size: 21px;
  }
  
  // Text field styling
  input[type="text"],input[type="number"], 
  input[type="date"], input[type="time"],
  input[type="password"], input[type="email"],
  input[type="tel"]{
    @include form(30px, 16px);
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;
    padding-left: 0;
    margin-top: $s-s;
    &::placeholder {

    }
  }
  
  // Remove arrows from Mozilla number field
  input[type="number"]{
    -moz-appearance: textfield;
    }

    input:disabled:hover {
      cursor: not-allowed;
  
      &+span {
        cursor: not-allowed;
      }
    }
  
  // Text area styling
  textarea {
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    padding: 5px;
    display: block;
    line-height: 31px;
    padding: 8px 10px;
    min-height: 168px;

    &::placeholder {
      font-size: 16px !important;
      color: #9B9B9B;
      font-weight: 500;
      text-transform: capitalize;
    }
  }
  
  // Label styling
  label {
    display: block;
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: $s-t * 2;
    color: $brand-primary;
    ~ input:first-of-type, ~ textarea:first-of-type {
      margin-top: $s-t !important;
    }
  }
  label.email-text {
    margin-top: 32px !important;
  }
  
.error {
  color: red;
  text-transform: none;
  font-weight: 500;
}

  select {
    display: block;
    width: 100% !important;
  }
  
  // Select2 plugin styling
  .select2 {
    &-container {
      // @include form(25px, 14px);
    }
    &-selection {
      @include form(25px, 14px);

      &__placeholder {
        color: white !important;
        line-height: unset !important;
      }
    
      &-dropdown {
        //
      }
      &__placeholder {
        //
      }
      &__arrow {
        b {
          display: none;
        }
        &::before {
           border: 0;
            content: "\f078";
            font-family: "Font Awesome 5 Free";
            font-size: 15px;
            vertical-align: 0px;
            position: relative;
            width: 15px;
            height: 15px;
            margin: 0;
            color: white;
            font-weight: 900;
        }
      }
      &__rendered {
        line-height: 25px !important;
      }
    }
  }
  
  // Bootstrap plugin styling
  // .bootstrap-filestyle {
  //   input {
  //      display: flex;
  //      width: 100% !important;
  //      background-color: transparent !important;
  //      border: 0 !important;
  //      order: 2;
  //      padding-left: 0;
  //      margin-top: 5px;
  //      font-size: 10px !important;
  //   }
  //   > span {
  //       display:flex;
  //       order: 1;
  //       background-color: transparent;
        
  //       label {
  //           background-color: transparent;
  //           color: $font-general;
  //           border-radius: 12.5px !important; 
  //               padding: 0 15px;
  //           span {
  //              font-weight: 500 !important;
  //           }
  //          &:hover {
  //          background-color: lightgray;
  //          color: $font-general;
  //          }
  //       }
  //   }
  //  }
  
  // Desktop specific styling
  @media (min-width: $tablet) {
  }
  
    // Mobile specific styling
    @media (max-width: $tablet) {

    }
  }

    // Validation (for some reason not working when nested in 'form' sass above
    .was-validated {
      .help-block {
        display: none !important;
      }
    }