.event-tag {
    padding: 0px 9px;
    background: #F69C3A;
    border-radius: 5px !important;
    text-align: center;
    border: 1px solid #F69C3A !important;
    max-width: fit-content;
    p {
        margin: 0 !important;
        font-style: normal;
        font-weight: 600 !important;
        font-size: 13px !important;
        line-height: 26px!important;
        color: #FFFFFF !important;
        padding: 0 !important;
        border: none !important;
    }

    &_card {
        margin: 0;
        position: absolute;
        top: 25px;
        right: 15px;
    }
}