.manage-connection{
    .nav-tabs{
        margin: 30px 0;
        .nav-item{
            width: 50%;
            .nav-link{
                color:  #3A3A3A;
                font-family: 'Inter';
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px; /* 145.455% */
                letter-spacing: 0.077px;
                text-transform: uppercase;
                text-align: center;
                &.active{
                    font-weight: 600;
                    border: none;
                    border-bottom: 2px solid #8DD728;
                }
            }
        }
    }

    .connected-component{
        border-bottom: 1px solid  #EBEBEB;
        padding-bottom: 15px;
        .wallet-wrapper{
            border-radius: 8px;
            background: #E9F0FA;
            width: fit-content;
            height: fit-content;
            padding: 6px 8px 8px 8px;
            max-width: 45px;
            cursor: pointer;
            .plus{
                color:  #1967D2;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                text-align: center;
            }
        }
        .user{
            width: fit-content;
            height: fit-content;
            display: flex;
            align-items: center;
            padding-top: 10px;
            position: relative;
            img{
                width: 34px;
                height: 34px;
                border-radius: 50%;
                border: 1px #ECECEC;
            }
            .name-and-rating{
                display: inline-block;
                margin-left: 5px;
                p{
                    color: #3A3A3A;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
            .manage-cta{
                position: absolute;
                right: 15px;
                color: #3A3A3A;
                font-size: 12px;
                font-weight: 400;
                &::after{
                    content: url('/static/dist/img/selector chevron down.svg');
                    position: absolute;
                    right: -16px;
                    top: 1px;
                    cursor: pointer;
                  }

                .remove-box{
                    position: absolute;
                    width: 159px;
                    border-radius: 4px;
                    border: 1px solid #EBEBEB;
                    background:  #FFF;
                    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
                    right: 0px;
                    z-index: 1;
                    .header-remover{
                        position: relative;
                        height: 42px;
                        border-bottom: 1px solid #EBEBEB;
                        margin-bottom: 10px;
                        
                        .close-remove{
                            position: absolute;
                            font-size: 31px;
                            right: 9px;
                            top: -5px;
                            cursor: pointer;
                        }
                    }
                    .remover-body{
                        ul{
                            list-style: none;
                            li{
                                a{
                                    float: right;
                                    margin-right: 10px;
                                    margin-bottom: 10px;
                                }
                                img{
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .hosting-wrapper{
        border-bottom: 1px solid #EBEBEB;
        background: #F7F7F7;
        margin-top: 15px;
        h3{
            color: #3A3A3A;
            font-size: 16px;
            font-weight: 500;
        }
        label{
            display: inline-flex;
            color: #717171;
            font-family: 'Inter';
            font-size: 11px;
            font-weight: 600;
            letter-spacing: 0.077px;
            text-transform: uppercase;
            padding: 30px 0;
            input{
                border-radius: 2px;
                border: 1px solid #DCDFE0;
                padding-left: 10px;
            }
            .current-input{
                max-width: 115px;
                height: 34px;
                position: relative;
                top: -7px;
                margin-left: 5px;
            }
            .add-more-input{
                max-width: 146px;
                height: 34px;
                position: relative;
                top: -7px;
                margin-left: 5px;
                
            }
        }
        .balance-error-message{
            font-size: 14px;
            font-style: italic;
        }
        .btn-wallet{
            border-radius: 5px;
            background: #F69C3A;
            box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.02), 0px 2px 12px 0px rgba(0, 0, 0, 0.11);
            width: fit-content;
            font-size: 14px;
            font-weight: 600;
            color: #FFF;
            height: 35px;
        }
    }
}