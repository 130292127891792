.article_banner {
    display: flex;
    gap: 21px;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #062C2D;
    padding: $s-s 40px;

    .read_more {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #5AC5BC;
        border: 1px solid #5AC5BC;
        border-radius: 40px;
        padding: 3px 17px;
        margin: 7px 0 10px 0;
        &-new{
            color: #5AC5BC;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            text-decoration: underline;
        }
    }

    .banner_text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #FFFFFF;


        .days {
            color: #F69C3A;
            font-weight: 600;
        }

        .hashtag {
            font-weight: 600;
        }
    }

    @media screen and (max-width: 652px) {
        flex-direction: column;
        align-items: center;
        gap: 13px;
        // .banner_text{
        //     margin: 18px 0 0 27px;
        // }
        .read_more{
            margin: 0px 0 15px 27px;
        }
    }
    @media (max-width: $tablet) {
        padding: $s-s 25px;
      }
}