// Select all bootstrap popovers

  .popover-pill {
    color: white;
    display: inline-block !important;
    border-radius: 15px;
    font-size: unset !important;
    line-height: 1 !important;
    padding: 5px 1px;
    right: 0;
    top: -10px;
    position: absolute;
    background: #cccccc;
    transition: .2s background-color ease-in-out;
    outline: none;
    cursor: pointer;
    span {
      font-size: 10px !important;
      display: inline-block !important;
      line-height: 10px !important;
      top: -1px;
      position: relative;
      padding: 0 5px;
      text-transform: uppercase;
    }
    &.public[aria-describedby] {
      // background-color: $success;
      background-color: $brand-primary;
    }
    &.private[aria-describedby] {
      // background-color: $failure;
      background-color: $brand-primary;
    }
  }


// Popover content
.popover {
&-header {
  background: $brand-primary;
  color: white;
}
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}