.watch-video {
    &--button {
        border: 1px solid #FFFFFF !important;
        border-radius: 5px;
        background-color: transparent !important;
        color: #FFFFFF;
        margin-left: 15px;
        min-width: 188px;
        width: 100%;

        img {
            margin-right: 13px;
        }
    }
    .modal {
        &-dialog {
            max-width: 732px;
        }
        &-body {
                img {
                    width: 117px;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                }
                h3 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 38px;
                    line-height: 48px;
                    text-align: center;
                    color: #000000;
                    margin-top: 31px;
                    margin-bottom: 23px;
                }
                p {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 26px;
                    text-align: center;
                    color: #000000;
                }
        }
        &-content {
            .about-us {
                font-weight: 400;
                font-size: 13px;
                line-height: 15px;
                letter-spacing: -0.02em;
                text-decoration-line: underline;
                color: #000000;
                text-align: center;
            }
        }
        &-footer {
            justify-content: center;
            button {
                border: 1px solid #202124 !important;
                background:transparent !important;
                color: #202124 !important;
                min-width: 239px;
                max-width: 218px !important;
                padding-left: 0;
        
                img {
                    height: 40px;
                    position: relative;
                    right: 3px;
                }
                span {
                    margin-left: 20px;
                }
                .video-play {
                    position: absolute;
                    left: 25px;
                    right: 0;
                    height: 20px;
                }
            }
        }
        
    }
    // Mobile specific styling
@media (max-width: $tablet) {
    .modal {
        &-dialog {
            max-width: 732px;
        }
        &-body {
                h3 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 40px;
                    text-align: center;
                    color: #000000;
                    margin-top: 31px;
                    margin-bottom: 23px;
                }
        }
        
    }
    &--button {
        margin-left: 0;
    }
    }
}