.modal {
// General styling
top: 63px !important;
&-dialog {
  max-width: 368px;
}
&-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3A3A3A !important;
  padding-top: 6px;
}
&-header {
  background-color: #FFF;
  border-bottom: none !important;
  padding: 23px 23px 16px 23px;
  .close {
    opacity: 1;
    span {
      font-size: 35px;
      line-height: 1;
      color: #3A3A3A;
    }
  }
}
&-body {
  padding: 0 23px 20px 23px;
}
&-footer {
  border-top: none !important;
  padding: 0 23px 23px 23px;
  a {
    @include orange-button;
  }
}
&-content {
  border-radius: 8px;
  border: 0;
  .sa-payments-notice {
    margin-top: $s-s;
  }
}
&-reviews {
  &--header {
    padding: 23px 23px 23px 23px;
    background-color: white !important;
    h5 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #3F4142;
      position: relative;
      top: 2px;
    }
  }
  &--dialog {
    max-width: 732px;
  }
  &--body {
    max-height: 436px;
    overflow-y: auto;
    /* width */
    &::-webkit-scrollbar {
      width: 7px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #E6E6E6; 
      border-radius: $border-radius;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #E6E6E6; 
    }
    .header {
      img {
        width: 50px;
        margin-left: 15px;
        border-radius: 50%;
        border: 1px solid #ECECEC;
      }
      .user {
        margin-left: 10px;
        h1 {
          font-size: 18px;
        }
        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
    .ratings {
      &-body {
        margin: 16px 0 15px 0;
        p {
          font-weight: 400;
          font-size: 15px;
          line-height: 23px;
          color: #676767;
        }
      }
      &-footer {
        margin-bottom: 23px;
      }

    }
  }
}
&-card {
  border-bottom: 1px solid #EBE9E9;;
  margin-bottom: 28px;
}
.btn {
  color: $font-general;
  // width: 50%;
  max-width: 200px;
  height: 48px;
  border-radius: 7px;
  min-width: 45%;
  &_primary {
    color: white !important;
  }
}
// Desktop specific styling
@media (min-width: $tablet) {

}

  // Mobile specific styling
  @media (max-width: $tablet) {
    &-dialog {
      max-width: 100%;
      margin-top: 1rem;
    }
  }
}