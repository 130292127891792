.contact-content {
  // General styling
      .banner {
        background: url('/static/dist/img/contact-us-banner.jpg');
        height: 405px;
        background-repeat: no-repeat;
    background-size: cover;
      }
      .banner-container {
        position: relative;
      }
      .contact-group {
        padding: 0 24px;
      }
      .overlay {
        background-color: rgba(60, 65, 71, 0.2);
        position: absolute;
        height: 100%;
        min-height: 405px !important;
        top: 0;
      }
      h2 {
        margin-top: 93px;
        margin-bottom: 12px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        color: #3A3A3A;
        @media (max-width: $tablet) {
          margin-top: 45px;
        }
      }
      p {
        margin-bottom: 25px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #676767;
      }
      hr {
        color: #ECECEC;
        margin: 30px 0;
      }
      form {

      }
      h1 {
        position: absolute;
        bottom: 75px;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 34px;
        color: #FFFFFF;
        margin-left: 40px;
      }
      input {
        background: transparent;
        color: $brand-primary;
        &::placeholder, &:focus {
          background: transparent;
          color: $brand-primary !important;

        }
      }
      textarea {
        background: transparent;
        color: $brand-primary;
        &::placeholder, &:focus {
          background: transparent;
          color: $brand-primary !important;
          text-transform: none !important
        ;
        }
      }
      label {
        color: $brand-primary;
        text-align: left;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
      }
      .button-container {
        button {
          @include orange-button;
          margin-bottom: 85px;
        }
      }
  // Desktop specific styling
  @media (min-width: $tablet) {

  }

    // Mobile specific styling
    @media (max-width: $tablet) {
      .banner {
        background: url(/static/dist/img/contact-us-banner-mobile.png) center/cover;
        height: 310px;
      }
      form {
        margin-left: 0;
      }
    }
  }