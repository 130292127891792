.email {
    border: 10px solid #000;
    background-color: #FFF;

    &_hero {
        height: 150px;
        background-image: url('/static/dist/img/Mailer-Header.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    &_content {
        text-align: center;
        padding: $s-m;
        h3{
            font-size: 25px;
            color: #000;
        }
        p{
            margin-top: $s-m;
            color: #000;
        }
        a.btn {
            background: #4A4A4A;
            color: #FFF;
            text-transform: uppercase;
            font-weight: 600;
            padding: 25px;
            border-radius: 15px;
            margin-top: $s-m;
        }
        textarea {
            ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                margin-left: $s-s;
              }
              ::-moz-placeholder { /* Firefox 19+ */
                margin-left: $s-s;
              }
        }
    }
}