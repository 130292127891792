.covid19-guidelines {
    margin: 20px 0;
    input {
        width: unset;
        position: relative;
        top: 5.8px;
        margin-right: 10px;
    }
    span {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #3F4142;
        a {
            text-decoration: underline !important;
        }
    }
}