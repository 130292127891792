.footer {
// General styling
background: #FAFAFA;
color: white;
padding: 20px;
width: 100%;
box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05), 0px 2px 16px rgba(0, 0, 0, 0.04);
margin-top: auto;

.container {
  background-color: unset !important;
  box-shadow: unset !important;
}
.center-container {
  text-align: center;
}
.cookie-policy {
  // color: #F69C3A !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  text-decoration-line: underline !important;
  color: #3F4142 !important;
}
a {
  color: $brand-tertiary;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
}
p {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 22px !important;
  margin-bottom: 0 !important;
  color: $brand-tertiary !important;

}
.blackbox {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 22px;
  color: #F69C3A;
  text-decoration-line: none !important;
}
&--desktop-list {
 margin-right: 22px;
}
&--info {
//
}
&--email {
  text-align: center;
  span {
    a {
      font-weight: bold;
      font-size: 16px;
    }
  }
}
&--logo {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}
 &--img {
   height: 30px;
   max-width: 100%;
 }
&--social-media {
   justify-content: end;
  .col {
    justify-content: center;
    flex-flow: row;
    display: flex;
    span {
      float: right;
      img {
        width: 32px;
        height: auto;
        margin: 0 10px;
      }
    }
  }
  .icons {
    position: relative;
    left: 3px;
    a:last-child {
      position: relative;
      left: 3px;
    }
  }
  a {
    padding: 7px;
  }
}
&--ctas {
  .col {
    text-align: center;
  }
  .find {
    border-left: 1px solid $brand-primary;
  }
  a {
   color: $font-general; 
   font-weight: 700;
   text-decoration: none;
   box-shadow: none;
   &:hover,
   &:focus {
       box-shadow: none;
       color: $brand-primary;
   }
  }
}
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
    &--info {
      max-width: 78% !important;
    }
    .center-container {
      text-align: left;
    }
    a,p {
      font-size: 11px;
    }
    &--email {
      span {
        padding: 10px 0;
      }
    }
    &--social-media {
      span {
        margin: $s-s 0;
        display: block;
        width: 100%;
        text-align: center;
      }
    }
  }
}