.new-way-to-eat {
    padding: 72px 0;
    h1,p {
        margin: auto;
        max-width: 580px;
        text-align: center;
    }
    h1 {
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 20px;
    }
    p {
        font-size: 15px;
        line-height: 20px;
    }
    @media (max-width: $tablet) {
        padding: 50px 25px;
        padding-bottom: 30px;
        h1 {
            text-align: left;
        }
        p {
            text-align: left;
        }
    }
}