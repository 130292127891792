.new-events, .events_local, .events_global {
    background-color: white;
    .custom-container{
        max-width: 1068px;
        h1 {
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: $brand-primary;
            margin-top: 15px;
            margin-left: 10px;
            margin-bottom: 10px;
        }
    }
        &--container {
            display: flex;
            justify-content: center;
            max-width: 1075px;
            justify-content: center;
            margin: auto;
        }

        &--cards {
            height: 344px;
            background: #FFFFFF;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08), 0px 0px 10px rgba(0, 0, 0, 0.08);
            border-radius: 5px;
            &-container-link {
                width: unset;
                &:hover {
                    text-decoration: none !important;
                }
            }
            &-body {
                padding: 16px 18px;
                min-height: 200px;
                height: 200px;
                overflow: hidden;
                .marker {
                    margin-left: 1px;
                }
               .row {
                margin: 0;
               }
               h1 {
                font-weight: 600;
                font-size: 22px;
                line-height: 27px;
                color: $brand-primary;
                margin-bottom: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                width: 100%;
                white-space: nowrap;
               }
               .date {
                 p {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    color: #676767;
                    margin-left: 7px;
                    position: relative;
                    top: 3px;
                 }
               }
               .location {
                   p {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    color: #676767;
                    margin-left: 10px;
                    position: relative;
                    top: 3px;
                   }
               }
               .description {
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    margin-top: 8px;

                    font-weight: 400;
                    font-size: 15px;
                    line-height: 23px;
                    color: #676767;

               }
            }
            &-header {
                padding: 13px;
                border-bottom: $border;
                .row {
                    margin: 0;
                }
                .card-profile {
                    @include circle-frame (50px, 0px, $font-general);
                }
                .no-rating {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .name {

                    p {
                        font-size: 14px;
                        line-height: 20px;
                        margin-left: 10px;
                        margin-top: 4px;
                    }
                    .rating-stars {
                        margin-left: 10px;
                    }
                }
            }
            &-footer {
                display: flex;
                justify-content: space-between;
                padding: 19px 18px 24px 18px;
                border-top: 1px solid #ECECEC;
                &.past-footer {
                    border: none !important;
                }


                .tag {
                    padding: 5px 10px;
                    border: $border;
                    border-radius: 5px;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 20px;
                    color: $brand-primary;
                }
                .fee {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 26px;
                    color: $brand-primary;
                    span {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 24px;
                        color: #676767;
                    }
                }
            }
        }
}
@media (max-width: $tablet) {
    .new-events {
        &--container {
            display: block;
            justify-content: center;
            padding: 25px;
            padding-bottom: 20px;
            padding-top: 0 !important;
        }
        &--cards {
            // width: 100%;
            margin: 10px auto;
            display: inline-block;
            width: 100%;
            &-container-link {
                width: 100%;
            }
        }
        &--button {
            .btn {
                margin-top: 0;
                margin-bottom: 39px;
            }
        }

}
  }