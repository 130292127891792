.newsletter-signup {
  // Module specific variables
  $newsletter-signup--submit: 48px;

    // General styling
    background: white;
    color: $font-general;
    padding: $s-m;
    text-align: center;
    a {
      display: block;
      text-decoration: underline;
    }
    p {
      width: 100%;
    }
    &--text {
      h2 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: $s-s;
        display: block;
        vertical-align: middle;
        vertical-align: -webkit-baseline-middle;
      }
    }
    &--input {
      @include border(1px, $brand-primary, 5px);
      width: 100%;
      max-width: 400px;
      margin: auto;
      margin-bottom: $s-m;
      padding-left: $s-s;
      input {
        border: 0;
        width: calc(100% - #{$newsletter-signup--submit});
        height: 100%;
        margin-top: 5px !important;
        border-bottom: 0 !important;
      }
      button {
        width: $newsletter-signup--submit;
        float: right;
        border: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background-color: $brand-primary;
        color: white;
        height: 40px;
      }
    }
    
    // Desktop specific styling
    @media (min-width: $tablet) {
        font-size: 18px;
      }
    
      // Mobile specific styling
      @media (max-width: $tablet) {
        &--text {
          //
        }
        &--input {
          //
        } 
      }
    }