.user-profile {
// General styling
padding-top:$s-l;
position: relative;
margin-top: 29px;
margin-bottom: -10px;
padding-bottom: 0;
margin-bottom: 18px !important;
.container {
  max-width: 658px !important;
}
.information-label {
  padding-bottom: 16px;

  &--line-break {
    margin: 0 -30px;
    background-color: #ECECEC;
    color: #ECECEC;
    border: 0;
    border-top: 1px solid rgba(0,0,0,0.1);
    margin-bottom: 20px;
  }
}
h2 {
  font-size: 14px;
  color: $brand-primary;
  margin-bottom: 7px;
}
hr {
  margin-top: 5px;
}
.btn {
  &.edit {
    @include transparent-button;
    position: absolute;
    right: 35px;
    top: $s-l;
    min-width: 80px;
    height: 34px;
    box-shadow: none;
    width: fit-content !important;
    border: 1px solid #C3C3C3;
    
  }
  &.cancel-profile {
    margin-bottom: 10px !important;
    @include orange-button;
    margin-left: 10px
    
  }
  &.save-profile {
    @include transparent-button;
    width: 150px;
  }
}
&--header {
//   margin-bottom: $s-s;
  margin-bottom: 0; 
  // @include margin-bottom;
  .social-info {
    margin-bottom: 34px;
    a {
      width: 31px;
      height: 31px;
      border: 1px solid rgba(246, 156, 58, 0.3);
      border-radius: 50%;
      margin-right: 10px;
      text-align: center;
      padding: 2px;
    }
  }
  &--metadata {
//     margin-left: -$s-s
       margin-bottom: 16px;
       margin-top: 6px;
       .public-details {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
       }
  }
}
&--user-name {
  margin-top: 15px;
}
&--container {
  position: relative;
  .profile {
    margin: unset;
  }
}
&--introduction {
  text-align: center;
  margin-top: 14px;
  margin-bottom: 14px;
  .text-counter {
    p {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #888B8E;
    }
  }
  p {
    text-align: start;
  }
}
&--data {
  background: #FFFFFF;
  border: $border;
  border-radius: $border-radius;
  margin-bottom: 20px;
  width: 100%;
  margin: auto auto 20px auto;
  .col {
     .div-trigger {
      padding: 16px 5px;
     }
    .trigger {
      font-weight: 600;
      font-size: 16px;
      width: 100%;
      text-align: left;
      i {
         right: 21px;
         position: absolute;
         font-size: 18px;
         color: #9B9B9B !important;
      }
      // Rotate arrow
      &[aria-expanded="true"]{
         i.fas {
            &::before {
                content: "\f068";
            }
        }
      }
    }
    .btn {
      margin: $s-m auto auto;
      display: block;
      min-width: 150px;
      max-width: fit-content;
    }
  }
}
.banking-details {
  margin-bottom: $s-s;
  position: relative;
  padding: $s-s + 5px 5px;
  max-width: 100%;
  box-shadow: none !important;
  border-bottom: 1px solid #ECECEC !important;
  border-radius: 0 !important;
  h2 {
    font-size: 16px;
    font-weight: 600;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #919191;
    margin: 4px 0 26px 0;
  }
  .add-replace-card{
   margin: 0;
   border: $border;
   min-width: unset !important;
   &:active,&:focus,&:hover {
    outline: none !important;
    box-shadow: none !important;
    text-decoration: none !important;
    background: rgba(63,65,66,0.05);
    border: 1px solid #202125;
   }
  }
  .credit-card-number {
    text-align: left;
    &.alert {
      color: #F73E55;
      padding: 0;
    }
  }
}
&--form {
  text-align: left;
  .social-info {
    margin-bottom: 41px;
  }
  h1 {
    // font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #3A3A3A;
    margin-bottom: 16px;
  }
}
&--inner-form {
  padding: 0 5px;
  a {
    text-decoration: underline;
  }
}

// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
    .btn {
      &.edit {
        &.btn_light {

        }
      }
      &.save-profile {
        margin-left: 0;
        border: none;
        box-shadow: none;
        order: 2;
      }
      &.cancel-profile {
        margin-left: 0;
      }
    }
  }
}

// Global setting for page
.profile {
  .user-layout {
    display: block !important;
  }
}