.landing-page--introduction {
// General styling

background: $brand-charcoal;
color: white;
text-align: center;
a.btn {
  color: white;
  width: 125px;
  display: block;
  margin: auto;
}
h2 {
  color: white;
  font-weight: 700;
  margin-bottom: $s-s;
}
p {
  margin-bottom: $s-s;
}
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}