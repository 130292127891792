.top-banner-article {
    height: 370px;
    background-image: url('/static/dist/img/article-cover.jpg');
    padding: 0 40px;
    background-size: cover;
    position: relative;
    @media (max-width: $tablet) {
        padding: 0 6px;
    }
    &--date-logo {
        margin: auto;
        &::after {
            background-image: url('/static/dist/img/28-days-dd-logo.png');
            background-size: 400px;
            display: inline-block;
            height: 400px;
            width: 400px;
            background-repeat: no-repeat;
            content:"";
            position: relative;
            top: 112px;
            @media (max-width: $tablet) {
                background-size: 300px;
                height: 300px;
                width: 300px;
                top: 127px;
                left: 50%;
                transform: translateX(-50%);
            }
          }
    }

    .inner-article {
        max-width: 1600px;
        margin: 0 auto;

        h1 {
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 34px;
            color: #FFFFFF;
            padding-top: 91px;
        }

        .btn {
            margin-top: 22px;
            width: 127px !important;
            @include orange-button;

            &:hover {
                border: 0;
                box-shadow: none
            }
        }
    }
    
    //   @media (max-width: $tablet) {
    //     background: url('/static/dist/img/Campaign-article-page-mobile 3.jpg');
    //     padding: 0 25px;
    //     height: 328px;
    //     background-size: cover;
    //     background-position: bottom;
    //   }

    //   @media screen and (min-width: $mobile) and (max-width: $tablet) {
    //     background: url('/static/dist/img/Campaign-article-page-mobile 3.jpg');
    //     padding: 0 25px;
    //     height: 328px;
    //     background-size: cover;
    //    background-position: 5px -20px;
    //   }
      
}