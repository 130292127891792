.header--navigation {

//   General styling
  position: fixed;
  width: 100%;
  top: 0;
  z-index:100000;
  background: transparent;
  transition: all .2s ease-in-out;
  background: $brand-tertiary;
  box-shadow: $box-shadow;
  
  .navbar {
    height: $navigation-height-desktop;
    padding: $s-t;
    max-width: 1600px;
    margin: 0 auto;

    &-buttons {
        .nav-item {
          .sign_up {
            background: #3F4142;
            border-radius: 40px;
            padding: 6px 17px !important;
            color: white !important;
            margin-left: 10px;
            .profile {
              width: 30px;
              min-width: 30px;
              height: 30px;
              margin-right: 10px;
            }
            p {
              position: relative;
              top: 3px;
            }
          }
          .dashboard {
            background: #F9F9F9;
            border-radius: 40px;
            padding: 2px 17px 4px 6px !important;
            color: $brand-primary!important;
            margin-left: 15px;
            .profile {
              width: 30px;
              min-width: 30px;
              height: 30px;
              margin-right: 10px;
              margin-top: 2px;

            }
            p {
              position: relative;
              top: 5px;
            }
          }
          .login {
            background: transparent;
            border-radius: 40px;
            padding: 5px 17px;
            color: white !important;
            border: 1px solid white !important;
            margin-right: 15px;

            @include transparent-button;
            border-radius: 40px !important;
            padding: 5px 17px;
            margin-right: 15px;
            min-width: fit-content;
            height: 38px;
            position: relative;
            text-align: center;
          }
          .host-a-meal  {
            min-width: 155px !important;
          }
          .signout {
            background: transparent;
            border-radius: 40px;
            padding: 5px 17px;
            color: #E7E7E7 !important;
            border: 1px solid #E7E7E7;
            margin-left: 15px;
            img {
              margin-left : 9px;
            }
          }
          .dropdown-menu {
            background: #FAFAFA;
            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.16);
            border-radius: 7px;
            right: 15px;
            top: 66px;
            left: unset;
            .dropdown-item {
              font-weight: 500;
              font-size: 14px;
              line-height: 26px;
              display: flex;
              align-items: center;
              color: #3A3A3A;
              img {
                margin-left: 7px;
              }
            }
          }
          .dropdown-toggle {
            &::after {
              border: none !important;
              content: url('/static/dist/img/three-dots.svg');
              position: relative;
              top: 8px;
              margin-left: 12px;

            }
          }
        }
      
    }
    &-collapse {
      .nav-item {
        .nav-link {
          font-weight: 500;
          text-transform: capitalize;
          text-align: center;
          font-weight: 500;
          font-size: 16px;
          line-height: 26px;
          color: white;

          &.active {
            font-weight: bold;
          }
        }
      }
    }
    &-brand {
      padding: 0;
      position: relative;
      z-index: 2;
      content: url('/static/dist/img/logo_white.svg');
      width: 73px;
      height: 31px;
      img {
        width: 100px;
      }
    }
    &-toggler {
      position: absolute;
      right: 0;
      z-index: 5;
      position: relative;
      width: 20px;
      padding: 0;
      outline: none;
      border: 0;
      /* color: #3A3A3A; */
      top: 0;
      &-line {
        width: 100%;
        height: 4px;
        background: white;
        display: block;
        margin: 4px auto;
        transition: .2s all ease-in-out;
        border-radius: 2px;
      }
      &[aria-expanded="true"]{
       > span {
         &:nth-of-type(1) {
          transform: rotate(45deg);
          top: 7px;
          position: relative;
         }
         &:nth-of-type(2) {
          width: 0;
         }
         &:nth-of-type(3) {
          transform: rotate(135deg);
          bottom: 9px;
          position: relative;
         }
       } 
      }
    }
    &-opened {
      .navbar-toggler-line {
        background: #3A3A3A !important;
      }
    }
    &-nav {
      margin-top: 0;
      &--mobile {
      position: absolute;
      right: 51px;
      top: 2px;
      .profile {
        width: 34px;
        min-width: 34px;
        height: 34px;
        position: absolute;
        top: 16px;
        right: -9px;
      }
    }
    &--login-btn {
      top: 18px;
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 40px;
      color: \white;
      padding: 2px 16px;
    }
    }
    .gtranslate_wrapper {
      position: absolute;

      .gt_float_switcher .gt-selected .gt-current-lang {
        padding: 5px 5px;
      }
      span.gt-lang-code {
        top: 0;
        font-weight: bold;
      }

      .gt_float_switcher {
        border-radius: 5px;
        box-shadow: 0;
      }

    }
   

  } 
  #hamburger[aria-expanded="true"]{
    .navbar-toggler-line {
      background-color: #3A3A3A !important;
    }
  }
  // Desktop specific styling
  @media (min-width: $tablet) {

  } 
  
  // Mobile specific styling
  @media (max-width: $tablet) {
    box-shadow: none;
    .navbar {
      padding: 7px !important;
      flex-wrap: nowrap;
       &-collapse {
       z-index: 4;
       padding: 0;
       width: 292px;
       top: 0;
       right: -292px;
       max-width: 320px;
       left: unset;
       position: fixed;
       box-shadow: $box-shadow;
       height: 100% !important;
       display: block !important;
       transition: .2s right ease-in-out;
       text-align: right;
       margin: 0;
       background: white;
       .sign_in-container {
          display: block !important;
          &--menu {
            padding: 20px;
          }
          .button_as_link {
            color: #3571DE;
            border: none;
            background-color: transparent;
            padding: 0;
      
            &:hover {
              text-decoration: underline;
              border: none;
              background-color: transparent;
            }
            &:focus {
              text-decoration: underline;
              border: none;
              outline: none;
              background-color: transparent;
              color: purple;
            }
          }
          &--buttons {
            padding: 20px;
            border-top: 1px solid #EBEBEB;
            .create-event {
              background-color: $brand-primary !important;
            }
            p {
              text-align: left;
              padding: 13px 13px 0 13px;
            }
          }

          .btn {
            @include orange-button;
            width: 100% !important;
          }
          p {
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: #3A3A3A;
            text-align: left;
            a {
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              color: #3571DE;
            }
          }
       }
    
       &.reveal {
        right: 0px;
       }
        li.nav-item {
          border-top: 1px solid #EBEBEB;
          &:active,&:focus &:focus-visible,&:focus-within{
            color: #ADADAD ;
            text-decoration: none;
          }
          &:nth-last-of-type(1) {
            border-bottom: 1px solid #EBEBEB;
          }
          .nav-link {
            text-align: right;
            padding: $s-s;
            color: #3A3A3A ;
            &:active,&:focus &:focus-visible,&:focus-within{
              color: #ADADAD;
              text-decoration: none;
            }
          }
          .logout {
            color: #ADADAD !important;
          }
        }
      }
      &-buttons {
        display: none !important;
      }
    }
  }
}
.nav-white {
  .header--navigation {
    .navbar {
      &-collapse {
          .nav-link {
            font-weight: 500;
            text-transform: capitalize;
            text-align: center;
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: $brand-primary;
            
          }
      }
      &-brand {
        content: url('/static/dist/img/logo_grey.svg');
        width: 73px;
        height: 31px;
      }
    }
  }
}
