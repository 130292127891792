.youtube-video-modal {
    text-align: center;
    button {
        border: 1px solid #202124 !important;
        background:transparent !important;
        color: #202124 !important;
        min-width: 239px;
        max-width: 218px !important;
        padding-left: 0;

        img {
            height: 40px;
            position: relative;
            right: 3px;
        }
        span {
            margin-left: 20px;
        }
        .video-play {
            position: absolute;
            left: 25px;
            right: 0;
            height: 20px;
        }
    }
    .modal {
        &-content {
            background: #202124;
            position: absolute;
            left: 0;
            height: 536px;
            border-radius: 7px;
            width: 733px;
            padding-top: 63px;
            .close {
                opacity: 1;
                margin-right: 15px;
                width: 10px;
                max-width: 10px !important;
                min-width: 52px;
                position: absolute;
                right: 0;
                top: 14px;
                span {
                    font-size: 35px;
                    line-height: 1;
                    color: white;
                }
            }
        }
        &-body {
            padding: 0;
        }
        &-header {
            background: #202124;
        }
    }
    

// Desktop specific styling
@media (min-width: $tablet) {
}

// Mobile specific styling
@media (max-width: $tablet) {
        .modal {
            &-content {
                width: 100%;
                height: 100%;
                
            }
            &-body {
                padding-top: 26vh;
            }
        }
    }

}