.info {
    .service-fee {
        font-weight: 400;
        font-size: 14px;
        color: #ADADAD;
        margin-right: 7px;
        position: relative;
        top: 3px;
      }
      button{
        background-color: transparent;
        border: none;
        outline: none !important;
        position: relative;
        bottom: 1.1px;
        img {
          cursor: pointer;
        }
      }
      .info-popup {
        background: #FFFFFF;
        // min-height: 96px;
        width: 370px;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 20px rgba(0, 0, 0, 0.12) !important;
        border-radius: 7px;
        padding: 5px 12px 12px 12px;
        margin-top: 6px;
        position: absolute;
        z-index: 10;
        .header {
          width: 100%;
          height: 7px;
          button {
            float: right;
          }
        }
        p {
          font-weight: 400 !important;
          font-size: 12px !important;
          line-height: 18px !important;
          display: flex;
          align-items: center;
          color: #3F4142;
          margin-top: 0 !important;

        }
      }
}
.host-info {
  display: none;
  margin-left: 40px;

  .host-service-fee {
    font-weight: 400;
    font-size: 10px;
    color: #ADADAD;
  }

  @media (min-width: 768px) { /* Adjust the breakpoint as needed */
    .host-service-fee {
      font-size: 14px;
    }
  }
}
