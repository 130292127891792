.how-to {
// General styling
.banner {
  background: url(/static/dist/img/how-to-banner.png) center/cover;
  height: 461px;
  background-size: cover;
}
.banner-container {
  position: relative;
  margin: -31px 0 93px 0;
}
.overlay {
  background-color: rgba(60, 65, 71, 0.2);
  position: absolute;
  height: 100%;
  height: 461px;
  top: 0;
}
h5 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: $brand-primary;
  margin-bottom: 12px;
}
h3 {
  background: $brand-light;
  padding: 16px;
  border-radius: $border-radius;
  margin: 24px 0;
}
h2{
  margin-top: $s-s;
  font-weight: 600;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: $brand-primary;;
  margin-bottom: 12px;
}
h1 {
  position: absolute;
  bottom: 75px;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #FFFFFF;
  margin-left: 40px;
}
p {
  margin-left: 15px;
}
.subheader {
  margin-left: 0 ;
}
h4 {
  margin-top: $s-s;
  font-weight: 500;
  margin-left: 15px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: $brand-primary;;
}
hr {
  color:$brand-light;
}
.private{
  @include transparent-button;
  width: unset;
  height: 48px;
}
.public {
  @include orange-button;
  height: 48px;
}
.fas {
  margin-right: $s-t;
  font-size: small;
}

// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
    .banner {
      background: url(/static/dist/img/how-to-banner-mobile.png) center/cover;
      height: 461px;
    }
    .private{
      width: unset;
    }
    .public {
      width: unset;
    }
  }
}