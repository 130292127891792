.whatsapp {
    a {
        width: 165px;
        height: 48px;
        background: rgba(96, 214, 106, 0.1);
        border-radius: 7px;
        display: block;
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #60D66A;
        padding: 13px 10px;
        &:hover {
            text-decoration: none;
            background: rgba(90, 202, 99, 0.1);
        }
        img {
            margin-left: 8px;
        }
    }

}