.events_global {
  background-color: $light-grey !important;
  h2 {
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .new-events--cards {
    width: 100%;
    margin: unset;
    &:first-child {
      margin-left: unset;
    }
  }

  .btn_primary {
    margin-top: 25px !important;
  }

  // Desktop specific styling
  @media (min-width: $tablet) {}

  // Mobile specific styling
  @media (max-width: $tablet) {}
}