.subscription{
    .subscription-nav {
        max-width: 300px;
        h3{
            color: #3A3A3A;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px; /* 128.571% */
            letter-spacing: 0.056px;
        }
        .btn-nav-wrapper{
            padding: 8px 10px 8px 40px;
            border-bottom: 1px solid #ECECEC;
            background: #FAFAFA;
            &.active{
                background: #FFF;
            }
        }
        .btn-nav{
            color: #3A3A3A;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
            letter-spacing: 0.072px;
        }

    }

    .btn{
        border-radius: 5px;
        margin-top: 30px;
        background: #F69C3A;
        box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.02), 0px 2px 12px 0px rgba(0, 0, 0, 0.11);
        padding: 13px 20px;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 137.5% */
    }
    .connect-section{
        margin-top: 37px;
        height: 200px;
        border-radius: 16px;
        background: url('/static/dist/img/connect-pic.png') lightgray -15.681px -146px / 105.167% 213% no-repeat;
        background-position: center;
        background-size: cover;
        .square{
            width: 223px;
            height: 166px;
            border-radius: 12px;
            margin-top: 21px;
            background-color: #FFF;
            padding: 20px 10px;
            div{
                color: #FFF;
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                
            }
        }
    }
    .mordern-work{
        margin-top: 37px;
        height: 200px;
        border-radius: 16px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), url('/static/dist/img/mordern.png') lightgray -30px -72px / 105.437% 213.446% no-repeat;
        //background: url('/static/dist/img/mordern.png');

        .square{
            color: #FFF;
            text-align: center;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            width: 223px;
            height: 150px;
            border-radius: 12px;
            border: 2px solid #F69C3A;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 26px;
        }
    }
    .people{
        margin-top: 30px;
        border-radius: 52px;
        padding: 8px;
        border: 1px solid #EBEBEB;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
        padding-left: 30px;
        max-width: 465px;
        .person{
            width: 80px;
            height: 80px;
            display: inline-block;
            margin-left: -15px;
            background-repeat: no-repeat !important;
        }
    }

    .together{
        max-width: calc(100% - 465px)
    }
    .line{
        background-color: #EBEBEB;
        height: 1px;
        margin: 30px 0;
    }
    .cultivating{
        margin-top: 60px;
        h3{
            font-size: 46px;
            font-weight: 400;
            color: #000;
            line-height: 54px;
        }
        h4.grey-text{
            font-size: 24px;
            font-weight: 500;
            line-height: 30px;
            color: #A1A1A1;
        }
        .titl30{
            font-size: 30px;
            color: #3A3A3A;
            font-weight: 500;
            line-height: 42px;
        }
    }
    .sub-cards{
        .card{
            border-radius: 16px !important;
            border: 1px solid #E5E5E5;
            box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
            height: 253px;
            color: #3A3A3A;
            padding: 20px;
            .icon-wrapper{
                border-radius: 24px;
                border: 2px solid #F69C3A;
                width: fit-content;
                padding: 9px 18px;
                .img{
                    width: 24px;
                    height: 24px;
                }
            }
            .title{
                font-size: 18px;
                font-weight: 500;
                line-height: 28px;
                margin: 5px 0;
            }
            .body{
                border-top: 1px solid #EBEBEB;
                padding-top: 10px;
                margin-top: 10px;
                p{
                    color: #727272;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px; /* 150% */
                    letter-spacing: 0.048px;
                    margin: 0;
                }
            }

            &.orange-card{
                background-color: #F69C3A;
                .icon-wrapper{
                    border-color: #FFF;
                    display: inline-block;
                }
                .title-box{
                    width: calc(100% - 111px);
                    display: inline-block;
                    .title{
                        color: #FFF;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.042px;
                        text-transform: uppercase;
                        margin-left: 5px;
                    }
                }
                .body{
                    border-color: #FFF;
                    padding-top: 20px;
                    margin-top: 20px;
                    p{
                        color: #FFF;
                    }
                }
            }
        
        }
    }

    .orange-box{
        border-radius: 16px;
        background: #F69C3A;
        padding: 20px;
        p{
            margin: 0;
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
        }
    }
    .pricing{
        border-radius: 8px;
        border: 1px solid #EBEBEB;
        box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.12);
        padding: 32px 20px;
        margin-bottom: 90px;
        label{
            color:#717171;
            font-family: 'Inter';
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 145.455% */
            letter-spacing: 0.077px;
            text-transform: uppercase;
        }
        h4{
            margin: 0;
            color: #000;
            font-size: 18px;
            font-weight: 400;
            line-height: 28px; 
        }
        h3{
            margin: 0;
            color: #000;
            font-size: 18px;
            font-weight: 500;
            line-height: 28px;  
        }
        p{
            color: #3A3A3A;
            margin: 0;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 128.571% */
            letter-spacing: 0.056px;
        }
        p.title{
            
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
        }
        p.disclaimer{
            color: #717171;
            font-family: 'Inter';
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px; /* 127.273% */
            letter-spacing: 0.077px;
        }
        .pic{
            width: 18px;
            height: 18px;
            background: #E9F0FA;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
        }
        .cream-bg{
            background-color: #FBFBFB;
            padding: 12px 14px;
        }
        .item-border{
            border-bottom: 1px solid #EBEBEB;
            padding-bottom: 15px;
        }
    }
    @media (min-width: $tablet) {}
    @media (max-width: $tablet) {
        .subscription-nav {
            display: none;
        }
        .mordern-work{
            margin: 0 16px;
            margin-top: 30px;
            max-width: 91%;
        }
        .people{
            max-width: 100%;
            padding-left: 53px;
            margin: 30px 13px;
            .person{
                margin-left: -32px;
            }
        }
        .together{
            max-width: 100%;
        }
    }
}