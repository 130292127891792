.background-filler {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: .8;
}

.no-pointer-events {
  pointer-events: none;
}

.img-position {
  &_top {
    object-position: top !important;
  }
  &_center {
    object-position: center !important;
  }
  &_bottom {
    object-position: bottom !important;
  }
}

.hide {
  display: none;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  margin: 0 !important;
}

.click-layer {
  width: 100% !important;
  height: 100% !important;
  display: block;
  position: absolute !important;
  z-index: 3 !important;
}

.empty-notice {
  background: #F9F9F9;
  padding: $s-m;
  border-radius: $border-radius;
  color: #808080;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #828282;
  text-align: start;
}

.top-banner {
  height: 270px;
  background: url("/static/dist/img/landing_page_hero_banner.jpg");
  padding: 0 40px;
  background-size: cover;
  color: #FFFFFF;

  .inner {
    max-width: 1600px;
    margin: 0 auto;
    display: block;
    width: 100%;
    h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 24px !important;
        line-height: 34px;
        color: #FFFFFF;
        padding-top: 91px;

    } 
    .btn {
        margin-top: 22px;
        width: 127px !important;
        @include orange-button;

        &:hover {
            border: 0;
            box-shadow:none
        }
    }
    .portal {
        padding-top: 91px;
        img {
            width: 112px;
            height: 112px;
            border-radius: 50%;
            margin-right: 13px;
            border: 2px solid #FFF;
            object-fit: cover;
            &.sab-foundation-logo,&.the-table-cafe-logo {
                background-color: white;
            }
        }
        h1 {
            padding: 0 !important;
            color: #FFF;
            font-family: 'Inter';
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        p {
            color: #FFF;
            font-family: 'Inter';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .learn-more {
            border-radius: 5px;
            border: 0.5px solid #FFF;
            padding: 20px;
            width: 469px;
            color: #FFF;
            @media (max-width: $tablet) {
                width: 100%;
                margin: 15px 0 48px 0;
            }
            p {
                color: #FFF;
                font-family: 'Inter';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            a {
                color: #FFF;
                font-family: 'Inter';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-decoration-line: underline;

                &::after{
                    content: url('/static/dist/img/greater icon.svg');
                    position: relative;
                    top: 3px;
                    left: 8px;
                }
            }
        }
    }
}
}

.top-banner .inner {
  max-width: 1600px;
  margin: 0 auto;
}

.top-banner .inner h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #FFFFFF;
  padding-top: 91px;
}

.top-banner .inner .btn {
  margin-top: 22px;
  width: 127px !important;
  height: 48px;
  color: white;
  background-color: #F69C3A;
  border: 0;
}

.top-banner .inner .btn:hover, .top-banner .inner .btn:active, .top-banner .inner .btn:focus, .top-banner .inner .btn:focus-visible {
  background-color: #EB8014;
  outline: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  text-decoration: none !important;
}

// Desktop specific styling
@media (min-width: $tablet) {}

// Mobile specific styling
@media (max-width: $tablet) {}