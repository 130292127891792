img, div {
    &.profile {
        @include circle-frame (85px, 2px, $font-general);
    }
}
img, div {
    &.profile_small {
        @include circle-frame (50px, 0px, $font-general);
        margin-left: 10px;
    }
}