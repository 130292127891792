.toast-container {
  position: fixed;
  right: 60px;
  top: 100px;
  z-index: 1050;
.toast-header {
  color: #ffffff;
  background-color: #f4ac63;
}
  // Desktop specific styling
  @media (min-width: $tablet) {

  }

  // Mobile specific styling
  @media (max-width: $tablet) {
    position: fixed;
    right: $s-s;
    top: 85px;
    width: calc(100% - #{$s-m});
  }
}

// .toast-header {
// }

// .toast-body {
// }

