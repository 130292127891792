.user-reviews {
    &--cards {
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08), 0px 0px 10px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        height: 203px;
        padding: 20px 20px 25px 20px ;
        
        .header {
            padding: 0 12px;
            margin-bottom: 15px;
            img {
                height: 50px;
                width: 50px;
                border-radius: 50%;
                border: 1px solid #ECECEC;

            }
            .user {
                margin-left: 10px;
                h1 {
                    font-size: 18px;
                    line-height: 24px;
                }
                p {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;
                    color: #707070;
                }
            }
        }
        .reviews-body {
            margin-bottom: 15px;
            margin-bottom: 15px;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-weight: 400;
            font-size: 15px;
            line-height: 23px;
            color: #676767;
            
        }
    }
    &--button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 24px;
        .btn {
            @include transparent-button;
            width: 327px !important;
            background-color: white;
            margin:20px 0 66px 0;
        }
    }
}
  // Mobile specific styling
  @media (max-width: $tablet) {
    .user-reviews {
        &--button {
            .btn {
                margin:20px 0 54px 0;
                width: 100% !important;
            }
        }
    }
  }