.credit-card {
// General styling
padding-top: 0;
&--accept-card {
  margin-top: 63px;
}
.user-profile {
  margin: 21px 0 0 0;
  padding: 0;
  .host-data {
    margin-bottom: 11px;
  }
}
.explainer-text {
  color: #888B8E;
  font-family: "unset";
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  line-height: 16px; /* 133.333% */
}
.spinner-border{
  display: block;
  margin: auto;
  z-index: 5;
}
h1 {
  text-align: center;
}
&-number {
  text-align: center;
  display: block;
  margin-bottom: $s-s;
}
.add-card {
  margin: auto;
  width: calc(100% - 140px);
  height: 40px;
}
.cancel-container {
  margin-right: 10px ;
}
.btn_primary {
  @include transparent-button;

  border: 1px solid #ECECEC ;
  box-shadow: none ;
  margin-bottom: 44px;
}
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
    .btn-container {
      justify-content: unset !important;
      display: grid;
      .btn_primary {
        width: 100%;
        border: none;
      }
      .cancel-container{
        width: 100%;
        order: 2;
      }
      .accept-container {
        order: 1;
      }
    }
  }
}