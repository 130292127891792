.otp-container {
  h1 {
    color: #3A3A3A;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px; /* 118.75% */
    margin-bottom: 24px;
  }
  span,small {
    color:  #676767 !important;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    margin-bottom: 12px;
  }
  .inputs {
    input {
        border-radius: 0;
        border: 0;
        outline: none !important;
        height: 44px;
        color:  #3A3A3A;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 100% */
        &:first-child {
            border-top-left-radius: 10px !important;
            border-bottom-left-radius: 10px !important;

        }
        &:last-child {
            border-top-right-radius: 10px !important;
            border-bottom-right-radius: 10px !important;
        }
        &:active,&:focus-visible,&:focus-visible,&:hover {
            border: 0;
            box-shadow: none !important;
        }
    }
  }
  hr {
    border: 1px solid #ECECEC;
    margin-top: 46px;
    margin-bottom: 27px;
  }
  .arrow-container{
    display: flex;
    justify-content: start;
    align-items: center;
    img {
        cursor: pointer;
    }
  }
  .validate-container{
    display: flex;
    justify-content: end;
    align-items: center;
    button {
        border-radius: 5px;
        background: #3A3A3A;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.11), 1px 1px 6px 0px rgba(0, 0, 0, 0.02);
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 137.5% */
        width: unset !important;
        margin: 0 !important;
    }
  }
}