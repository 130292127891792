.past-events-content {
    &--thin-title {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        text-transform: uppercase;
        color: #676767;
        margin-bottom: 4px;
    }
    &--bold-title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #3A3A3A;
        margin-bottom: 24px;
    }

    .btn {
        width: 327px;
        height: 48px;
        margin: auto;
        background: transparent;
      }

     .past-events-h1 {
        padding-top: 47px;
        margin: 0 0 22px 0;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #3A3A3A;
     }
    .past-desktop {

        @media (max-width: $tablet) {
            display: none;
        }
    }
    .past-search {
        @media (max-width: $tablet) {
            display: block;
        }
    }
    .past-mobile {
        display: none;
        @media (max-width: $tablet) {
            display: block;
        }
}

    .past-events-card {
        width: 327px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08), 0px 0px 10px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        margin-bottom: 20px;
        margin-right: 20px;
        background-color: white;

        @media (max-width: $tablet) {
            width: 100%;
            margin-bottom: 20px;
        }
        .header {
            padding: 15px;
            border-bottom: 1px solid #DADADA;
        .card-profile {
            height: 50px;
            width: 50px;
            min-width: 50px;
            border-radius: calc(50px / 2);
            border: 3px solid #EDEDED;
            margin: auto;
            display: block;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 5px;
        }
        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #3A3A3A;

        }
        .badge {
            width: 87px;
            height: 26px;
            background: #DADADA;
            border-radius: 5px;
            justify-content: center;
            align-items: center;
            display: flex;
            h6 {
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                line-height: 20px;
                color: #676767;
            }
        }
        }
        .body {
            padding: 15px;
            .location {
                p {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    color: #AEAEAE;
                }
            }
            .new-events--cards-footer {
                .fee {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    color: #AEAEAE;
                }
            }
            .bold-h1 {
                font-weight: 600;
            }
            h1 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: #3A3A3A;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                width: 100%;
                white-space: nowrap;

            }
        }
    }
}
.modal-past--body{
    .modal-card {
        .header {
            .card-profile {
                height: 50px;
                width: 50px;
                min-width: 50px;
                border-radius: calc(50px / 2);
                border: 3px solid #EDEDED;
                margin: auto;
                display: block;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                margin-right: 5px;
            }
        }
    }
}
