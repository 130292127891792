.about-us {
  margin-bottom: 50px;
   &--heroHeader {

     max-width: 1600px !important;

     h1 {
      position: absolute;
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      color: #FFFFFF;
      margin-left: 40px;
       bottom: 55px !important;
     }
   }
  .hero--image {
    background-image: url('/static/dist/img/image_hero_about-us.jpg');
    background-size: cover;
    min-height: 405px !important;
  }
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin: 37px 0;
  }
  h2{
    margin-top: 37px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: $brand-primary;;
    margin-bottom: 12px;
  }
  p {
    margin-bottom: 12px;
  }
  .subheader {
    margin-left: 0 ;
  }
  // Desktop specific styling
  @media (min-width: $tablet) {
    padding-bottom: 30px;
  }

  // Mobile specific styling
  @media (max-width: $tablet) {
    h2{
      margin-top: 50px;
    }
    .hero--image {

      min-height: 264px !important;
    }
  }
}
