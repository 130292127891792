.user-dashboard {
  &.container {
    margin-bottom: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: $s-m;
  }

  .event-header-title {
    border-top: $border;
    border-bottom: $border;
    padding: 16px 0px;
  }
.no-events {
  background-color:  #F9F9F9;
  width: 100%;
  padding: 20px;
  text-align: center;
  h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $brand-primary
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #7F7F7F;
    margin-top: 2px
  }
  a {
    &:hover {
      text-decoration: none
    }
  }
  button {
    @include orange-button;
    width: 327px;
    margin-top: 18px;
  }

}
.accordion {
  .accordion-custom-card {
    border: 1px solid #ECECEC !important;
    background-color: transparent;
    border-radius: 7px !important;
  }
  .card {
    border: 0;
    min-width: 100%;
    max-width: 100%;
    margin-top: 0;
    box-shadow: none;
    &--public {
      padding: 18px 15px !important;
    }
    &-header {
      background-color: white !important;
      border-radius: 5px 5px 0 0;
      z-index: 1;
      .rating-submit {
        width: unset;
        min-width: fit-content;
        background-color: #8DD728;
        height: 34px;
        margin: auto;
        padding-left: 17px;
        padding-right: 17px;
      }
      .pending-events {
        span {
          background: #F9F9F9;
          border-radius: $border-radius;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #8D8D8D;
          padding: 5px 10px;
        }
        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          margin-left: 10px;
        }
        .rating-stars {
          margin-left: 10px;
        }
      }
      &--container {
        background-color: white;
        height: 56px;
      }
      .accordion-icon {
        font-size: 18px;
        top: 16px;
        z-index: 1000;
        color: #9B9B9B !important;
        cursor: pointer;
        pointer-events: none;
      }
      .trigger {
        width: 100%;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-height: 56px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        padding: 15px 40px 15px 15px;
        &:hover {
          box-shadow: none !important;
          border: none !important;
          outline: none !important;
          text-decoration: none;
        }
        hr {
          border: 1px solid #FEEDDB;
          margin-top: 3px;
          margin-bottom: 3px;
        }
        label {
          font-weight: 400;
        }
        .attending {
          &-icon {
            float: right;
            position: relative;
            top: 4px;
            right: unset;
            font-size: 14px;
          }
        }
      }
      .trigger[aria-expanded="true"]{
        background-color: white;
    }
      .invitation-header {
        font-weight: 500;
        font-size: 16px;
      }
    }
    &--pending-invite {
      width: 286px;
    }
    &-body-section {
      margin-top: 21px;
      .request-seat {
        @include orange-button;
        height: 34px;
      }
      .event-details {
        height: 34px;
        border: 1px solid #ECECEC;
        border-radius: 7px;
        margin-right: 10px;
        min-width: fit-content;
        color: white;
        background-color: $brand-primary;
      }
      .card {
        padding-top: 0 !important;
      }
    }
    .content {
      background: #ffffff;
      border-radius: 0 0 5px 5px !important;
      ul {
        padding-left: 0;
      }
    }
    &-body {
      &--line {
        border-top: 1px solid #ECECEC;
        margin-right: 0 !important;
      }
      &--card-wrap {
        flex-wrap: wrap;
      }

      padding:0;
      .btn.details {
        @include orange-button;
        width: 180px;
        height: 34px;
        border-radius: $border-radius;
      }
      .btn.arrow-right {
        width: 5px !important;
        min-width: 0 !important;
        height: 45px;
        border-radius: $border-radius;
        &:hover {
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
        }
      }
    }
  }
  .email-guests {
    a {
      @include transparent-button;
      border: 1px solid #ECECEC;
      border-radius: 7px;
      height: 37px;
      width: unset;
      margin-top: 15px;
    }
  }

  // Child accordion styling
  .event-card {
    position: relative;
    margin-bottom: $s-s;
    margin-right: 10px;
    .card--pending-invite {

      .invites {
        border: 1px solid #ECECEC !important;
        border-radius: $border-radius !important;
      }
    }
    &:last-of-type {
      margin-bottom: 0px;
    }
    .status{
      text-align: center;
      p{
        color: #FFF;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 200% */
      }
      &.pending{
        background-color: #C2C2C2;
      }
      &.green{
        background-color: #8DD728;
      }
    }
    .card {
      border-radius: 0 !important;
      box-shadow: none;
      // border-bottom: 1px solid #ECECEC !important;
      padding: 18px 15px;

      .get-directions {
        @include orange-button;
        margin-top: 29px;
        min-width: fit-content;
      }
      .email-host {
        height:48px;
        border: $border;
        border-radius: $border-radius;
        margin-right: 10px;
        margin-top: 29px;
        min-width: fit-content;
        width: 109px;
      }
      &--pending-invite {
        .invites {
          border: 1px solid $brand-primary;
          border-radius: $border-radius;
          margin-bottom: 0;
        }
      }
      &-header{
        box-shadow: none;
        border: 0;
        padding: 0;
        .trigger {
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          padding: 0;
          margin-top: 0;
          width: 100%;
          white-space: normal;
          .meal-type--icon {
            float: right;
          }
          .description {
            display: block;
          }
        }
        .invitation-header {
          font-weight: 500;
          font-size: 16px;
          height: unset;
          min-height: unset !important;
          &--center {
            position: relative;
            top: 8px;
          }
        }
        .extra-seats-number {
          &::before {

          }
        }
        .profile-column {
          padding: 0;
          padding-left: $s-s;
        }
        .date {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 13px
        }
        .profile {
          @include circle-frame(40px,0px,transparent);
          margin-left: 0;
          margin: unset !important;
          margin-right: 10px;
        }
      }
      &-body {
        background: white;
        padding-top: 0;
        border-radius: 0 0 5px 5px;
        &--event-ratings_container {
          background: #FBFBFB;
          border-radius: 3px;
          padding: 16px;
          margin-top: 16px;
        }
        .btn-publish{
          border-radius: 7px;
          background: #F69C3A;
          color: #FFF;
          padding: 12px 10px;
          height: unset;
        }
        .venue-btn{
          border-radius: 7px;
          border: 1px solid #5E5E5E;
          color: #3A3A3A;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 128.571% */
          letter-spacing: 0.056px;
          padding: 24px 10px;
        }
        .venue-image{
          width: 50px;
          height: 50px;
          background-position: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 4px;
          border: 1px solid #EBEBEB;
        }
        .venue-details{
          position: relative;
          left: -35px;
          h3{
            color: #717171;
            font-family: 'Inter';
            font-size: 11px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px; /* 145.455% */
            letter-spacing: 0.077px;
            text-transform: uppercase;
          }
          p{
            color: #717171;
            font-family: 'Inter';
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0.077px;
          }
        }
        .venue-status{
          float: right;
          span{
            color: #717171;
            text-align: right;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px; /* 130.769% */
            letter-spacing: 0.052px;
            position: relative;
            padding-right: 27px;
          }
          .venue-request-status-pending{
            &::after{
              content: url('/static/dist/img/pending.svg');
              top: 0;
              position: absolute;
              right: 0;
            }
          }
          .venue-request-status-approved{
            &::after{
              content: url('/static/dist/img/approved.svg');
              top: 0;
              position: absolute;
              right: 0;
            }
          }
        }
        .line{
          height: 1px;
          background-color: #C2C2C2;
          margin: 16px 0;
        }
        .days-left{
          color: #3A3A3A;
          font-size: 12px;
          font-style: italic;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
          letter-spacing: 0.072px;
        }
        &.event-ctas {
          padding-bottom: 0;
          .btn {
            width: 50%;
            border-radius: 0;
            min-width: 30%;
          }
          .accept-inivation{
            background-color: #8DD728;
            border-radius: $border-radius;
            color: white;
            font-weight: 500;
            font-size: 16px;
            width: unset;
            min-width: 30%;
            margin-top: 15px;
            height: 32px;
          }
          .view-details-btn{
            background-color: #F69C3A;
            border-radius: 7px;
            padding: 17px 10px;
            color: white;
            font-size: 14px;
            font-weight: 600;
            height: unset;
          }
          .decline-inivation{
            border: $border;
            border-radius: $border-radius;
            color: $brand-primary;
            font-weight: 500;
            font-size: 16px;
            width: unset;
            margin-right: 10px;
            height: 32px;;
            margin-top: 15px;
          }
        }
      }
    }
  }
}
&_logged-in {
  flex-grow: 1;
  background-color: #F9F9F9;
  padding-top: 0 !important;
  margin: unset;
}
&--hosting {
  .guest-list {
    width: 100%;

    // Public Invites Requests
    .requests {
      .event-ctas {
        margin-top: $s-s;
        border-radius: 5px;
        a {
          min-width: 100px;
        }
      }
    }
    li {
      width: 100%;
      list-style-type: none;
      &:after {
        float: right;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 16px;
      }
      &.confirmed{
        &:after {
          content: 'paid';
          color: #8EC988;
        }
      }
      &.pending{
        &:after {
          content: 'pending';
          color: #FCC389;
        }
      }
      &.accepted{
        &:after {
          content: 'pending payment';
          color: #FCC389;
        }
      }
      &.declined{
        &:after {
          content: 'declined';
          color: #FF8795;
        }
      }
      &.cancelled{
        &:after {
          content: 'cancelled';
          color: #FF8795;
        }
      }
    }
  }
}
&--invites {}
&--sent-requests {}
&--attending {}
&--ratings {
  .card {
    &-body {
      text-align: center;
      .rating-description {
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #3A3A3A;
      }
      .rating-host_name {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #3A3A3A;
      }
      .rating-dates {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #676767;
        margin-bottom: 13px;
      }
      .rating-profile_container {
        margin-top: 13px;
      }
    }
  }
}
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
    .event-header-title {
      width: 100%;
      margin-left: 0;
    }
    .accordion {
      .card {
        border: 0;
        min-width: 100%;
        max-width: 100%;
        &--pending-invite {
          width: 100%;
          margin-right: 0;
        }
        &-header {
          background-color: rgba(255,255,255,0.7);
          border-radius: 5px 5px 0 0;
          z-index: 1;
          .pending-events {
            span {
              background: #F9F9F9;
              border-radius: $border-radius;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #8D8D8D;
              padding: 5px 10px;
            }
            p {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              margin-left: 10px;
            }
          }
          &--container {
            background-color: #FAFAFA;
            height: auto;
          }
          .accordion-icon {
            font-size: 18px;
          }
          .trigger {
            font-size: 18px;
            line-height: 1.5;
            width: 100%;
            display: inline-block;
            font-weight: 500;
            text-overflow: ellipsis;
            // overflow: hidden;
            white-space: nowrap;
            hr {
              border: 1px solid #FEEDDB;
              margin-top: 3px;
              margin-bottom: 3px;
            }
            label {
              font-weight: 400;
            }
            .attending {
              &-icon {
                float: right;
                position: relative;
                top: 4px;
                right: unset;
                font-size: 14px;
              }
            }
          }
          .invitation-header {
            font-weight: 500;
            font-size: 16px;
          }
        }
        &-body-section {

          .request-seat {
            width: unset;
            height: 34px;
          }
        }
        .content {
          background: #ffffff;
          border-radius: 0 0 5px 5px !important;
          ul {
            padding-left: 0;
          }
        }
        &-body {
          .venue-details{
            left: 10px !important;
          }
          .event-card {
            margin-right: 0;
          }
          padding:0;
          .btn.details {
            @include orange-button;
            width: 180px;
            height: 34px;
            border-radius: $border-radius;
          }
        }
      }

      // Child accordion styling
      .event-card {
        .card {
          .get-directions {
            width: unset;
          }

        }
      }
    }
  }
    //Mobile specific styling
    @media (max-width: $tablet) {

      .event-header-title--no-events {
        padding-left: 30px;
      }
      .no-events {
        margin: 0;
      }
    }
}
