.add-venue {
  .menu-wrapper{
    box-shadow: 0px 4px 12px 0px #0000001F;
    border: 1px solid  #EBEBEB;
    border-radius: 8px;
    padding: 20px;
    margin: 30px 0;
    position: relative;
    .cancel-btn{
      width: 30px;
      position: absolute;
      right: -12px;
      top: -12px;
      cursor: pointer;
    }
  }
  .info-box {
    padding: 16px;
    padding-left: 30px;
    border-radius: 5px;
    border: 1px solid #EBEBEB;
    background: #E9F0FA;
    position: relative;
  

    p {
        color: #3A3A3A;
        font-family: 'Montserrat';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        /* 133.333% */
        letter-spacing: 0.072px;
    }

    &::before {
        content: url('/static/dist/img/feedback.svg');
        position: absolute;
        top: 12px;
        left: 15px;
    }
}
  .select-component select {
    height: 48px;
    border-radius: 7px;
    border: 1px solid #DCDFE0;
    background: #FFF;
    padding: 12px 29px 12px 18px;
    color: var(--grey-black-primary, #3F4142);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    -moz-appearance: none;
    -webkit-appearance: none;
    margin-top: 10px;
}

  h4.title{
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 10px;
    span{
      color: #D93025;
    }
  }
  .address-search{
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;
    padding-left: 0;
    margin-top: 0 !important;
  }
  .l-2{
    line-height: 2 !important;
  }
  .small-font{
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.006em;
    text-align: left;
    margin-bottom: 5px;
  }
  form{
    label{
      margin-top: 5px;
      display: inline-flex;
    }
    input[type="text"]{
      margin-top: 0;
    }

  }
  .switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 28px;

    &.mobile_switch{
        background-color: red;
    }

    &--co-host {

    }
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: center;
  }

 input:checked + .slider {
    background-color: #3A3A3A;
    width: 42px !important;
  }

  input:checked + .slider.width-search-round {
    background-color: #3A3A3A;
    width: 42px!important;
  }

  input + .slider.width-search-round {
    background-color: #ccc;
    width: 42px!important;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #3A3A3A;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
    background-image: url('/static/dist/img/grey-checkmark.svg');
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: center;
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
    width: 42px !important;
  }

  .slider.round:before {
    border-radius: 50%;
  }
    &-box {
      width: 218px;
      border-radius: 8px;
      padding: 15px;
      border: 1px solid #EBEBEB;
      box-shadow: 0px 4px 12px 0px #0000001F;
      position: absolute;
      right: 34px;
      top: 115px;
      background-color: #FFF;
      z-index: 1;

  
      &--header,
      &-nav--header {
        border: 1px solid #EBEBEB;
        background: linear-gradient(0deg, #E9F0FA, #E9F0FA), linear-gradient(0deg, #EBEBEB, #EBEBEB);
        padding: 5px;
        border-radius: 4px;
  
        .icon {
          background: #1967D2;
          color: white;
          width: 14px;
          height: 15px;
          border-radius: 3px;
          margin-right: 8px;
  
          .fa {
            font-size: 12px;
            margin: 0;
          }
        }
  
        h5 {
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
          letter-spacing: 0.006em;
          text-align: left;
          color: #3A3A3A;
          display: flex;
          margin-bottom: 5px;
        }
      }
  
      &--body,
      &-nav--body {
        margin: 5px 0;
  
        p {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.006em;
          text-align: left;
        }
      }
  
      &--buttons,
      &-nav--buttons {
        .btn {
          border-radius: 7px;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.006em;
          text-align: center;
          min-width: unset;
  
          &-ignore {
            border: 1px solid #C3C3C3;
            color: #3F4142;
            width: 60px;
          }
  
          &-add {
            color: #3F4142;
            background-color: #FFF;
            float: right;
            font-weight: 600;
            border-radius: 7px;
            border: 1px solid #C3C3C3;
            &:hover{
              color: #FFFFFF;
              background-color: #F69C3A;
            }
          }
        }
      }
  
      &-nav {
        border: 1px solid #EBEBEB;
        background: linear-gradient(0deg, #E9F0FA, #E9F0FA), linear-gradient(0deg, #EBEBEB, #EBEBEB);
        padding: 15px;
        border-radius: 4px;
        margin: 0px 10px;
  
        &--header,
        &-nav--header {
          border-bottom: 1px solid #1967D2;
  
          .icon {
            background: #1967D2;
            color: white;
            width: 14px;
            height: 15px;
            border-radius: 3px;
            margin-right: 8px;
  
            .fa {
              font-size: 12px;
              margin: 0;
            }
          }
  
          h5 {
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.006em;
            text-align: left;
            color: #3A3A3A;
            display: flex;
            margin-bottom: 5px;
          }
        }
  
        &--body,
        &-nav--body {
          margin: 15px 0;
  
          p {
            font-size: 14px !important;
            font-weight: 400 !important;
            line-height: 16px !important;
            letter-spacing: 0.006em;
            text-align: left;
          }
        }
  
        &--buttons,
        &-nav--buttons {
          .btn {
            border-radius: 7px;
            font-size: 16px;
            font-weight: 500 !important;
            line-height: 16px;
            letter-spacing: 0.006em;
            text-align: center;
  
            &-ignore {
              border: 1px solid #3A3A3A !important;
              color: #3A3A3A !important;
              background-color: #FFFFFF !important;
            }
          }
        }
      }
    }

    &-profile{
        border-top: 1px solid #ECECEC;
        padding-top: 60px;
        margin-top: 30px;
        .icon {
            background: #1967D2;
            color: white;
            width: 14px;
            height: 15px;
            border-radius: 3px;
            margin-right: 8px;
    
            .fa {
              font-size: 12px;
              margin: 0;
            }
          }
        &-info{
            background: linear-gradient(0deg, #E9F0FA, #E9F0FA),linear-gradient(0deg, #EBEBEB, #EBEBEB);
            border: 1px solid #EBEBEB;
            padding: 15px;
            width: 200px;
            border-radius: 8px;
            position: relative;
            margin-left: 35px;
            .triangle {
                width: 31px;
                height: 30px;
                background: linear-gradient(0deg, #E9F0FA, #E9F0FA),linear-gradient(0deg, #EBEBEB, #EBEBEB);
                clip-path: polygon(100% 0%, -1% 55%, 100% 100%);
                position: absolute;
                left: -29px;
                top: 42px;
                border: 1px solid #EBEBEB;
                border-right: none;
              }
            p{
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.006em;
                text-align: left;
                color: #3A3A3A;

            }
        }
        &-img{
            width: 112px;
            height: 112px;
            display: block;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border: 1px solid #ECECEC;
            border-radius: 8px;
            cursor: pointer;
        }
    }

    &-progress-bar{
        margin-top: 30px;
        border-radius: 4px;
        padding: 12px;
        border: 1px solid #EBEBEB;
        gap: 8;
        box-shadow: 0px 4px 12px 0px #0000001F;
        .bar{
            border-bottom: 4px solid #F7F7F7;
            padding-bottom: 7px;
            max-width: calc((100% / 3) - 15px);
            margin: 0 5px;
            label{
                font-family: "Inter";
                font-size: 11px;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0.007em;
                text-align: left;
                color: #A1A1A1;
                text-transform: uppercase;
                margin-bottom: 0;
            }
            &-current{
                border-color: #8DD728;
                label{
                    font-weight: 600;
                    color: #3A3A3A;
                }
            }
            &-previous{
                border-color: #CDE99D;
            }
        }
    }
    #basic_amenities, #entertainment_amenities, #additional_amenities {
      .amenities-icon{
        width: 24px;
        height: 24px;
        position: absolute;
        left: 31px;
        top: 0;
        &.image-0{
          background-image: url('/static/dist/img/wifi.svg')
        }
        &.image-1{
          background-image: url('/static/dist/img/wheelchair.svg')
        }
        &.image-2{
          background-image: url('/static/dist/img/car-sideview.svg')
        }
        &.image-3{
          background-image: url('/static/dist/img/shield.svg')
        }
        &.image-4{
          background-image: url('/static/dist/img/medical-square-full.svg')
        }
        &.image-5{
          background-image: url('/static/dist/img/process.svg')
        }
        &.image-6{
          background-image: url('/static/dist/img/baby-carriage.svg')
        }
        &.image-7{
          background-image: url('/static/dist/img/lightbulb-alt.svg')
        }
        &.image-8{
          background-image: url('/static/dist/img/music.svg')
        }
        &.image-9{
          background-image: url('/static/dist/img/glass-martini-alt.svg')
        }
        &.image-10{
          background-image: url('/static/dist/img/presentation-play.svg')
        }
        &.image-11{
          background-image: url('/static/dist/img/basketball.svg')
        }
        &.image-12{
          background-image: url('/static/dist/img/fire.svg')
        }
      }
      li{
        label{
          left: 60px !important;
        }
      }
    }
    #number{
      width: 40px;
      display: inline-block;
      text-align: center;
    }
    .math-btn{
      background: linear-gradient(0deg, #D3D3D3, #D3D3D3),linear-gradient(0deg, #F7F7F7, #F7F7F7);
      border: 1px solid #D3D3D3;
      border-radius: 4px;
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: center;
      display: inline-block;
      padding: 3px 13px;
      cursor: pointer;

    }

    #culinary_expertise, #culinary_services, #event_types, #health_safety, #qualifications, #basic_amenities, #venue_type, #entertainment_amenities, #additional_amenities{
      padding: 0;
      li{
        list-style: none;
        position: relative;
        //styleName: Typography/body/medium-02;
        label{
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.004em;
          text-align: left;
          position: relative;
          top: -1px;
          left: 33px;
        }
        .checkmark {
          background-color: #FFFFFF;
          border: 1px solid #DCDFE0;
          position: absolute;
          top: 0;
          left: 0;
          height: 25px;
          width: 25px;
          border-radius: 3px;
        }
        input:checked ~ .checkmark {
          background-color: #3A3A3A;
        }
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }
        input:checked ~ .checkmark:after {
          display: block;
        }
        .checkmark:after {
          left: 9px;
          top: 5px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        input[type="checkbox"]{
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 30px;
          width: 28px !important;
          z-index: 1;
        }
    }
  }
  textarea{
    &::placeholder{
      font-size: 14px !important;
      font-weight: 400;
      text-transform: unset !important;
    }
  }
    .business-details, .venue-location, .hire-cost, .social-media, .website{
      padding: 15px 0;
      .select2-selection__choice{
        padding-left: 24px;
      }
      select{
        option{
          font-family: 'Inter';
          font-size: 11px;
          font-style: normal;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
      .select2-selection--multiple{
        min-height: 40px;
        // border: none;
        // border-bottom: 1px solid #C2C2C2;
        //border-radius: 0 !important;
        background-color: #FFFFFF;
        li{
          background-color: #FFFFFF;
          font-family: 'Inter';
          font-size: 11px;
          font-style: normal;
          font-weight: 600;
          text-transform: uppercase;
        }
        .select2-selection__choice__remove{
          line-height: 26px;
          background: #FFFFFF;
        }
      }
      textarea{
        &::placeholder{
          font-size: 14px !important;
          font-weight: 400;
          text-transform: unset !important;
        }
      }
    }
    .btn{
      padding: 20px 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.003em;
      text-align: center;
      box-shadow: 0px 2px 12px 0px #0000001C;
      //box-shadow: 1px 1px 6px 0px #00000005;
      
    }
    .next-btn{
      background: #F69C3A;
      color: #FFFFFF;
    }
    .prev-btn{
      border: 1px solid #3A3A3A;
      color: #3A3A3A;

    }
    .camera-wrapper{
      background: linear-gradient(0deg, #EBEBEB, #EBEBEB),linear-gradient(0deg, #F7F7F7, #F7F7F7);
      border: 1px solid #EBEBEB;
      border-radius: 4px;
      height: 230px;
      cursor: pointer;
      background-size: cover;
    }
    .camera{
      background-image: url('/static/dist/img/camera.png');
      width: 20px;
      height: 20px;
      display: block;
      margin: auto;
      margin-top: calc((230px / 2) - 24px);

    }
    .camera-text{
      text-align: center;
      display: block;
      margin: auto;
      font-family: 'Inter';
      font-size: 11px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0.007em;
    }
    #gallery-wrapper{
      padding: 10px;
      padding-left: 15px;
      //background: linear-gradient(0deg, #D3D3D3, #D3D3D3),linear-gradient(0deg, #FFFFFF, #FFFFFF);
      border: 1px dotted #D3D3D3;
      border-radius: 8px;

      .camera-gallery{
        max-width: 90px;
        height: 90px;
        margin: 0 10px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        .camera{
          margin-top: calc((90px / 2) - 18px);
        }
      }

    }

    .line{
      background: #EBEBEB;
      height: 1px;
      margin: 20px 0;
    }
    .cost-per-person{
      label{
        color:#A1A1A1;
        font-family: 'Inter';
        font-size: 11px;
        font-weight: 400;
        text-transform: uppercase;
      }
    }
    .warning{
      .view{
        color: #3A3A3A;
        font-family: 'Inter';
        font-size: 11px;
        font-weight: 400;
        // &::after{
        //   content: url('/static/dist/img/selector-chevron-down.svg');
        // }
      }
    }
    .add-more-menu{
      cursor: pointer;
      .plus-sign{
        background-image: url('/static/dist/img/plus-sign.svg');
        width: 40px;
        height: 40px;
      }
      h5{
        color: #3A3A3A;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px; /* 150% */
        letter-spacing: 0.048px;
      }
      p{
        color: #A1A1A1;
        font-family: 'Inter';
        font-size: 11px;
        font-weight: 400;
        line-height: 14px; /* 127.273% */
        letter-spacing: 0.077px;
      }
    }
    .menu{
      label{
        color: #3A3A3A;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.056px;
        span{
          font-size: 14px;
          line-height: 18px; /* 128.571% */
          letter-spacing: 0.056px;
          font-weight: 400;
          margin-left: 4px;
        }
      }
      .menu-input{
        margin-bottom: 10px;
      }

      .menu-offering{
        border-radius: 8px;
        border: 1px solid #EBEBEB;
        background: #FFF;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
        padding: 20px 10px;
        margin-top: 20px;
        label{
          width: 100%;
          color: #222;
          font-size: 14px;
          font-weight: 400;
          margin-top: 0;
          margin-left: 35px;
          .checkmark {
            background-color: #FFFFFF;
            border: 1px solid #DCDFE0;
            position: absolute;
            top: 19px;
            left: 15px;
            height: 25px;
            width: 25px;
            border-radius: 3px;
          }
          input:checked ~ .checkmark {
            background-color: #3A3A3A;
          }
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }
          input:checked ~ .checkmark:after {
            display: block;
          }
          .checkmark:after {
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          input[type="checkbox"]{
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 30px;
            width: 28px !important;
            z-index: 1;
          }
        }
      }
      .menu-box{
        display: inline-flex;
        margin: 14px 0;
        .increment{
          border-radius: 4px;
          border: 1px solid #D3D3D3;
          background: #F7F7F7;
          color: var(--text-text-primary-02, #A1A1A1);
          text-align: center;
          font-size: 20px;
          font-weight: 400;
          padding: 0 14px;
        }
        .number{
          font-size: 16px;
          color: #3A3A3A;
          text-align: center;
          margin: 0 11px;
          position: relative;
          bottom: -4px;
        }
        input{
          margin-left: 8px;
          margin-top: 10px;
          margin-bottom: 0;
        }
      }
    }
    #type_of_organisation{
      font-family: 'Inter';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      option{
        font-size: 14px;
        font-weight: 400;
      } 
    }
    .type_of_organisation{
      &::after{
        content: url('/static/dist/img/selector-chevron-down.svg');
        position: absolute;
        right: 12px;
        top: 12px;
      }
      position: relative;
    }
    // Desktop specific styling
    @media (min-width: $tablet) {
      #culinary_expertise, #culinary_services, #event_types, #health_safety, #qualifications, #basic_amenities, #venue_type, #entertainment_amenities, #additional_amenities{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
      .type_of_organisation{
        width: 50% !important;
      }
    }
  
    // Mobile specific styling
    @media (max-width: $tablet) {
      .add-more-menu{
        .text{
          margin-left: 23px;
        }
      }
      &-box {
        right: 29px;
        top: 138px;
      }
    }
  }
  