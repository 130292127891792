.slidecontainer{
    width: 550px;
    position: relative;
    .range-slider {
      position: relative;
      width: 100%;
      height: 4px;
      border-radius: 15px;
      background: #3A3A3A;
      input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        }
    
      .progress {
        position: absolute;
        left: 25%;
        right: 0%;
        height: 100%;
        border-radius: 15px;
        background: #3A3A3A;
      }
      input[type="range"] {
        position: absolute;
        width: 100%;
        height: 10px;
        -webkit-appearance: none;
        pointer-events: none;
        background: none;
        outline: none;
        top: -3px;
        
      }
      .range-min::-webkit-slider-thumb {
        pointer-events: auto;
        -webkit-appearance: none;
        width: 18px;
        height: 18px;
        background: #FFF;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
        border: 2px solid #EBEBEB;
        border-radius: 9999px;
        opacity: 1;
      }
      .range-max::-webkit-slider-thumb {
        pointer-events: auto;
        -webkit-appearance: none;
        width: 18px;
        height: 18px;
        background: #FFF;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
        border: 2px solid #EBEBEB;
        border-radius: 9999px;
        opacity: 1;
      }
    }
    .numberVal{
        border-radius: 5px;
        border: 1px solid #D3D3D3;
        padding: 0 8px;
        &.min-value{
            position: absolute;
            right: 0;
        }
        .slider-label{
            color: #3A3A3A;
            font-family: 'Inter';
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 200% */
            position: relative;
            top: 5px;
        }
        .amount, input{
            background-color: #FFF;
            border: none;
            color: #3A3A3A;
            font-family: 'Inter';
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px; /* 130.769% */
            letter-spacing: 0.052px;
        }
        input{
          position: absolute;
          right: -33px;
          top: -1px;
          padding: 0;
          margin: 0;
          height: 19px !important;
          width: 30px;
        }
        .amount{
            display: inline-flex;
            position: relative;
            top: -3px;
        }

    }
    .small-font{
        color: #3A3A3A;
        font-family: 'Inter';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.072px;
    }


    @media (max-width: $tablet) {
        width: 100%;
    }
}
