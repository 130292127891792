.social-share {
    a {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #F69C3A !important; 
        position: relative;
        bottom: 5px;
        img {
            position: relative;
            bottom: 3px;
        }
        span {
            margin-left: 7px;
        }
        
    }
}
