.home {
    > .content {
        padding-top: 0;
        background: white;
        padding: 0;
      }
    p.helper-text {
      color: var(--Text-Gray-500, #676767);
      /* p/p | 16px, 24px */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
}