.hero {
    height: 600px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 90%, #00000081 100%), url('/static/dist/img/landing_page_hero_banner.jpg');
    padding: 0 40px;
    background-size: cover;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    background-position: center;
    height: calc(100vh - 200px);

    &-content {
      height: 100%;
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      max-width: 1000px;
      margin: auto;

      // Desktop Search bar modifications for landing page specifically
      .row:nth-of-type(2) {
        position: absolute;
        bottom: 50px;
    
        .search-bar {
          margin-top: unset;
          padding: $s-s;
          border-radius: 12px;
          background: rgba(255, 255, 255, 0.40);
          backdrop-filter: blur(4px);
          .form-group {
            margin-bottom: 0;
          }
          .btn_search {
            background-color: #F69C3A;
          }
        }
      }


    }

    h1 {
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;

      color: var(--Grey-White, #FFF);
      text-align: center;
      font-family: Inter;
      font-size: 42px;
      font-style: normal;
      font-weight: 600;
      line-height: 55px; /* 130.952% */
    }

    // Desktop specific styling
    @media (min-width: $tablet) {
      
      &-content {
        // Force the search bar to sit at the bottom of the hero

      }
    }

  // Mobile specific styling
  @media (max-width: $tablet) {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #00000081 100%), url('/static/dist/img/landing_page_hero_banner.jpg');
    padding: 0 25px;
    height: 328px;
    background-size: cover;
    background-position: bottom;

    h1 {
      font-size: 40px;
      line-height: 1.2;
      margin-bottom: 10px;
    }

    &-content {
      .row:nth-of-type(2) {
        bottom: 25px;
      }
    }

 // Mobile sarch bar modifications for landing page specifically
    .search-bar {
      margin-top: unset;
      form {
        width: calc(100vw - 60px) !important;
      }
      .btn_search {
        background-color: #F69C3A;
      }

      &--input {
        margin-bottom: 0;
        &::after {
          display: none;
        }
        input.search-field {
          padding-left: 15px !important;
          width: 100%;
        }
      }
      .search-bar--date,.search-bar--area, .select2 {
        display: none!important;
      }
      .search-container {
        position: absolute;
        top: $s-s;
        right: $s-s;
      }
      .form-buttons {
        margin-bottom: 0;
      }
    }
  }
}
