.payment-confirmation {
  // General styling

  // Switch logo with back button
  .back {
    display: flex !important;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
    .fas {
      font-size: 24px;
      margin-right: 10px;
    }
  }

  .logo-img {
    display: none !important;
  }


  // Desktop specific styling
  @media (min-width: $tablet) {}

  // Mobile specific styling
  @media (max-width: $tablet) {}
}