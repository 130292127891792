.faq{
    // General Styling
    padding-bottom: 0;
    .card{
        border: 0;
        &-header{
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        background-color: #FFFFFF;
        padding: 25px;
        }
    }
    .fa {
        float: right;
        margin: auto;
        top: 3px;
        position: relative;
    }
    h2{
        text-transform: capitalize;
        text-align: center;
        margin-bottom: $s-m;
        // font-weight: 700;
    }
    h6{
        font-size: 18px;
        margin-bottom: 0px;
    }
    .btn_secondary {
        margin: $s-m auto;
        display: block;
        width: 165px;
    }
        // Desktop specific styling
    @media (min-width: $tablet) {
    }
    
      // Mobile specific styling
      @media (max-width: $tablet) {
          h4{
              font-size: 22px;
          }
          h5{
              font-size: 24px;
          }
      }
    }