.EasyMDEContainer{
// General styling

.guide, .fullscreen + .separator {
  display: none;
}

span {
  display: unset !important;
}

// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}