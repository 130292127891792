// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {

    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }

  @else {

    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

// Module padding
@mixin module {
  padding: $s-m;
  margin-top: 0;
  display: block;
  margin: auto;
}

@mixin no-margin-padding {
  padding: 0;
  margin: 0;
}

@mixin margin-bottom {
  margin-bottom: $s-m;
}


@mixin padding-bottom {
  padding-bottom: $s-m;
}

@mixin circle-frame($diameter, $border-thickness, $border-colour) {
  height: $diameter;
  width: $diameter;
  min-width: $diameter;
  border-radius: calc(#{$diameter} / 2);
  border: 3px solid #EDEDED;
  margin: auto;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin border ($dimension, $colour, $radius) {
  border: $dimension solid $colour;
  border-radius: $radius;

}

@mixin form ($height, $font-size) {
  height: $height !important;
  border-radius: 3px !important;
  font-size: $font-size;
  border: 1px solid #CBCBCC;
}

@mixin guest-list {
  .guest-list {
    width: 100%;
    &--spacer {
      display: block;
      height: 14px;

    }
    .profile {
      width: 25px !important;
      min-width: 25px;
      height: 25px !important;
      margin: unset;
      margin-right: 15px;
      border: none;

    }
    &--lists {
        margin-bottom: 22px;
    }
    &--profile-name-link {

    }
    .added-guests {
      position: relative;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #888B8E;
      left: 34px;
      &:after {
        right: 34px;
      }
    }
    li {
      width: 100%;
      list-style-type: none;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #3A3A3A;
      position: relative;
      .comment{
        position: absolute;
        right: 0;
        top: -6px;
        border-radius: 50%;
        border: 1px solid #EBE9E9;
        padding: 6px;
        width: 36px;
        height: 36px; 
        cursor: pointer;
        img{
          width: 20px;
          height: 20px;
        }
        &.disabled{
          cursor: not-allowed;
          background-color: #EBE9E9;
        }
      }
      &:after {
        float: right;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        border-radius: 10px;
        top: 4px;
        position: relative;
        color: $brand-primary;
      }

      &.accepted {
        // &:after {
        //   content: 'Attending';

        // }
        &.remove-after{
          &:after {
            content: '';
  
          }
        }
      }
      &.host {
        &:after {
          content: 'Host';
          border: 1px solid rgba(246, 156, 58, 0.2);
          border-radius: 7px;
          padding: 4px 7px;
          color: #F69C3A;
        }
      }
      &.co-host {
        &:after {
          content: 'Co-host';
          border: 1px solid rgba(246, 156, 58, 0.2);
          border-radius: 7px;
          padding: 4px 7px;
          color: #F69C3A;
        }
      }
      &.pending {
        &:after {
          content: 'Pending';
          border: 1px solid #ECECEC;
          border-radius: 7px;
          padding: 4px 7px;
          color: #CBCBCC;
        }
      }

      &.cancelled {
        &:after {
          content: 'cancelled';
          background-color: #FCC389;
        }
      }

      &.declined {
        &:after {
          content: 'declined';
          background-color: #FF8795;
        }
      }
    }
  }
}

@mixin dissapear {
  position: absolute;
  visibility: hidden;
}

@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@mixin paragraph-size {
  font-size: 14px;
  line-height: 24px;
}
@mixin fullwidth-button {
  height: 48px;
  width: 100%;
  @media (max-width: $tablet) {
    width: 100%;
  }
}
@mixin transparent-button {
  width: 100%;
  background-color: transparent;
  border-radius: $border-radius !important;
  color: $brand-primary;
  border: 1px solid $brand-primary;
  &:hover,&:active,&:focus,&:focus-visible {
    outline: none !important;
    box-shadow: none !important;
    text-decoration: none !important;
    background: rgba(63, 65, 66, 0.05);
    border: 1px solid #202125;
  }
  &.btn-profile{
    right: 0;
  }
  @media (max-width: $tablet) {
    width: 100%;
  }
}
@mixin dark-button {
  height: 48px;
  width: 100%;
  color: white;
  border-radius: $border-radius !important;
  background-color: $brand-primary;
  &:hover,&:active,&:focus,&:focus-visible {
    background-color: #202125;
    outline: none !important;
    box-shadow: none !important;
    color: white !important;
    text-decoration: none !important;
  }
  @media (max-width: $tablet) {
    width: 100%;
  }
}
@mixin orange-button {
  height: 48px;
  color: white;
  background-color: #F69C3A;
  border: 0;
  &:hover,&:active,&:focus,&:focus-visible {
    background-color: #EB8014;
    outline: none !important;
    box-shadow: none !important;
    text-decoration: none !important;
  }
  @media (max-width: $tablet) {
    width: 100%;
  }
}
@mixin search-input {
  color: $brand-primary;
  height: 50px !important;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: $border-radius !important;
  margin-top: 0 !important;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3A3A3A;
  padding: 13px 16px;

  }

  @mixin guests-profile {
    height: 20px;
    width: 20px;
    min-width: 20px;
    border-radius: calc(50px / 2);
    margin: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 11px;

}

@mixin back-btn {
  border-bottom: 1px solid #EEEEEE;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3A3A3A;
  min-width: 68px;
  margin-top: 32px;
  &::before {
    content: url('/static/dist/img/back-button.svg');
    position: relative;
    right: 13px;
    top: 3px;
  }
}

.warning{
  padding: $s-t + 3px;
  border-radius: 5px;
  border: 1px solid #EBEBEB;
  background: #FEFAEC;
  position: relative;
  display: flex;
  p{
      color: #3A3A3A;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.072px;
  }
  &::before{
      content: url('/static/dist/img/warning.svg');
      margin-top: -2px;
      margin-right:5px;
  }
}