.event-view {
// General styling
  // remove nav-fade compensation padding
  .container {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08), 0px 0px 10px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 15px 15px 0 15px !important;
    margin-bottom: 0 !important;
  }
  > .content {
    padding-top: 0;
    background: white;
  }
  .share {
    z-index: 1;
    position: absolute;
    top: 10px;
    right: $s-s;
    font-size: 20px;
    &.whatsapp {}
  }
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}