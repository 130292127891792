.credit-card-details {
// General styling
padding-top: 0;
margin-top: 75px;
h1 {
  margin: $s-m 0;
  font-size: 20px;
  text-transform: capitalize;

}
.grey-container {
  background: #FAFAFA;
  padding: 22px 0;
  width: 100%;
  position: relative;

  hr {
    color: $brand-light; 
    height: 1px;
    width: 327px;
    float: left;
    margin-top: 18px;
    margin-bottom: 18px;
  }
}
.back-btn {
  border-bottom:1px solid #EEEEEE;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $brand-primary;
  min-width: 68px;
  margin-top: 32px;
  &::before {
    content: url('/static/dist/img/back-button.svg');
    position: relative;
    right: 13px;
    top: 3px;
  }
}

.top-section {
  hr {
    color: #EEEEEE;
    height: 1px
  }
}
.confirm-btn {
  @include orange-button;
}
.cancel-btn {
  @include transparent-button;
  width: 150px;
  margin-right: 10px;
}
.ccv {
    width: 139px;
}
.notification {
  margin-top: $s-m;
  display: inline-block;
}
.notification:last-of-type {
  margin: 0 !important;
}
.month,.year {
  padding-right: 0;
  input {
    margin-top: 5px;
    padding: 0;
  }
}
.month {
  max-width: 90px;
}
.year {
  max-width: 110px;
}

// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
    .grey-container {
      hr {
        width: 100%;
      }
    }
    .notification {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #919191;
      }
    h1 {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      position: absolute;
      left: 50%;
      transform: translate(-50px, -50px);
      top: 34px;
    }
    .back-btn {
      margin-top: 0;

    }
    .top-section {
      margin-left: -15px;
      margin-right: -15px;
      hr {
          margin-top: 0;
      }
    }
    .ccv {
      width: 100%;
      }
      .cancel-btn,.back-btn {
        border:none !important;
      }
      #decisionBtn {
        display: grid;
      }
      #decisionBtn > #cancelBtn {order:2;margin-top:10px;}
      #decisionBtn > #confirmBtn {order:1}
  }
}
.card-details {
  .card-type,.expiry-date {
    margin-bottom: 4px;
  }
  .cvv-label {
    margin-top: 20px;
  }
}