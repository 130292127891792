.cancellation-fee-explainer {
     margin: 19px 0;
     a {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #727272;
     }
    .popup {
        width: 358.83px;
        height: 134px;
        background: #FFFFFF;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 20px rgba(0, 0, 0, 0.12);
        border-radius: 7px;
        padding: 13px 29px 13px 15px;
        position: absolute;
        z-index: 1;
        margin-top: 9px;
        .close {
            position: absolute;
            right: 12px;
            border: none;
            background: white;
            top: 8px;
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #3F4142;
        }
        a {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #3F4142;
            position: absolute;
            bottom: 13px;
        }
    }
}