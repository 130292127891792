.post-update {
    .edit {
            width: unset;
            min-width: 34px;
            height: 34px !important;
        }
    .modal {
     &-button {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #3A3A3A;
        border: 1px solid #C4C4C4;
        border-radius: 5px;
        height: 48px;
     }
     &-header {
        border-bottom : 1px solid #EAECEC !important;
        button {
                &:active,&:focus,&:focus-visible {
                    outline: none !important;
                }
        }
     }
     &-body {
        padding: 20px !important;
        textarea {
            border: 1px solid #CBCBCC;
            border-radius: 7px;
            height: 100%;
            width: 100%;
            padding: 16px 14px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #717273;
        }
        input {
            height: 48px;
            border: 1px solid #CBCBCC;
            border-radius: 7px;
            padding: 0 18px;
        }
        label,.word-count {
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            color: #3F4142;

        }
     }
     &-footer {
        border-top : 1px solid #EAECEC !important;
        justify-content: space-between;
        button {
            max-width: 79px !important;
            min-width: 0;
            margin-top: 15px;
        }
        button:last-child {
            @include dark-button;
        }
     }
     &-dialog {
        min-width: 732px;
        @media (max-width: $tablet) {
            min-width: unset
          }
     }
    }
}