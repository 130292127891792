.banking-details-content {
  margin-top: 100px;
  .host-event-container {
    margin-bottom: 30px !important;
  }
h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  margin-top: 34px;
}
h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 28px;
}
p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #727272;
  margin-top: 8px;
}
.back-btn {
  border-bottom:1px solid #EEEEEE;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $brand-primary;
  min-width: 68px;
  &::before {
    content: url('/static/dist/img/back-button.svg');
    position: relative;
    right: 13px;
    top: 3px;
  }
}
.btn-confirm {
  @include orange-button;
}
.btn-cancel {
  @include transparent-button;
  width: 150px;
  margin-right: 10px;
}
background: white;
.info {
  margin: 12px 0 28px 0;
}
&--decision {
  margin: $s-l 0;
}
&--note {
  padding: 22px 0;
  background-color: #FAFAFA;
  position: relative;
  width: 100%;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #919191;
  }
}
&--select-bank {
  margin-bottom: 5px;
}
&--select-container {
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    background-image: url('/static/dist/img/select_arrow.svg');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 15px);
    background-position-y: 15px;
}
}
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
    &--decision {
      margin: 44px 0 0 0;
    }
    &--note {

      position: unset;
      .host-event-container {
        margin-bottom: 30px !important;
      }
    }
    .back-btn { 
      border: none;
    }
    .top-section {
      margin-left: -13px;
      margin-right: -13px;
      hr {
        color:#EEEEEE;
        height: 1px;
        margin-top: 0;
      }
    }
    h1 {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      position: absolute;
      left: 41%;
      transform: translate(-41px, -41px);
      margin-top: 54px;
    }
    .btn-cancel {
      @include transparent-button;
      border: none;
    }
    #decisionBtn {
      display: grid;
    }
    #decisionBtn > #cancelBtn {order:2;margin-top:10px;}
    #decisionBtn > #confirmBtn {order:1}
  }
}