   .masonry {
    &-container {
      max-width: 1600px;
        h1 {
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: $brand-primary;
            margin-bottom: 0 !important;

        }
        padding: 0 24px 40px 24px!important;
    }
    &-grid {
    column-count: 3;
    column-gap: 14px;
    .brick {
      box-sizing: border-box;
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
      counter-increment: brick-counter;
       margin-bottom: 14px;
       position: relative;
       .overlay {
        display: none;
        width:100%;
        height:100%;
        position:absolute;
        padding: 18px;
        top: 0px;
        text-decoration: none;
        //
        // background: rgba(0, 0, 0, 0.7);
        // z-index: 1;
        // top: 0;

        .header {
            padding: 15px 14px 24px 14px;
            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                border: 1px solid #ECECEC;
            }
            .user {
                margin-left: 10px;
                h1 {
                    color: white;
                    position: relative;
                    bottom: 3px;
                }
                p {
                    color: white;
                    margin-top: 18px;
                }
            }
        }
        .description {
                color: white;
        
        }
       }
       &:hover > .overlay {
        display: block;
        background: rgba(0, 0, 0, 0.7);
        z-index: 1;
        top: 0;
        -webkit-transition: visibility  .3s ease-in-out;
        transition: visibility  .3s ease-in-out;
        }

       }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $border-radius;
    }
  }
  @media (max-width:  $tablet) {
    &-grid {
    .brick {
       .overlay {
         display: none;
        .header {
          padding: 0 14px 24px 14px;
          img {             
            width: 25px;
            height: 25px;}
          .user {
              h1 {
                  font-size: 11px;
              }
          }
      }
      .description  {
        font-size: 10px;
      }
      
       }
      }
    }
    .description {
     font-size: 11px;
    }
    &-grid {
    column-count: 2;
    }
  }
  @media (min-width: 992px) {
    &-grid {
    column-count: 4;
    }
  }
  @media (min-width: 1199px) {
    &-grid {
    column-count: 4;
    }
  }
}