.organisation-badge{
    width: 49%;
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    padding: 8px 16px;
    .labeling{
      border-top: 1px solid #EBEBEB;
      margin: 10px 0;
      padding-top: 13px;
    }
    img{
      width: 34px;
      height: 34px;
      margin-right: 5px;
    }
    span{
      color: #3A3A3A;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.072px;
    }
    .badge{
      position: relative;
      span{
        display: block;
        float: left;
        margin-left: 18px;
      }
      &::after{
        content: url("/static/dist/img/angle-right.svg");
        right: 12px;
        top: 10px;
        position: absolute;
      }
    }
    .money{
      &-wrapper{
        background: #F7F7F7;
        border-radius: 4px;
        padding: 5px;
        margin: 5px;
        .hosting-text{
          border-right: 1px solid #5E5E5E;
          span{
            font-family: 'Inter';
            font-size: 11px;
          }
        }
      }
      .amount{
        display: inline-block;
        float: right;
        position: relative;
        right: 10px;
        color:#3A3A3A;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
      }
      img{
        width: 24px;
        height: 24px;
      }
    }
    @media (max-width: $tablet) {
          width: 100%;
        }
  }