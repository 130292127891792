.meal-invoice-snippet {
  margin-top: 32px;
  // General styling
  width: 574px ;
  .check-sponsored-body {
    padding: 0 !important;
    .card-title-wrapper {
      padding: 15px 24px 0 24px;
    }
    .card-details {
      padding: 9px 24px 0 21px;
      &.sponsored {
        padding-top: 17px;
      }
    }
    hr {
      margin: 0;
    }
  }
  &.event-display {
    box-shadow: none;
    border: 1px solid #ECECEC !important;
    border-radius: 5px;
    max-width: 100% !important;
    .card-body {
      padding: 20px !important;
    }
    p {
      margin-left: 0 !important;
    }
  }
&_slim {
  padding: $s-s;
}
.card-title {
  a {
    width: 96%;
    @include ellipsis();
    height: 30px;
  }
}
h1 {
  text-align: left;
  margin-bottom: 28px;
  margin-top: 34px;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  &.confirm-charge {
    margin: 14px 0 !important;
  }
}
h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.back-btn {
  @include back-btn;
}
hr {
  border-top: 1px solid #EBE9E9 !important;
  margin:0  -22px;
}
.event-invoice {
  position: absolute;
  top: 47px;
  left: 0;
  right: 0;
  text-align: center;
}
.profile_small {
  margin-left: 0 !important;
}
.host-name {
  margin-left: 11px;
}
.event-name {
  font-size: 20px;
  font-weight: 600;
}
.host-name {
  font-size: 18px;
}
.host-payment {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #3F4142;
  margin-left: 0 !important;
}
.service-fee {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #3F4142;
  opacity: 0.5;
  margin-left: 0 !important;
}
.main-title {
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: #3A3A3A;
  width: 100%;

 }
.card-details {
  &--container {
    padding: 0 0 31px 0;
    &.sponsored-container {
      padding-bottom: 21px;
    }
    .free-to-attend-banner {
      &.card-check-sponsored {
        padding: 15px 24px 15px 24px;
        position: relative;
        border-bottom: 1px solid #EBE9E9;
        margin-bottom: 0;

      }
      margin-bottom: 10px;
      p {
        color: #3A3A3A;
        font-family: "unset";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 162.5% */
      }
      .tag {
        display: flex;
        width: 63px;
        height: 26px;
        padding: 3px 9px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 5px;
        background: #F69C3A;
        color: #FFF;
        text-align: center;
        font-family: "unset";
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 123.077% */
      }
    }
  }
  .event-time-date {
    margin-top: 27px;
     .date {
       &::before {
        content: url(/static/dist/img/clock.svg);
        margin-right: 10px;
        position: relative;
        top: 2px;
       }
     }
     .address {
       &::before {
        content: url(/static/dist/img/location.svg);
        margin-right: 10px;
        position: relative;
        top: 2px;
       }
     }
  }
}
.amount-of-guests-container {
  padding: 25px 11px;
  position: relative;
  .amount-of-guests {
    margin-left: 14px;
    margin-right: 14px;
    p {
      color: #3F4142;
      font-family: "unset";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 162.5% */
    }
  }
  .platform-service-fee{
    height: 43px;
    border-radius: 4px;
    border: 1px solid  #F69C3A;
    background:  rgba(246, 156, 58, 0.10);
    padding: 0 8px 0 8px;
    margin: 16px 0;
    button {
      background-color: transparent;
      border: none;
      outline: none !important;
      position: relative;
      bottom: 1.1px;
    }

    p {
      color: #F69C3A;
      font-family: "unset";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 100% */
    }
  }
  .info-popup {
    button {
      background-color: transparent;
      border: none;
      outline: none !important;
      position: relative;
      bottom: 1.1px;
    }
    background: #FFFFFF;
    // min-height: 96px;
    width: 370px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 20px rgba(0, 0, 0, 0.12) !important;
    border-radius: 7px;
    padding: 5px 12px 12px 12px;
    margin-top: 6px;
    position: absolute;
    z-index: 10;
    @media (max-width: $tablet) {
      width: 100%;
    }
    .header {
      width: 100%;
      height: 7px;
      button {
        float: right;
      }
    }
    p {
      font-weight: 400 !important;
      font-size: 12px !important;
      line-height: 18px !important;
      display: flex;
      align-items: center;
      color: #3F4142;
      margin-top: 0 !important;

    }
  }
  span {
    color: #888B8E;
    font-family: "unset";
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    margin-left: 14px;
  }
}
.payment-container {
  padding: 25px 0;
}
.price-container {
  padding: 34px 0 0 0;
}
.price {
  text-align: right;
  font-size: 16px;
  &--total {
    font-weight: 600;
    font-size: 26px;
    margin-left: 7px;
    margin-top: 6px;
  }
  &--totalCost {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #676767;
    margin-top: 6px;
  }
  &--fee {
    font-weight: normal !important;
  }
}

.cost {
  font-size: 30px;
  font-weight: 600;
  position: absolute;
  right: $s-s;
  bottom: $s-t;
}

// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
    width: 100% ;
    .event-invoice {
      top: 22px;
    }
    .back-btn {
     border: none;
     margin-top: 10px;
     width: 100%;
     z-index: 100;
     &::before {
      position: absolute;
      right: 13px;
      top: 17px;
      right: calc(100% - 10px);
     }
    }
    h2 {
      margin-top: 21px;
    }
    .top-line {
      margin-left: -30px;
      margin-right: -30px;
    }
  }
}
