.plus-ones-form {
  // General styling
  margin-top: 50px;
  margin-bottom: $s-m;

  h1 {
    text-align: center;
    margin: $s-m 0;
    font-size: 30px;
  }

  h2 {
    font-size: 16px;
    text-align: center;
    margin-bottom: 30px;
  }

  p {
    margin-top: $s-m;
    margin-bottom: 0;
  }

  &--body {
    margin-bottom: $s-l;

    .success {
      margin: $s-m 0;
    }

    .delete {
      position: relative;

      i {
        position: absolute;
        right: 15px;
        top: 10px;
      }
    }

    .plus-one-wrapper {
      display: none;
    }

    .seats {
      width: 215px;
      margin: auto;

      &--number {
        #plusOneNumber {
          font-size: 60px;
          height: unset !important;
          margin-top: 0;
          padding: 0;
          text-align: center;
        }
      }

      &--label {
        font-size: 20px;
        align-self: center;
      }
    }

  }

  &--footer {
    .btn {
      min-width: 200px;
      margin: auto;
      
    }
    .continue-payment {
      @include orange-button;
    }
  }

  // Desktop specific styling
  @media (min-width: $tablet) {}

  // Mobile specific styling
  @media (max-width: $tablet) {}
}