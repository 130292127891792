.load-bar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 4px;
    background-color: #fdba2c;
    z-index: 10000000;
    .bar {
        content: "";
        display: inline;
        position: absolute;
        width: 0;
        height: 100%;
        left: 50%;
        text-align: center;
      }
      .bar:nth-child(1) {
        background-color: #da4733;
        animation: loading 3s linear infinite;
      }
      .bar:nth-child(2) {
        background-color: #3b78e7;
        animation: loading 3s linear 1s infinite;
      }
      .bar:nth-child(3) {
        background-color: #F69C3A;
        animation: loading 3s linear 2s infinite;
      }
      @keyframes loading {
          from {left: 0; width: 0;z-index:100;}
          33.3333% {left: 0; width: 100%;z-index: 10;}
          to {left: 0; width: 100%;}
      }
  }